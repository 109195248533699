<template>
    <div>
        <h1 class="header">Оплаты</h1>

        <payment-banner @open="setOpenModal" />

        <!-- <div class="search-input-wrapper search-input-wrapper--payments">
            <input type="text" class="search-input" />
            <mdicon size="20" name="magnify" class="search-input-icon" />
        </div> -->

        <payment-history />
    </div>

    <payment-modal 
        :is-payment-modal-visible="isPaymentModalVisible" 
        @close="onCloseModal"
        />
</template>

<script setup>
import { ref } from "vue";
import PaymentBanner from "./PaymentBanner.vue";
import PaymentHistory from "./PaymentHistory.vue";
import PaymentModal from "./PaymentModal.vue";

const isPaymentModalVisible = ref(false);
const setPaymentPopupVisibility = (status) => {
    isPaymentModalVisible.value = status;
};

const setOpenModal = (value) => {
    setPaymentPopupVisibility(value);
};

const onCloseModal = (value) => {
    setPaymentPopupVisibility(value);
}


</script>

<style scoped lang="scss">
.search-input-wrapper--payments {
    margin-top: 30px;
    margin-bottom: 20px;
}
</style>