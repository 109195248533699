<template>
  <base-popup
    class="modal"
    top-indent="40"
    size="auto"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    title="Фото жильца"
    @close="onModalClose"
  >
    <div class="modal__body">
      <div>
        <div class="faceId-view" v-if="Boolean(currentClient.faceUrl)">
          <div class="faceId-view__faceId">
            <img
              class="faceId-view__faceId-image"
              :src="currentClient.faceUrl"
              alt="faceId"
            />
            <div class="action">
              <div
                :class="[
                  'action__button action__button_check',
                  {
                    action__button_disabled: currentClient.isFaceUploaded,
                  },
                ]"
                @click="setApproveStatusForFaceId(true)"
              >
                <mdicon size="30" class="action__submit-icon" name="check" />
              </div>
              <div class="action__text">
                Подтвердить <br />
                фотографию?
              </div>
              <div
                :class="[
                  'action__button action__button_cancel',
                  { action__button_disabled: !currentClient.isFaceUploaded },
                ]"
                @click="setApproveStatusForFaceId(false)"
              >
                <mdicon size="30" class="action__submit-icon" name="cancel" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>Что-то не так с данными фото...</div>
      </div>
      <div>
        <div class="client__info">
          <div class="client__info-row">
            <p class="client__info-title">Имя:</p>
            <p class="client__info-value">
              {{ client.user.firstName }} {{ client.user.lastName }}
            </p>
          </div>
          <div class="client__info-row">
            <p class="client__info-title">ID:</p>
            <p class="client__info-value">{{ client.user.id }}</p>
          </div>
          <div class="client__info-row">
            <p class="client__info-title">Телефон:</p>
            <p class="client__info-value">{{ client.user.phone }}</p>
          </div>
        </div>
        <div v-if="loading" class="mt-20 text-center loader">
          <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
        </div>
        <div v-else-if="sensors.length" class="client__status">
          <p class="client__info-title">Статус доступа:</p>
          <div class="client__status-list">
            <div
              v-for="item in sensors"
              :key="item.sensorId"
              class="status__row"
            >
              <p class="client__info-value">{{ item.sensorName }}</p>
              <div
                v-if="item.sensorStatus"
                class="action__button action__button_check"
                :class="{
                  disabled:
                    sensorsIds.includes(item.sensorId) &&
                    updateUserPanelFaceIdLoading,
                }"
                title="Повторить загрузку"
                @click="onPanelUpdate(item.sensorId)"
              >
                <mdicon
                  v-if="
                    sensorsIds.includes(item.sensorId) &&
                    updateUserPanelFaceIdLoading
                  "
                  size="20"
                  class="action__submit-icon action__submit-icon_loading"
                  name="loading"
                />
                <mdicon size="20" class="action__submit-icon" name="check" />
                <mdicon
                  size="20"
                  class="action__submit-icon action__submit-icon_refrech"
                  name="refresh"
                />
              </div>
              <div
                v-else
                class="action__button action__button_cancel"
                :class="{
                  disabled:
                    sensorsIds.includes(item.sensorId) &&
                    updateUserPanelFaceIdLoading,
                }"
                title="Повторить загрузку"
                @click="onPanelUpdate(item.sensorId)"
              >
                <mdicon
                  v-if="
                    sensorsIds.includes(item.sensorId) &&
                    updateUserPanelFaceIdLoading
                  "
                  size="20"
                  class="action__submit-icon action__submit-icon_loading"
                  name="loading"
                />
                <mdicon size="20" class="action__submit-icon" name="cancel" />
                <mdicon
                  size="20"
                  class="action__submit-icon action__submit-icon_refrech"
                  name="refresh"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-popup>
</template>

<script setup>
import { BasePopup } from '@/components/atoms';
import { ref, watch, defineEmits, defineProps } from 'vue';
import {
  getUserFaceIdStatuses,
  updateUserPanelFaceId,
} from '@/graphql/Client.graphql';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { useStore } from 'vuex';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const props = defineProps({
  client: {
    faceUrl: String,
    id: [String, Number],
    user: {
      firstName: String,
      id: String,
      lastName: String,
      email: String,
      phone: String,
    },
  },
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  clientLoaderId: {
    type: Number,
  },
});
const emit = defineEmits(['close', 'setApproveStatusForFaceId']);
const store = useStore();
const currentClient = ref({});
const isLoading = ref(false);

const onModalClose = () => {
  emit('close');
};

const setApproveStatusForFaceId = async (status) => {
  onModalClose();
  await refetch();
  emit('setApproveStatusForFaceId', {
    ...currentClient.value,
    isFaceUploaded: status,
  });
};

watch(
  () => props.isModalVisible,
  (newValue) => {
    if (newValue) {
      load() || refetch();
    }
  }
);

const sensors = ref([]);

const currentUserId = ref(null);

const {
  result: getAllUserFaceIdStatuses,
  load,
  loading,
  refetch,
} = useLazyQuery(getUserFaceIdStatuses, () => ({
  userId: currentUserId.value,
}));

watch(getAllUserFaceIdStatuses, (newValue) => {
  sensors.value = newValue.getUserFaceIdStatuses;
});

watch(
  () => props.client,
  (data) => {
    currentClient.value = data;
    // load() || refetch();
    currentUserId.value = data.user.id;
  }
);

watch(
  () => props.clientLoaderId,
  (data) => {
    if (currentClient.value.id === data.id) {
      isLoading.value = true;
    }
  }
);

const sensorsIds = ref([]);

const {
  mutate: updateUserPanelFaceIdMutation,
  loading: updateUserPanelFaceIdLoading,
  onDone,
  onError,
} = useMutation(updateUserPanelFaceId);

const onPanelUpdate = async (sensorId) => {
  sensorsIds.value.push(sensorId);
  const { data } = await updateUserPanelFaceIdMutation({
    userId: currentClient.value.user.id,
    sensorId,
  });
  const sensorIndex = sensors.value.findIndex(
    (obj) => obj.sensorId === sensorId
  );

  const tempSensors = [...sensors.value];
  tempSensors[sensorIndex] = {
    ...tempSensors[sensorIndex],
    sensorStatus: data.updateUserPanelFaceId,
  };
  sensors.value = tempSensors;

  console.log(sensors.value);
  const index = sensorsIds.value.indexOf(sensorId);
  if (index > -1) {
    sensorsIds.value.splice(index, 1);
  }
};

onDone(async () => {
  store.dispatch('notification/showNotification', {
    text: `Загрузка прошла успешно`,
    type: 'success',
  });
  // await refetch();
});
onError((error) => {
  store.dispatch('notification/showNotification', {
    text: error,
    type: 'error',
  });
  console.error(error);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/colors';

.faceId-view {
  display: flex;
  margin-right: 40px;

  &__title {
    font-size: 18px;
    line-height: 20px;
    color: map-get($black-text, 'normal');
  }

  &__faceId {
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    height: 476px;
    position: relative;
    &-image {
      width: 100%;
      overflow: hidden;
      height: 100%;
      object-fit: cover;
      background-color: rgba($color: var(--primary-color), $alpha: 0.5);
    }
  }
}
.action {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 43px;
  background: linear-gradient(
    90deg,
    var(--white) 5%,
    rgba(255, 255, 255, 0.65) 100%
  );
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action__text {
  color: var(--dark);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.action__button {
  border-radius: 50%;
  border: 2px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.action__button_check {
  background-color: var(--primary-color);
}
.action__button_cancel {
  background-color: var(--grey-text-color);
}

.action__submit-icon {
  color: var(--white);
}
.action__button_disabled {
  pointer-events: none;
  opacity: 0.7;
}
.modal__body {
  display: flex;
}
.client__info-row {
  display: flex;
  align-items: center;
}
.client__info-row:not(:last-child) {
  margin-bottom: 10px;
}
.client__info-title {
  color: var(--grey-text-color);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 10px;
}
.client__info-value {
  color: var(--black);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.client__info {
  margin-bottom: 20px;
}
.client__status-list {
  overflow-y: auto;
  height: 340px;
}
.client__status .client__info-title {
  margin-bottom: 10px;
}
.status__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status__row:not(:last-child) {
  margin-bottom: 10px;
}
.status__row .client__info-value {
  margin-right: 20px;
}
.status__row .action__button {
  width: 32px;
  height: 32px;
  position: relative;
}
.status__row .action__button_cancel {
  background-color: var(db4e4e);
}
.status__row .action__submit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.status__row .action__button .action__submit-icon {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}
.status__row .action__button:hover .action__submit-icon {
  opacity: 0;
  visibility: hidden;
}
.status__row .action__button .action__submit-icon_refrech {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.status__row .action__button:hover .action__submit-icon_refrech {
  opacity: 1;
  visibility: visible;
}
.action__button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.action__button.disabled .action__submit-icon {
  opacity: 0;
  visibility: hidden;
}
.action__button.disabled .action__submit-icon_loading {
  opacity: 1;
  visibility: visible;
}
.action__button.disabled {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
