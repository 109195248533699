<template>
  <Transition>
    <div class="splash" ref="splash" v-if="isSplashScreenVisible">
      <div class="splash__body">
        <div class="splash__logo">
          <img src="@/assets/images/logo-domion.svg" alt="logo" class="image splash__logo-img" />
        </div>
        <div class="splash__gif">
          <img src="@/assets/images/splash-screen.gif" alt="splash-screen" class="image" />
        </div>
        <div class="splash__text">
          <p>Пожалуйста, откройте сайт с экрана побольше</p>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { ref, onMounted } from 'vue';
const splash = ref(null);
const isSplashScreenVisible = ref(null);
const toggleSplashScreen = () => {
  if (window.innerWidth < 1200) {
    isSplashScreenVisible.value = true;
    document.body.classList.add('lock');
  } else {
    isSplashScreenVisible.value = false;
    document.body.classList.remove('lock');
  }
};
onMounted(() => {
  toggleSplashScreen();
  window.addEventListener('resize', toggleSplashScreen);
});
</script>
<style scoped>
.splash {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--white);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  user-select: none;
  overflow: scroll;
}

.splash__body {
  width: fit-content;
  background-color: #f9f9f9;
  height: fit-content;
  border-radius: 20px;
  overflow: hidden;
}

.splash__gif {
  max-width: 312px;
  border-radius: 20px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.splash__logo {
  width: 40px;
  margin: 20px 24px 0 auto;
}

.splash__logo-img {
  filter: var(--primary-svg);
}

.splash__text {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 247px;
}

.splash__text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--grey-text-color);
}
</style>
