<template>
  <div class="radio-button-group" :class="{ vertical: !isHorizontal }">
    <label
      :for="option.value"
      v-for="(option, idx) in options"
      :key="idx"
      class="radio__label"
    >
      <input
        type="radio"
        :name="name"
        class="radio__input"
        :value="option.value"
        :id="idx"
        :checked="defaultValue === option.value"
        @change="onChange"
      />
      <span class="radio__checkmark"></span>
      {{ option.label }}
    </label>
  </div>
</template>
<script setup>
  import { defineEmits, defineProps } from 'vue';
  defineProps({
    options: {
      type: Object,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
    },
  });
  const emit = defineEmits(['change']);

  const onChange = (event) => {
    emit('change', event.target.value);
  };
</script>
<style scoped>
  .radio-button-group {
    display: flex;
    align-items: center;
  }
  .radio-button-group.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
  .radio-button-group.vertical .radio__label:not(:last-child) {
    margin-bottom: 10px;
  }
  .radio__label {
    position: relative;
    padding-left: 30px;
    margin-right: 20px;
    padding: 5px 0 5px 30px;
    color: var(--grey-text-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    white-space: nowrap;
  }
  .radio__input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0px;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .radio__checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: var(--light-bg);
    border: 1px solid var(--grey-text-color);
    z-index: 1;
  }
  .radio-button-group .radio__input:checked ~ .radio__checkmark {
    background-color: var(--white);
    border: 1px solid var(--primary-color);
  }

  .radio__checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }

  .radio-button-group .radio__input:checked ~ .radio__checkmark::after {
    display: block;
  }

  .radio-button-group .radio__checkmark::after {
    top: 10px;
    left: 10px;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--primary-color);
  }
</style>