<template>
  <div
    class="base-select"
    :class="[{
      'is-opened': isVisible,
      'has-label': label,
    }]"
    @mouseleave="onBlur"
  >
    <mdicon name="chevron-down" class="base-select__chevron" size="24"></mdicon>
    <span class="base-select__layer" @click="onSelectClick"></span>
    <input
      class="base-select__field"
      type="text"
      :placeholder="placeholder"
      :value="currentData.name"
      ref="input"
      required
    />
    <span class="base-select__label">{{label}}</span>
    <div class="base-select__options" v-show="isVisible">
      <div
        class="base-select__option"
        v-for="item in getOptionList"
        :key="item.id"
        @click="onSelect(item)"
      >
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'base-select',
    props: {
      label: String,
      value: {
        type: [String, Number],
        default: '',
      },
      placeholder: String,
      options: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isVisible: false,
        currentData: {
          id: null,
          name: null,
        },
      };
    },
    created() {
      if (this.value && this.options) {
        this.currentData = this.options.find(({name}) => name === this.value) || {};
      }
    },
    watch: {
      value() {
        this.setCurrentOption();
      },
      options() {
        this.setCurrentOption();
      },
    },
    computed: {
      getOptionList() {
        return this.options;
      },
    },
    methods: {
      setCurrentOption() {
        if (this.value && this.options) {
          // eslint-disable-next-line eqeqeq
          this.currentData = this.options.find(({name}) => name === this.value) || {};
        } else {
          this.currentData = {};
        }
      },
      onSelectClick() {
        if (!this.readonly) {
          this.$refs.input.focus();
          this.isVisible = !this.isVisible;
        }
      },
      onBlur() {
        if (!this.readonly) {
          this.isVisible = false;
          this.$refs.input.blur();
        }
      },
      onSelect(opt) {
        this.currentData = {
          id: opt.id,
          name: opt.name,
          value: opt.value
        };
        this.isVisible = false;
        this.$emit('input', opt.id);
        this.$emit('change', opt);
      },
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/colors';

  .base-select {
    position: relative;
    padding-top: 10px;

    &__chevron {
      color: map-get($lightgray-text, 'normal');
      position: absolute;
      top: 20px;
      right: 5px;
      //transition: transform .2s ease-in-out;
    }

    &.is-opened .base-select__chevron {
      transform: rotate(180deg);
    }

    &__layer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      content: '';
    }

    &__label {
      top: 0;
      font-size: 12px;
      position: absolute;
      left: 0;
      font-family: 'Golos', sans-serif;
      font-style: normal;
      font-weight: normal;
      color: map-get($lightblue-text, 'normal');
      line-height: 22px;
      transition: 200ms;
    }

    &__field {
      padding: 8px 40px 5px 0;
      width: 100%;
      font-family: 'Golos', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      border: none;
      border-bottom: 1px solid map-get($lightgray-stroke, 'hover');
      background: transparent;
      caret-color: transparent;

      &:focus {
        outline: none;
        border-bottom-color: map-get($secondary-text, 'hover');
      }
    }

    &__options {
      border-radius: 4px;
      position: absolute;
      z-index: 32001;
      width: 100%;
      max-height: 160px;
      overflow-y: auto;
      background: map-get($white-text, 'normal');
      box-shadow: 0 0 32px rgba(42, 132, 172, 0.1), 0 8px 8px rgba(42, 132, 172, 0.1), 0 2px 2px rgba(42, 132, 172, 0.1);
    }

    &__option {
      position: relative;
      padding: 9px 12px;
      min-height: 40px;
      font-family: 'Golos', sans-serif;
      font-size: 15px;
      color: map-get($black-text, 'normal');
      cursor: pointer;

      &:hover {
        background: linear-gradient(0deg, rgba(3, 138, 190, 0.02), rgba(3, 138, 190, 0.02)), var(--white);
        //color: map-get($primary-text, 'hover');
      }
    }
  }
</style>
