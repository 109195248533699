<template>
  <div class="d-flex">
    <h1 class="header header_black">Клиенты гринвуд</h1>
  </div>

  <div class="filter">
    <div class="search-input-wrapper">
      <input type="text" class="search-input" v-model="filterObj.search" />
      <mdicon size="20" name="magnify" class="search-input-icon" />
    </div>
    <div class="filter__item">
      <p class="filter__label">Статус</p>
      <v-select
        class="modal-select modal-select_filter"
        :options="filterOptions"
        v-model="filterObj.searchByDebt"
        :reduce="(value) => value.value"
        label="title"
      >
      </v-select>
    </div>
    <div class="filter__item">
      <p class="filter__label">Комплекс</p>
      <v-select
        class="modal-select modal-select_filter"
        :options="complexOptions"
        v-model="filterObj.complexes"
        :reduce="(value) => +value.id"
        placeholder="Все"
        :multiple="true"
        label="value"
      >
      </v-select>
    </div>
  </div>

  <div class="clients-table">
    <div v-if="isLoading" class="mt-20 text-center">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <base-table v-else>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="14%">ЖК</base-table-head-cell>
          <base-table-head-cell width="20%">ФИО</base-table-head-cell>
          <base-table-head-cell width="16%">Роль</base-table-head-cell>
          <base-table-head-cell width="16%"
            >Номер телефона</base-table-head-cell
          >
          <base-table-head-cell width="20%">Адрес дома</base-table-head-cell>
          <base-table-head-cell width="14%">Лицевой счет</base-table-head-cell>
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          :class="[
            'clients-table__row',
            { 'clients-table__row_red': client.ownership.debt > 0 },
          ]"
          v-for="client in clients"
          :key="client.id"
          @click="
            $router.push(`/app/${role}/clients/card/${client.ownership.id}`)
          "
        >
          <base-table-cell width="14%"
            >{{ client.complex.name || '-' }}
          </base-table-cell>
          <base-table-cell width="20%"
            >{{ client.user.lastName }}
            {{ client.user.firstName }}
            {{ client.user.patronymic }}
          </base-table-cell>
          <base-table-cell width="16%">{{
            userRole(client.user) || '-'
          }}</base-table-cell>
          <base-table-cell width="16%">{{
            userPhone(client.user.phone) || '-'
          }}</base-table-cell>
          <base-table-cell width="20%">
            {{ client.building.address }}
          </base-table-cell>
          <base-table-cell width="14%">
            {{ client.ownership.account }}
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
    <the-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @pageChanged="onChangePage"
      class="pagination"
    ></the-pagination>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { getAllClients, getAllComplexes } from '@/graphql/Client.graphql';
import ThePagination from '@/components/ThePagination.vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { useRoute, useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const router = useRouter();
const route = useRoute();

defineProps({
  role: {
    type: String,
    required: true,
  },
});

const store = useStore();

const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const isLoading = ref(true);
const clients = ref([]);

const totalPages = ref(0);
const elementsOnPage = ref(5);

const complexOptions = ref([]);

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (value) => {
  complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const getComplexesRoute = () => {
  const complexes = route.query.complexes;

  if (complexes) {
    return Array.isArray(complexes) ? complexes.map((id) => +id) : [+complexes];
  }
  return [];
};

const filterObj = reactive({
  searchByDebt: !route.query.hasDebt
    ? null
    : route.query.hasDebt === 'true' || false,
  search: route.query.search || null,
  complexes: getComplexesRoute(),
});

let {
  result: clientsResult,
  fetchMore,
  refetch,
} = useQuery(getAllClients, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    q: filterObj.search,
    hasDebt: filterObj.searchByDebt,
    complexIds: filterObj.complexes,
  },
});

// Пагинация с бэка
const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      clients.value = [...fetchMoreResult.getAllClients.clients];
    },
  });
};

onMounted(() => {
  loadMore(+route.query.page || 0);
});

watch(clientsResult, (newValue) => {
  getTotalPages(newValue?.getAllClients.filtered);
  isLoading.value = false;
});

const currentPage = ref(+route.query.page || 1);

const onChangePage = (page, filterList = null) => {
  router.replace({
    route,
    query: {
      page,
      search: filterList?.search,
      hasDebt: filterList?.searchByDebt,
      complexes: filterList?.complexes,
    },
  });
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const filterOptions = ref([
  {
    title: 'Все',
    value: null,
  },
  {
    title: 'С задолженностью',
    value: true,
  },
  {
    title: 'Без задолженности',
    value: false,
  },
]);

let onLoad = true;

watch(filterObj, async (newValue) => {
  const {
    data: { getAllClients },
  } = await refetch({
    filter: {
      q: newValue.search,
      hasDebt: newValue.searchByDebt,
      complexIds: newValue.complexes,
    },
  });
  clients.value = [...getAllClients.clients];

  getTotalPages(getAllClients.filtered);
  if (onLoad) {
    onChangePage(+route.query.page || 1, newValue);
    onLoad = false;
  } else {
    onChangePage(1, newValue);
  }

  if (!clients.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
});

const userRole = (user) => {
  return user.role === 'OWNER' ? 'Собственник' : 'Жилец';
};

const userPhone = (phone) => {
  let mask = phone?.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  return mask
    ? `+${mask[1]}(${mask[2]})${mask[3]}-${mask[4]}-${mask[5]}`
    : null;
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.pagination {
  margin-top: 25px;
}

.clients-table {
  border-top: 1px solid var(--grey-opacity);
  padding-top: 30px;

  &__row div {
    cursor: pointer;
  }

  &__row_red div {
    color: var(--button-error-disable-color);
  }
}

.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}

.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}

.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.filter {
  display: flex;
  align-items: center;

  &__label {
    color: map-get($black-text, 'normal');
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 22px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  &__item:not(:last-child) {
    margin-right: 17px;
  }

  &__input {
    margin-top: 7px;
    background: var(--grey-opacity-light);
    border-radius: 2px;
    font-size: 12px;
    line-height: 22px;
    color: map-get($black-text, 'normal');
    border: none;
    height: 24px;
    padding-left: 10px;
  }
}
.modal-select_filter {
  width: 260px;
}
</style>
