<template>
  <base-popup
    class="modal"
    size="large"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    :title="manager ? 'Редактировать менеджера' : 'Добавить менеджера'"
    @close="onModalClose"
  >
    <form class="form" @submit.prevent="onFormSubmit">
      <div class="form-row">
        <div class="col">
          <div class="form__field">
            <base-input
              label="Имя*"
              placeholder="Имя"
              :value="currentManager.user.firstName"
              @input="onManagerUserInput($event, 'firstName')"
            />
          </div>
        </div>
        <div class="col">
          <div class="form__field">
            <base-input
              label="Фамилия*"
              placeholder="Фамилия"
              :value="currentManager.user.lastName"
              @input="onManagerUserInput($event, 'lastName')"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form__field">
            <base-select
              v-if="manager"
              label="Статус"
              :value="userStatus"
              :options="[
                {
                  id: 1,
                  name: 'Активный',
                  value: true,
                },
                {
                  id: 2,
                  name: 'Отключен',
                  value: false,
                },
              ]"
              @change="onSelectManagerStatus"
              placeholder="Статус"
            />
            <base-input
              v-else
              label="Email"
              placeholder="Email"
              :value="currentManager.user.email"
              @input="onManagerUserInput($event, 'email')"
            />
          </div>
        </div>
        <div class="col">
          <div class="form__field">
            <base-input
              label="Телефон*"
              placeholder="Телефон"
              :value="currentManager.user.phone"
              @input="onManagerUserInput($event, 'phone')"
            />
          </div>
        </div>
      </div>
      <div>
        <BaseCheckbox
          label="Получать СМС"
          value="1"
          v-model:values="sendSMSCheckboxActivity"
          taggable
          size="medium"
        />
      </div>
      <div class="form__field text-right mt-24">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />
        <base-button
          text="Сохранить"
          color="green"
          :disabled="isFormDataInvalid"
          @click.prevent="onFormSubmit"
        />
      </div>
    </form>
  </base-popup>
</template>

<script>
import { ref, watch, computed } from 'vue';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import {
  BaseInput,
  BaseButton,
  BaseSelect,
  BasePopup,
  BaseCheckbox,
} from '@/components/atoms';
import {
  updateManager,
  registerManager,
  updateManagerStatus,
} from '@/graphql/Managment.graphql';
import { useMutation } from '@vue/apollo-composable';

export default {
  name: 'manager-modal',
  components: {
    BasePopup,
    BaseInput,
    BaseButton,
    BaseSelect,
    BaseCheckbox,
  },
  props: {
    manager: {
      active: Boolean,
      id: Number,
      user: {
        id: Number,
        firstName: String,
        lastName: String,
        email: String,
        phone: String,
      },
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const emptyManager = {
      id: null,
      active: true,
      receiveSMS: false,
      user: {
        id: null,
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
      },
    };
    const currentManager = ref({
      id: null,
      active: true,
      receiveSMS: false,
      user: {
        id: null,
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
      },
    });

    const rules = computed(() => {
      return {
        user: {
          phone: { required },
          firstName: { required },
          lastName: { required },
          email: {
            required: (val) => !!val,
          },
        },
      };
    });
    const isFormDataInvalid = computed(() => {
      return v$.value.$invalid;
    });
    const v$ = useVuelidate(rules, currentManager);

    const { mutate: updateEmployeeUserMutation } = useMutation(updateManager);
    const { mutate: updateManagerStatusMutation } =
      useMutation(updateManagerStatus);
    const { mutate: registerManagerMutation } = useMutation(registerManager);

    //todo почитать как можно чистить реактивные объекты и применить для очистки currentManger
    // const clearObjectValues = (obj) => {
    //  Object.keys(obj).forEach((key) => {
    //     if (typeof obj[key] === 'object') clearObjectValues(obj[key]);
    //     else {
    //       obj[key] = '';
    //     }
    //   });
    // };

    const onManagerUserInput = (event, type) => {
      currentManager.value.user[type] = event.target.value;
    };

    const onSelectManagerStatus = (opt) => {
      currentManager.value.active = opt.value;
    };

    const sendSMSCheckboxActivity = ref([]);

    const isSendSMSCheckboxActive = ref(false);

    watch(sendSMSCheckboxActivity, (newValue) => {
      newValue.length
        ? (isSendSMSCheckboxActive.value = true)
        : (isSendSMSCheckboxActive.value = false);
      currentManager.value.receiveSMS = isSendSMSCheckboxActive.value;
    });

    const onManagerUpdate = async () => {
      const {
        user: { id: userId, firstName, lastName, phone },
        receiveSMS,
        active,
        id,
      } = currentManager.value;

      await updateEmployeeUserMutation({
        data: {
          firstName,
          lastName,
          phone,
        },
        userId,
      });

      await updateManagerStatusMutation({
        data: {
          active,
          receiveSMS,
        },
        id,
      });

      emit('update', currentManager.value);
    };

    const onManagerCreate = async () => {
      const {
        user: { firstName, lastName, email, phone },
        receiveSMS,
      } = currentManager.value;

      const { data } = await registerManagerMutation({
        data: {
          user: {
            firstName,
            lastName,
            email,
            phone,
          },
          receiveSMS,
          role: 'MANAGER',
        },
      });

      emit('create', data.registerEmployee);
      console.log('managerData', { data });
    };

    const onFormSubmit = async () => {
      if (isFormDataInvalid.value) return;

      if (props.manager) {
        await onManagerUpdate();
      } else {
        await onManagerCreate();
      }

      onModalClose();
    };

    const userStatus = computed(() => {
      return currentManager.value.active ? 'Активный' : 'Отключен';
    });

    watch(
      () => props.manager,
      (data) => {
        if (data) {
          currentManager.value = JSON.parse(JSON.stringify(data));
          console.log('manager', currentManager.value);
        } else {
          currentManager.value = ref({ ...emptyManager }).value;
        }
        currentManager.value.receiveSMS
          ? (sendSMSCheckboxActivity.value = ['1'])
          : (sendSMSCheckboxActivity.value = []);
      }
    );

    const onModalClose = () => {
      currentManager.value = ref({ ...emptyManager }).value;
      emit('close');
    };

    return {
      isFormDataInvalid,
      v$,
      currentManager,
      userStatus,
      onSelectManagerStatus,
      onManagerUserInput,
      onModalClose,
      onFormSubmit,
      sendSMSCheckboxActivity,
    };
  },
};
</script>

<style lang="scss" scoped></style>
