<template>
  <div class="mt-40">
    <base-table>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="5%">№</base-table-head-cell>
          <base-table-head-cell width="15%">Статус</base-table-head-cell>
          <base-table-head-cell width="10%">Дом/Квартира</base-table-head-cell>
          <base-table-head-cell width="10%">Дата</base-table-head-cell>
          <base-table-head-cell width="30%">Описание</base-table-head-cell>
          <base-table-head-cell width="10%">Категория</base-table-head-cell>
          <base-table-head-cell width="10%">Исполнитель</base-table-head-cell>
          <base-table-head-cell width="10%"></base-table-head-cell>
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          @click="onClick(claim)"
          class="table-row"
          v-for="claim in claimsList"
          :key="claim?.id"
        >
          <base-table-cell width="5%"> {{ claim?.id }} </base-table-cell>
          <base-table-cell width="15%"
            ><span class="status" :class="statusClass(claim?.status)">
              {{ getStatus(claim?.status) }}
            </span>
          </base-table-cell>
          <base-table-cell width="10%">
            Дом {{ claim.dataJson.buildingNumber }}, кв.
            {{ claim?.dataJson?.apartmentNumber }}</base-table-cell
          >
          <base-table-cell width="10%">
            {{ new Date(claim?.dateFrom).toLocaleDateString('ru-RU') }}
            <!-- {{ claim.dateFrom }} -->
          </base-table-cell>
          <base-table-cell width="30%">
            {{ truncate(claim?.description, 115) }}
          </base-table-cell>
          <base-table-cell width="10%">
            <div v-if="claim.category1 || claim.category2 || claim.category3">
              {{ getCategory(claim.category1) }}
              {{ getCategory(claim.category2) }}
              {{ getCategory(claim.category3) }}
            </div>
            <div class="red" v-else>Категория не выбрана</div>
          </base-table-cell>
          <base-table-cell width="10%">
            <p v-if="claim?.dataJson?.executorName">
              {{ claim?.dataJson?.executorName }}
            </p>
            <p v-else class="red">Исполнитель не назначен</p>
          </base-table-cell>
          <base-table-cell width="10%" class="d-flex justify-content-center">
            <div class="message">
              <mdicon name="email-outline" size="30" class="message__icon" />
              <div class="message__point" v-if="claim.newCommentsCount">
                {{ claim.newCommentsCount }}
              </div>
            </div>
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, watch, ref } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { getStatuses, getAllCategories } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';

defineProps({
  claimsList: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['click']);

const onClick = (value) => {
  emit('click', value);
};
const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

const statusList = ref([]);
const isStatusListReady = ref(false);

const { result: getAllStatuses } = useQuery(getStatuses);

watch(getAllStatuses, (newValue) => {
  statusList.value = newValue.getStatuses;
  isStatusListReady.value = true;
});

const getStatus = (status) => {
  if (isStatusListReady.value) {
    statusList.value.forEach((item) => {
      if (item.name === status) {
        status = item.title;
      }
    });
  }
  return status;
};

const categoriesList = ref([]);
const isCategoriesListReady = ref(false);

const { result: getCategories } = useQuery(getAllCategories);

watch(getCategories, (newValue) => {
  categoriesList.value = newValue.getAllCategories;
  isCategoriesListReady.value = true;
});

const getCategory = (category) => {
  if (isCategoriesListReady.value) {
    categoriesList.value.forEach((item) => {
      if (item.id === category) {
        category = item.title;
      }
    });
  }
  return category;
};
const statusClass = (status) => {
  if (isStatusListReady.value) {
    switch (status) {
      case 'OPENED':
        return 'open';
      case 'PROCESSING':
        return 'processed';
      case 'IN_PROGRESS':
        return 'closed';
      case 'CLOSED':
        return 'closed';
      case 'REJECTED':
        return 'rejected';
    }
  }
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.table-row {
  cursor: pointer;
}
.red {
  color: map-get($red, 'normal');
}
.status {
  box-shadow: 0px 2px 2px var(--black-notification-shadow);
  border-radius: 6px;
  padding: 7px 15px;
}
.open {
  color: var(--primary-color);
  background: var(--white);
}
.processed {
  color: var(--grey-text-color);
  background: var(--white);
}
.closed {
  color: var(--primary-color);
  background: var(--primary-light-opacity-color);
}
.rejected {
  background: var(--button-error-secondary-active-color);
  color: var(--white);
}
.message {
  box-shadow: 0px 4px 10px var(--black-notification-shadow);
  padding: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.message__icon {
  color: var(--primary-color);
}
.message__point {
  position: absolute;
  top: -3px;
  right: -7px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
