<template>
  <base-popup
    class="modal"
    size="large"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    title="Редактировать новость"
    @close="onModalClose"
  >
    <form class="form">
      <!-- <div class="form-row"> -->
        <!-- <div class="col"> -->
          <div class="form__field">
            <base-textarea
              class="form__textinput"
              :style="{ height: currentNews.title.length >= 59 ? 'auto' : '68px' }"
              label="Тема"
              :value="currentNews.title"
              @input="onNewsInput($event, 'title')"
            />
            <!-- <base-input
              label="Тема"
              :value="currentNews.title"
              @input="onNewsInput($event, 'title')"
            /> -->
          </div>
        <!-- </div> -->
        <!-- <div class="col"> -->
          <div class="form__field">
            <base-textarea
              class="form__textarea"
              label="Содержимое"
              :value="currentNews.text"
              @input="onNewsInput($event, 'text')"
            />
            <!-- <base-input
              label="Содержимое"
              :value="currentNews.text"
              @input="onNewsInput($event, 'text')"
            /> -->
          </div>
        <!-- </div> -->
      <!-- </div> -->
      <div class="form__field text-right mt-24">
        <base-button
          text="Отменить"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />
        <base-button
          text="Сохранить"
          color="green"
          @click.prevent="onFormSubmit"
        />
      </div>
    </form>
  </base-popup>
</template>
<script>
import { ref, watch } from 'vue';
import { BasePopup, BaseButton, BaseTextarea } from '@/components/atoms';
import { updateNews } from '@/graphql/News.graphql';
import { useMutation } from '@vue/apollo-composable';

export default {
  components: {
    BaseButton,
    BasePopup,
    BaseTextarea
  },
  props: {
    news: {
      id: Number,
      title: String,
      text: String,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const emptyNews = {
      id: null,
      title: '',
      text: '',
    };

    const currentNews = ref({
      id: null,
      title: '',
      text: '',
    });

    const { mutate: updateNewsMutation } = useMutation(updateNews);

    const onNewsInput = (event, type) => {
      currentNews.value[type] = event.target.value;
    };

    const onNewsUpdate = async () => {
      const { title, text, id } = currentNews.value;

      await updateNewsMutation({
        data: {
          title,
          text,
        },
        id,
      });
      emit('update', currentNews.value);
    };

    const onFormSubmit = async () => {
      await onNewsUpdate();
      onModalClose();
    };

    watch(
      () => props.news,
      (data) => {
        if (data) {
          currentNews.value = JSON.parse(JSON.stringify(data));
        } else {
          currentNews.value = ref({ ...emptyNews }).value;
        }
      }
    );

    const onModalClose = () => {
      currentNews.value = ref({ ...emptyNews }).value;
      emit('close');
    };

    return {
      onNewsInput,
      onModalClose,
      onFormSubmit,
      currentNews,
    };
  },
};
</script>
<style scoped lang="scss">
.form__textarea {
  height: 200px;
}
.form__textinput {
}
</style>
