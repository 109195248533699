<template>
  <div class="mt-40">
    <base-table>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="15%">Лицевой счет</base-table-head-cell>
          <base-table-head-cell width="30%">Адрес дома</base-table-head-cell>
          <base-table-head-cell width="55%"
            >Список автомобильных номеров</base-table-head-cell
          >
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          v-for="apartment in apartmentsList"
          :key="apartment.id"
          class="table-row cursor-pointer"
          @click="onClick(apartment)"
        >
          <base-table-cell width="15%">
            {{ apartment.plateNums.meta.apartmentAccount }}
          </base-table-cell>
          <base-table-cell width="30%">{{
            apartment.plateNums.meta.apartmentAddress
          }}</base-table-cell>
          <base-table-cell width="55%">
            <div
              v-if="apartment.plateNums?.plateNums?.length"
              class="numbers clip-1"
            >
              <p
                v-for="(item, idx) in apartment.plateNums.plateNums"
                :key="idx"
                class="numbers-item"
              >
                {{ item.num }}
              </p>
            </div>
            <div v-else>-</div>
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';

defineProps({
  apartmentsList: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['click']);

const onClick = (apartment) => {
  emit('click', apartment);
};
</script>
<style scoped>
.numbers {
  display: flex;
}
.numbers-item {
  font-weight: 700;
}
.numbers-item:not(:last-child) {
  margin-right: 20px;
}
</style>
