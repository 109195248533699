<template>
    <div class="client-second-slide">
        <div class="client-second-slide__banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
            <div class="client-second-slide__banner-text">
                <div class="client-second-slide__banner-text-title">Оплаты</div>
                <div class="client-second-slide__banner-text-subtitle">
                    {{ subtitle }}
                </div>
                <base-button @click="pushToPayments" text="Подробнее" color="green" size="big" /> 
            </div>
        </div>

        <div class="client-second-slide__image">
            <img class="client-fs__image-item" :src="rubImage" alt="rubles"/> 
        </div>
    </div>
</template>

<script setup>
import { BaseButton } from '@/components/atoms';
import { useRouter } from 'vue-router';
import greenRubles from '@/assets/images/claims-green/money.png';
import domionRubles from '@/assets/images/client/slides/rubles.png';
import greenBgRub from '@/assets/images/claims-green/bg-money.png';
import domionBgRub from '@/assets/images/client/slides/payments-bg.png';

const THEME = process.env.VUE_APP_THEME;
const router = useRouter();

const rubImage = THEME === 'domion' ? domionRubles : greenRubles;
const backgroundImage = THEME === 'domion' ? domionBgRub : greenBgRub; 
const subtitle = THEME === 'domion' ? 
`Оплачивайте коммунальные услуги удобно и без лишних хлопот с личным кабинетом Домион.\n\n Воспользуйтесь преимуществами:\n\n Следите за состоянием лицевого счета. Просматривайте историю начислений. Контролируйте историю оплат. Оплачивайте счета в один клик. Простота и удобство в каждой операции.`
:
`Оплачивайте коммунальные услуги удобно и без лишних хлопот с личным кабинетом Грин.Дом.\n\n Воспользуйтесь преимуществами:\n\nСледите за состоянием лицевого счета. Просматривайте историю начислений. Контролируйте историю оплат. Оплачивайте счета в один клик. Простота и удобство в каждой операции.`

const pushToPayments = async () => {
    await router.push('/app/client/payments');
}
</script>

<style scoped lang="scss">
.client-second-slide {
    position: relative;
    width: 100%;
    height: 530px;
    display: flex;
    align-items: end;

    &__banner {
        padding: 47px 35px;
        width: 100%;
        min-height: 399px;
        // background-image: url('../../../../../../src/assets/images/client/slides/payments-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 29px;
        box-sizing: border-box;

        &-text {
            max-width: 50%;
            text-align: left;

            &-title {
                margin-bottom: 20px;
                font-size: 37px;
                font-weight: 500;
                color: var(--primary-color);
            }

            &-subtitle {
                max-width: 408px;
                margin-bottom: 38px;
                font-size: 16px;
                font-weight: 500;
                opacity: .5;
            }
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>