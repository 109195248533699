<template>
  <base-popup
    size="largest"
    :is-visible="isParkingModalVisible"
    @close="onModalClose"
    :title="`Место ${parking?.name}`"
  >
    <div class="parking-row mb-3">
      <span class="text-thin">Собственник паркинга: &nbsp;</span>
      <span class="text">{{ parking.meta.parkingOwnerFIO }}</span>
    </div>
    <div class="parking-row mb-3">
      <span class="text-thin">Доступ к управлению: &nbsp;</span>
      <span class="text">{{ parking.meta.parkingTenantFIO || '-' }}</span>
    </div>
    <div class="parking-row mb-3">
      <span class="text-thin">Лицевой счет: &nbsp;</span>
      <span class="text">{{ parking?.account }}</span>
      <!-- <div class="parking-row mr-4">
        <span class="text-thin">Автомобильный номер: &nbsp;</span>
        <span class="text">{{ parking?.currentPlateNum || '-' }}</span>
      </div> -->
    </div>
    <div class="parking-row">
      <span class="text-thin">Уровень: &nbsp;</span>
      <span class="text">{{ parking?.meta.parkingLevel || '-' }}</span>
    </div>
    <div class="d-flex justify-content-between select-group mb-3 mt-40">
      <v-select
        class="claim-modal-select modal-select disabled"
        :options="complexOptions"
        v-model="complexValue"
        placeholder="Комплекс*"
        :reduce="(value) => value?.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template></v-select
      >
      <v-select
        class="claim-modal-select modal-select"
        :options="buildingOptions"
        v-model="buildingValue"
        placeholder="Дом*"
        :reduce="(value) => value?.id"
        label="value"
      >
        <template v-slot:no-options>
          <div v-if="!complexValue">Сначала выберите комплекс</div>
          <div v-else>Извините, ничего не найдено</div>
        </template>
      </v-select>
      <v-select
        class="claim-modal-select modal-select"
        :options="apartmentsOptions"
        v-model="apartmentsValue"
        placeholder="Квартира*"
        :reduce="(value) => value?.id"
        label="value"
        @option:selected="onApartmentSelected"
      >
        <template v-slot:no-options>
          <div v-if="!buildingValue">Сначала выберите дом</div>
          <div v-else>Извините, ничего не найдено</div>
        </template></v-select
      >
    </div>
    <!-- <div class="parking-row col mb-3" v-if="tenants.length">
      <div class="text-thin mb-2">Арендаторы: &nbsp;</div>
      <div
        v-for="item in tenants"
        :key="item.owner?.id"
        class="text parking-row mb-2"
      >
        {{ item.owner.user.lastName }} {{ item.owner.user.firstName }}
        {{ item.owner.user.patronymic }}
      </div>
    </div>-->
    <!-- <div class="parking-row mb-3">
      <input
        type="text"
        placeholder="Введите номер..."
        class="modal-input"
        v-model="currentParking.number"
      />
    </div> -->
    <!-- <div class="parking-row">
      <v-select
        class="modal-select"
        :options="clients"
        placeholder="Добавить арендатора"
        multiple
        @option:selected="onOptionSelected"
        @option:deselected="onOptionDeselected"
        :reduce="(value) => value.id"
        label="value"
        :filterable="false"
        @search="onSearch"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template>
      </v-select>
    </div> -->
    <div class="d-flex justify-content-end mt-20">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button text="Сохранить" color="green" @click="onSubmit" />
    </div>
  </base-popup>
</template>
<script setup>
import { updateParkingPlaceApartmentIdForUI } from '@/graphql/Parking.graphql';
import { useMutation } from '@vue/apollo-composable';
import { BasePopup, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { getAllClientsForParking } from '@/graphql/Client.graphql';
import {
  getAllComplexes,
  getBuildings,
  getApartments,
} from '@/graphql/Claims.graphql';

const store = useStore();

const props = defineProps({
  isParkingModalVisible: {
    type: Boolean,
    default: false,
  },
  parking: {},
});
watch(
  () => props.isParkingModalVisible,
  async (newValue) => {
    if (newValue) {
      currentParking.value = {
        id: props.parking?.id,
        number: props.currentPlateNum,
      };
      // tenants.value = props.parking.owners.filter(
      //   (item) => item.type === 'TENANT'
      // );
      complexValue.value = props.parking.meta?.complexId || 1;
      buildingValue.value = props.parking.meta?.buildingId;
      if (buildingValue.value) {
        apartmentsValue.value = props.parking?.apartmentId;
      }
    }
  }
);

const emit = defineEmits(['close', 'update']);

const currentParking = ref({
  id: null,
  number: null,
  apartment: null,
  complex: null,
  building: null,
});

const complexOptions = ref([]);

const buildingOptions = ref([]);

const apartmentsOptions = ref([]);

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (value) => {
  complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const { result: allBuildings } = useQuery(getBuildings, {
  complexId: 1,
});
// const { result: allBuildings, refetch: fetchMoreBuildings } = useQuery(
//   getBuildings,
//   {
//     complexId: 1,
//   }
// );
console.log(allBuildings);
watch(allBuildings, (value) => {
  buildingOptions.value = value.getBuildings.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const { result: allApartments, refetch: fetchMoreApartments } = useQuery(
  getApartments,
  {
    buildingId: 1,
  }
);
console.log(allApartments);

const complexValue = ref(1);
const buildingValue = ref(null);
const apartmentsValue = ref(null);

// watch(complexValue, async (newValue) => {
//   currentParking.value.complex = newValue;
//   if (newValue) {
//     const {
//       data: { getBuildings },
//     } = await fetchMoreBuildings({
//       complexId: newValue,
//     });
//     buildingOptions.value = getBuildings.map(({ id, name }) => {
//       return {
//         id,
//         value: name,
//       };
//     });
//   } else {
//     buildingOptions.value = [];
//     buildingValue.value = null;
//   }
// });

watch(buildingValue, async (newValue) => {
  currentParking.value.building = newValue;
  if (newValue) {
    const {
      data: { getApartments },
    } = await fetchMoreApartments({
      buildingId: newValue,
    });
    apartmentsOptions.value = getApartments
      .map(({ id, number }) => {
        return {
          id,
          value: number,
        };
      })
      .sort((a, b) => a.value - b.value);
  } else {
    apartmentsValue.value = null;
    apartmentsOptions.value = [];
  }
});

const isNeedToShowConfirm = ref(false);

watch(apartmentsValue, (newValue) => {
  currentParking.value.apartment = newValue;
  console.log('currentApartment', currentParking.value.apartment);
});

const onApartmentSelected = () => {
  isNeedToShowConfirm.value = true;
};

const onModalClose = (_, isSubmit = false) => {
  let isClosed = true;
  if (!isSubmit && isNeedToShowConfirm.value) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emit('close');
    isNeedToShowConfirm.value = false;
    filterClients.value = '';
    complexValue.value = null;
  }
};

const filterClients = ref('');

// const onSearch = (search) => {
//   filterClients.value = search;
// };

// const onOptionSelected = (option) => {
//   currentParking.value.clients = [
//     ...currentParking.value.clients,
//     ...option.map((item) => item.id),
//   ];
//   filterClients.value = '';
// };

// const onOptionDeselected = (option) => {
//   const index = currentParking.value.clients.indexOf(option.id);
//   currentParking.value.clients.splice(index, 1);
// };

const clients = ref([]);

let { result: allClients } = useQuery(getAllClientsForParking, () => ({
  pagination: {
    offset: 0,
    limit: 7,
  },
  filter: {
    complexIds: [1],
    q: filterClients.value,
    apartmentId: apartmentsValue.value,
  },
}));

watch(allClients, (newValue) => {
  clients.value = newValue.getAllClients.clients.map((client) => {
    return {
      id: client.id,
      value: `${client.user.lastName} ${client.user.firstName} ${client.user.patronymic}`,
    };
  });
  // .filter((item) => {
  //   const ownerItem = props.parking?.owners.find(
  //     (item) => item.type === 'OWNER'
  //   );
  //   return item.id !== ownerItem?.owner.id;
  // });
});

watch(
  () => props.parking,
  (newValue) => {
    if (newValue) {
      currentParking.value = {
        ...currentParking.value,
        ...{
          id: newValue.id,
          number: newValue.currentPlateNum,
        },
      };
    }
  }
);

// const deleteTenant = (tenant) => {
//   const indexTenants = tenants.value.indexOf(tenant);
//   const index = currentParking.value.clients.indexOf(tenant.owner.id);

//   if (index !== -1) {
//     currentParking.value.clients.splice(index, 1);
//   }

//   if (indexTenants !== -1) {
//     tenants.value.splice(indexTenants, 1);
//   }
// };

const { mutate: updateParkingPlaceApartmentIdForUIMutation } = useMutation(
  updateParkingPlaceApartmentIdForUI
);

const onParkingUpdate = async () => {
  console.log('Parking', currentParking.value);
  const { id, apartment } = currentParking.value;
  try {
    const { data } = await updateParkingPlaceApartmentIdForUIMutation({
      id,
      apartmentId: apartment,
    });
    if (data.updateParkingPlaceApartmentIdForUI.status) {
      emit('update');
      store.dispatch('notification/showNotification', {
        text: 'Парковочное место успешно обновлено',
        type: 'success',
      });
      onModalClose(null, true);
    } else {
      throw new Error(data.updateParkingPlaceApartmentIdForUI.error);
    }
  } catch (error) {
    console.error(error);
    store.dispatch('notification/showNotification', {
      text: error,
      type: 'error',
    });
  }
};

const onSubmit = async () => {
  await onParkingUpdate();
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.parking-row {
  display: flex;
  align-items: center;
}
.parking-row.between {
  justify-content: space-between;
}
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  text-transform: uppercase;
}
.modal-input::placeholder {
  text-transform: none;
}

.modal-select {
  width: 100%;
}
.text-thin {
  color: var(--grey-text-color);
  font-weight: 500;
}
.text {
  color: var(--dark);
  font-weight: 600;
}
.parking-row__account {
  margin-right: 20px;
  width: 58%;
}
.parking-row.col {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.delete-item {
  margin-left: 5px;
  height: 26px;
  width: 26px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}
.select-group .claim-modal-select:not(:last-child),
.categories .claim-modal-select:not(:last-child) {
  margin-right: 20px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
