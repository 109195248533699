<template>
  <the-clients role="manager"></the-clients>
</template>
<script>
  import TheClients from '@/components/TheClients.vue';
  export default {
    name: 'clients-page',
    components: {
      TheClients,
    },
  };
</script>
<style lang="">
</style>