<template>
  <div class="card">
    <div
      class="card__actions d-flex justify-content-between align-items-center"
    >
      <div class="card__title">
        {{ manager.user.firstName }} {{ manager.user.lastName }}
      </div>
      <div class="d-flex">
        <div class="icon-wrapper mr-3" @click="updateManager">
          <mdicon name="pencil" size="20" />
        </div>
        <div class="icon-wrapper" @click="deleteManager">
          <mdicon name="delete" size="20" />
        </div>
      </div>
    </div>
    <div class="card__content">
      <div class="list">
        <div class="list__item">
          <div class="title">Электронная почта:</div>
          <div class="value">{{ manager.user.email }}</div>
        </div>
        <div class="list__item">
          <div class="title">Мобильный телефон:</div>
          <div class="value">{{ manager.user.phone }}</div>
        </div>
        <div class="list__item">
          <div class="title">Статус:</div>
          <div class="value">
            {{ manager.active ? 'Активный' : 'Отключен' }}
          </div>
        </div>
        <div class="list__item">
          <div class="title">СМС информирование:</div>
          <div class="value">
            <mdicon
              size="20"
              name="check"
              class="mr-3 green sms-icon"
              v-if="manager.receiveSMS"
            ></mdicon>
            <mdicon
              size="20"
              name="close"
              class="mr-3 red sms-icon"
              v-else
            ></mdicon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { toRefs } from 'vue';

  export default {
    name: 'Card',
    props: {
      manager: {
        id: Number,
        user: {
          firstName: String,
          lastName: String,
          email: String,
          phone: String,
        },
        active: String,
      },
    },
    setup(props, { emit }) {
      const { manager } = toRefs(props);
      const updateManager = () => emit('update');
      const deleteManager = () => {
        const {
          user: { lastName, firstName },
          id,
        } = manager.value;

        emit('delete', {
          id,
          user: {
            firstName,
            lastName,
          },
        });
      };
      return {
        deleteManager,
        updateManager,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/_colors';

  .list {
    font-size: 14px;

    &__item {
      margin-bottom: 10px;
      display: flex;

      .title {
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.5);
      }

      .value {
      }
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-notification-shadow);
    color: #525252;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
  }

  .card {
    position: relative;
    width: 100%;
    height: auto;

    overflow: hidden;
    box-shadow: 0 8px 14px var(--black-popup-shadow);
    border-radius: 5px;
    transition: transform 0.4s;

    &__title {
      font-weight: 600;
      color: map-get($primary-text, 'normal');
    }

    &__actions {
      border-radius: 4px 4px 0 0;
      background-color: var(--light-bg);
      padding: 12px;
    }

    &__content {
      padding: 16px 12px 12px 12px;
    }

    &:hover {
      //transform: scale(1.05);
    }
    .sms-icon {
      display: block;
      margin-top: -3px;
    }
  }
</style>