<template>
<base-popup
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Фильтр должников"
    subtitle=""
    @close="onModalClose"
    >
    <div class="d-flex block">
        <div class="block__item">
          <span class="select-label">Название ЖК</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите ЖК"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
        <div class="block__item">
          <span class="select-label">Длительность задолженности</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите длительность"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
    </div>

    <div class="d-flex block">
        <div class="block__item">
          <span class="select-label">Адрес</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите адрес"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
        <div class="block__item">
          <span class="select-label">Сумма задолжености от (руб.)</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите сумму"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
    </div>

    <div class="d-flex block">
        <div class="block__item">
          <span class="select-label">Кол-во уведомлений</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите кол-во"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
        <div class="block__item">
          <span class="select-label">Тип уведомления</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите тип"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
    </div>

    <div class="line"></div>

    <div class="d-flex justify-content-between align-items-center">
        <div>
            <span class="select-label">Выбрано 0 пользователей</span>
        </div>
        <div>
            <base-button
                text="Отмена"
                color="gray"
                class="mr-3"
                @click.prevent="onModalClose"
            />

            <base-button
                text="Сохранить"
                color="green"
                @click.prevent="onFormSubmit"
            />
        </div>
    </div>
</base-popup>
</template>
<script setup>
import { defineEmits, defineProps, reactive, ref } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms/';

const emit = defineEmits(['close']);

defineProps({
    isModalVisible: {
    type: Boolean,
    default: false,
  },
});

const debthPeriodOptions = ref([]);
const filters = reactive({
    period: ''
});

const onFormSubmit = () => {};

const onModalClose = () => {
    emit('close');
};
</script>

<style scoped lang="scss">
.block {
    gap: 12px;
    margin-bottom: 15px;

    &__item {
        width: 100%;
    }
}

.select-label {
  font-size: 12px;
  margin-bottom: 9px;
  display: block;
}

.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;  
}
</style>