<template>
  <div class="mt-40">
    <base-table>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="25%">Собственник</base-table-head-cell>
          <base-table-head-cell width="25%">Доступ</base-table-head-cell>
          <base-table-head-cell width="30%">Адрес дома</base-table-head-cell>
          <base-table-head-cell width="10%">Паркоместо</base-table-head-cell>
          <base-table-head-cell width="10%">Уровень</base-table-head-cell>
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          v-for="parking in parkingList"
          :key="parking.id"
          class="table-row cursor-pointer"
          @click="onClick(parking)"
        >
          <base-table-cell width="25%">
            {{ parking.meta.parkingOwnerFIO || '-' }}
          </base-table-cell>
          <base-table-cell width="25%">
            {{ parking.meta.parkingTenantFIO || '-' }}
          </base-table-cell>
          <base-table-cell width="30%">{{
            parking.meta.apartmentAddress || '-'
          }}</base-table-cell>
          <base-table-cell width="10%">{{
            parking?.name || '-'
          }}</base-table-cell>
          <base-table-cell width="10%">{{
            parking.meta.parkingLevel || '-'
          }}</base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
</template>
<script setup>
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { defineEmits, defineProps } from 'vue';

defineProps({
  parkingList: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['click']);

const onClick = (parking) => {
  emit('click', parking);
};

// const ownerName = (parking) => {
//   const owner = parking?.owners.find((item) => item?.type === 'OWNER').owner
//     .user;
//   return `${owner.lastName} ${owner.firstName} ${owner.patronymic}`;
// };
</script>
<style scoped></style>
