<template>
  <form class="form mt-24" @submit.prevent="logIn">
    <div class="d-flex align-items-baseline">
      <span class="phone-icon">
        <i class="fas fa-at"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.phone"
          type="tel"
          placeholder="Email"
          class="input__field input__field_phone input__field_auth"
        />
      </div>
    </div>

    <div class="d-flex align-items-baseline">
      <span class="password-icon">
        <i class="fas fa-unlock-alt"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.password"
          type="password"
          placeholder="Пароль"
          class="input__field input__field_password input__field_auth mt-30"
          readonly
          onfocus="this.removeAttribute('readonly')"
        />
      </div>
    </div>
    <div class="mt-40 d-flex justify-content-center align-items-center">
      <div class="text-center">
        <base-button text="Войти" color="green" size="small" />
      </div>
      <!-- <router-link to="/forgot-password" class="forgot-password">
        Забыли пароль?
      </router-link> -->
    </div>
  </form>
</template>

<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

import { BaseButton } from '@/components/atoms';
import { logIn as Login } from '@/graphql/Login.graphql';

export default {
  emits: ['toggle-password-alert'],
  components: {
    BaseButton,
  },
  setup() {
    const store = useStore();
    const form = reactive({
      phone: '',
      password: '',
    });

    const router = useRouter();

    const { mutate: logIn, onError } = useMutation(Login, () => ({
      variables: {
        data: {
          email: form.phone,
          password: form.password,
        },
      },
      update: async (cache, { data }) => {
        await store.dispatch('app/setAuth', {
          token: data.logIn.token,
          role: data.logIn.role,
          user: '{}'
        });
        await router.push('/app');
      },
    }));

    onError((error) => {
      store.dispatch('notification/showNotification', {
        text: 'Ошибка авторизации. Логин или пароль неправильные.',
        type: 'error',
      });
      logErrorMessages(error);
    });

    return {
      form,
      logIn,
    };
  },
};
</script>

<style scoped lang="scss">
.form {
  width: 300px;
  margin: 0 auto;
}

.forgot-password {
  display: inline-block;

  font-size: 12px;
  line-height: 14px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

.phone-icon,
.password-icon {
  margin-right: 10px;
  color: var(--primary-color);
}
</style>