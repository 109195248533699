const state = {
  auth: {
    token: localStorage.getItem('token') || null,
    role: localStorage.getItem('role') || null,
    user: localStorage.getItem('user') || null
  },
};

const getters = {
  userRole: () => {
    return state.auth.role;
  },
  userToken: () => {
    return state.auth.token;
  },
  getUser: () => {
    return state.auth.user;
  },
};

const actions = {
  logout({ commit }) {
    commit('LOGOUT');
  },
  async setAuth({ commit }, data) {
    saveAuthData(commit, data);
    return data;
  },
  getUser() {
    return state.auth.user;
  }
};

const mutations = {
  SET_AUTH(state, data) {
    state.auth = { ...state.auth, ...data };
  },
  LOGOUT(state) {
    state.auth = {
      token: null,
      role: null,
      user: null
    };

    localStorage.clear();
  },
};

function saveAuthData(commit, data) {
  commit('SET_AUTH', { ...data });

  localStorage.setItem('token', data.token);
  localStorage.setItem('role', data.role);
  localStorage.setItem('user', data.user);
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
