<template>
  <div class="el-table-row">
    <div class="el-table-row__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-table-row',
};
</script>

<style lang="scss">
@import '../atoms/assets/styles/colors';

.el {
  &-table-row {
    margin-bottom: 8px;
    width: 100%;

    &__content {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      z-index: 1;

      &::before {
        border-radius: 5px;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 55px;
        box-shadow: 0 8px 14px var(--black-popup-shadow);
      }
    }

    &:first-child {
      .el-table-cell,
      .el-table-head-cell {
        //border-top-left-radius: 5px;
        //border-bottom-left-radius: 5px;
        //border-top: 1px solid map-get($table, 'borderColor');
      }
    }

    &:last-child {
      .el-table-cell {
        //border-top-right-radius: 20px;
        //border-bottom-right-radius: 20px;
        //border-bottom: 1px solid map-get($table, 'borderColor');
      }
    }
  }
}
</style>
