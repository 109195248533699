<template>
  <Teleport to="body">
    <div class="loader">
      <div class="loader__popup">
        <img
          src="@/assets/images/loader.svg"
          alt="loading"
          class="loader__image"
        />
        <div class="loader__text">
          <p>Пожалуйста, подождите,</p>
          <p>Ваш запрос обрабатывается</p>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup></script>
<style scoped>
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e2e2e296;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Golos';
  z-index: 100;
}
.loader__popup {
  /* padding: 40px 30px; */
  background-color: var(--white);
  border-radius: 19px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px 20px 30px;
}
.loader__image {
  margin: -40px;
}
.loader__text {
  line-height: 1.5;
}
</style>
