<template>
  <h1>JK</h1>
</template>

<script>
  export default {
    name: 'house-complex'
  }
</script>

<style scoped>

</style>