<template>
  <div class="d-flex mt-30">
    <div class="search-input-wrapper">
      <input type="text" class="search-input" v-model="filterObj.search" />
      <mdicon size="20" name="magnify" class="search-input-icon" />
    </div>
    <button class="filter__button filter__button_disable" @click="clearFilters">
      <mdicon name="filter" class="filter__button-icon" />
      <span class="filter__button-text">Очистить фильтры</span>
      <mdicon
        name="close"
        class="filter__button-icon filter__button-icon_close"
      />
    </button>
  </div>
  <div class="d-flex mt-30">
    <v-select
      v-model="filterObj.category"
      :options="categoriesOptions"
      placeholder="Категория"
      class="filter-select"
      :reduce="(value) => value.id"
      label="value"
    >
      <template v-slot:no-options>
        <div>Извините, ничего не найдено</div>
      </template>
    </v-select>
    <v-select
      v-model="filterObj.executorPost"
      :options="executorsPostsOptions"
      placeholder="Должность"
      class="filter-select"
      :reduce="(value) => value.id"
      label="value"
    >
      <template v-slot:no-options>
        <div>Извините, ничего не найдено</div>
      </template>
    </v-select>
    <v-select
      v-model="filterObj.complex"
      :options="complexOptions"
      placeholder="Комплекс"
      class="filter-select"
      :reduce="(value) => value.id"
      label="value"
    >
      <template v-slot:no-options>
        <div>Извините, ничего не найдено</div>
      </template>
    </v-select>
  </div>
</template>
<script setup>
  import { ref, reactive, watch, defineEmits } from 'vue';
  import { getAllCategories, getAllComplexes } from '@/graphql/Claims.graphql';
  import { getAllPosts } from '@/graphql/ExecutorPost.graphql';
  import { useQuery } from '@vue/apollo-composable';

  const emit = defineEmits(['filter']);

  const filterObj = reactive({
    category: '',
    executorPost: '',
    complex: '',
    search: '',
  });

  watch(filterObj, (newValue) => {
    emit('filter', newValue);
  });

  const categoriesOptions = ref([]);
  const executorsPostsOptions = ref([]);
  const complexOptions = ref([]);

  const { result: allCategories } = useQuery(getAllCategories);

  watch(allCategories, (newValue) => {
    categoriesOptions.value = newValue.getAllCategories.map(({ title, id }) => {
      return {
        id,
        value: title,
      };
    });
  });

  const { result: allPosts } = useQuery(getAllPosts);

  watch(allPosts, (newValue) => {
    executorsPostsOptions.value = newValue.getAllPosts.map(({ id, title }) => {
      return {
        id,
        value: title,
      };
    });
  });

  const { result: allComplexes } = useQuery(getAllComplexes);

  watch(allComplexes, (newValue) => {
    complexOptions.value = newValue.getAllComplexes.map(({ id, name }) => {
      return {
        id,
        value: name,
      };
    });
  });

  const clearFilters = () => {
    filterObj.category = '';
    filterObj.executorPost = '';
    filterObj.complex = '';
    filterObj.search = '';
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/styles/_colors';
  .filter-select:not(:last-child) {
    margin-right: 20px;
  }
  .search-input-wrapper {
    position: relative;
    width: 300px;
    height: 34px;
    margin-right: 20px;
  }
  .search-input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: var(--light-secondary-bg);
    border: none;
    padding: 0 20px 0 40px;
    font-family: 'Golos';
    font-size: 16px;
  }
  .search-input-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    color: var(--grey-dark-color);
  }
  .filter__button {
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease 0s;
  }
  .filter__button_disable {
    background: map-get($lightgray-text, 'normal');
  }
  .filter__button_disable:hover {
    background: map-get($lightgray-text, 'hover');
  }
  .filter__button-text {
    font-family: 'Golos';
    font-weight: 600;
    font-size: 15px;
    margin: 0 20px 0 5px;
  }
</style>