export default {
  beforeMount(el) {
    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }

      const x = this.value
        .replace(/\D/g, '')
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (!x[2] && x[1] !== '') {
        this.value = x[1] === '8' ? x[1] : '8' + x[1];
      } else {
        this.value = !x[3]
          ? x[1] + x[2]
          : x[1] + ' (' + x[2] + ') ' + x[3] + (x[4] ? ' ' + x[4] : '');
      }
    };
  },
};

export const maskPhone = (newValue) => {
  if (!newValue || newValue.trim() === '') {
    return '+7';
  }

  let value = newValue;
  if (value.startsWith('+7')) {
    value = value.slice(2);
  }

  value = value.replace(/\D/g, '');
  const phoneArray = value.split('');
  const mask = '+7(___)___-__-__';

  let maskedPhone = '';
  let j = 0;
  for (let i = 0; i < mask.length; i++) {
    if (maskedPhone.length === 16) {
      break;
    }
    if (mask[i] === '_') {
      if (phoneArray[j]) {
        maskedPhone += phoneArray[j];
        j++;
      } else {
        break;
      }
    } else {
      maskedPhone += mask[i];
    }
  }

  return maskedPhone;
}

export const cleanPhoneNumber = (phoneNumber) => {
  var cleaned = phoneNumber.replace(/[\s()-]+/g, '');
  return cleaned;
}
