import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  // {
  //   path: '/app/admin/complexes',
  //   name: 'AdminComplexesPage',
  //   component: AuthPagesController,
  //   meta: {
  //     auth: true,
  //     roles: ['admin'],
  //   },
  // },
  {
    path: '/app/admin/complexes/:cid',
    name: 'AdminComplexPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/clients',
    name: 'AdminClientsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
    children: [
      {
        path: 'card/:id',
        name: 'AdminUserCardPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/app/admin/managers',
    name: 'AdminManagersPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/sensors',
    name: 'AdminSensorsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/clients-with-faceId',
    name: 'AdminClientsWithFaceIdPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/votes',
    name: 'AdminVotesPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/claims',
    name: 'AdminClaimsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/notifications',
    name: 'AdminNotificationsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/news',
    name: 'AdminNewsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/logs',
    name: 'AdminLogsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/cameras',
    name: 'AdminCamerasPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/directory',
    name: 'AdminDirectoryPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
    children: [
      {
        path: 'categories',
        name: 'AdminCategoriesPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
      {
        path: 'executors',
        name: 'AdminExecutorsPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
      {
        path: 'posts',
        name: 'AdminPostsPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
      {
        path: 'complexes',
        name: 'AdminComplexesPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
      {
        path: 'parking',
        name: 'AdminParkingPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
      {
        path: 'street-parking',
        name: 'AdminStreetParkingPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['admin'],
        },
      },
    ],
  },
];
