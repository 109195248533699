<template>
  <div @click="router.back()" class="d-flex align-items-center sub-tltle">
    <mdicon size="24" class="arrow" name="chevron-left" /><span
      >Клиенты Гринвуд</span
    >
  </div>
  <div v-if="isLoading" class="mt-20 text-center">
    <img :src="loadimage" alt="loading" />
  </div>
  <div v-else>
    <div class="user-card d-flex">
      <div class="user-card__avatar">
        <img
          :src="currentUser.client.faceUrl"
          :alt="`${currentUser.lastName} ${currentUser.firstName}`"
          v-if="currentUser.client.faceUrl"
          class="user-card__image"
        />
        <div class="user-card-plug" v-else>
          <mdicon size="110" class="user-card-icon" name="account" />
        </div>
      </div>
      <div class="user-card__info user-info d-flex">
        <div class="user-info__name">
          <div class="user-info__name__wrapper d-flex">
            <span class="user-info__name__text"
              >{{ currentUser.lastName }}
              {{ currentUser.firstName }}
              {{ currentUser.patronymic }}</span
            >
            <div
              class="user-info__name__duty-block d-flex"
              v-if="isUserHasDuty"
            >
              <span class="user-info__name__duty-text">есть задолженность</span>
            </div>
          </div>
          <BaseButton
            text="Редактировать"
            color="green"
            @click="setPopupVisibility(true)"
          />
        </div>
        <div class="user-info__fields fields d-flex">
          <div class="fields__column d-flex">
            <div class="fields__item fields__item_top field w-412">
              <span class="field__text_red" v-if="isUserHasDuty"
                >Задолженность: {{ currentUser.ownership.debt }} руб</span
              >
              <span class="field__text_gray" v-else
                >Задолженность: отсутствует
              </span>
            </div>
            <div class="fields__wrapper d-flex">
              <span class="field__text-title">Тел.</span>
              <div class="fields__item field w-350">
                <span class="field__text">{{ userPhone || '-' }}</span>
              </div>
            </div>
            <div class="fields__wrapper d-flex">
              <span class="field__text-title">Адрес</span>
              <div class="fields__item field w-350">
                <span class="field__text">{{
                  currentUser.building.address
                }}</span>
              </div>
            </div>
            <!-- <div class="fields__wrapper d-flex">
              <span class="field__text-title">Почта</span>
              <div class="fields__item field w-350">
                <span class="field__text">{{ currentUser?.email || '-' }}</span>
              </div>
            </div> -->
          </div>
          <div class="fields__column d-flex">
            <div
              class="fields__item fields__item_top field field__comment w-412"
            >
              <span class="field__text_gray">Комментарий: отсутствует</span>
            </div>
            <div class="fields__wrapper d-flex">
              <span class="field__text-title ml-20">Договор </span>
              <div class="fields__item field w-304">
                <span class="field__text">{{
                  currentUser.ownership.account || '-'
                }}</span>
              </div>
            </div>
            <div class="fields__wrapper d-flex">
              <span class="field__text-title ml-20">Роль</span>
              <div class="fields__item field w-304">
                <span class="field__text">{{ userRole }}</span>
              </div>
            </div>
            <div class="fields__wrapper d-flex">
              <span class="field__text-title ml-20">Жилая площадь</span>
              <div class="fields__item field w-100">
                <span class="field__text"
                  >{{ currentUser.apartment.overallSpace || '-' }}м<sup
                    >2</sup
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <UserCardModal
    :isModalVisible="isModalVisible"
    @close="setPopupVisibility(false)"
    :user="currentUser"
    @update="onUserUpdated"
  />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { getUser } from '@/graphql/Client.graphql';
import { useRouter } from 'vue-router';
import { BaseButton } from '@/components/atoms';
import UserCardModal from './UserCardModal.vue';

const router = useRouter();

const route = useRoute();
const isLoading = ref(true);

const currentUser = ref({});

let { refetch } = useQuery(getUser, {
  id: 1,
});

onMounted(async () => {
  const {
    data: { getUser },
  } = await refetch({
    id: +route.params.id,
  });
  currentUser.value = getUser;
  isLoading.value = false;
});

const isModalVisible = ref(false);

const setPopupVisibility = (status) => {
  isModalVisible.value = status;
};

const onUserUpdated = async (user) => {
  currentUser.value = user;
  if (user.phone) {
    currentUser.value.phone = user.unmaskedPhone;
  }
};

const isUserHasDuty = computed(() => {
  return currentUser.value?.ownership?.debt > 0 ? true : false;
});

const userRole = computed(() => {
  return currentUser.value?.role === 'OWNER' ? 'Собственник' : 'Жилец';
});

const userPhone = computed(() => {
  let mask = currentUser.value?.phone?.match(
    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/
  );
  if (mask) {
    return `+${mask?.[1]}(${mask?.[2]})${mask?.[3]}-${mask?.[4]}-${mask?.[5]}`;
  } else {
    return '-';
  }
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.w-412 {
  width: 412px;
}
.w-350 {
  width: 350px;
}
.w-304 {
  width: 304px;
}
.w-219 {
  width: 219px;
}
.ml-20 {
  margin-left: 20px;
}

.fields {
  margin-top: 16px;

  &__item {
    margin-left: auto;
    &_top {
      margin-bottom: 23px;
    }
  }

  &__column {
    flex-direction: column;
    width: 412px;
  }

  &__column:not(:last-child) {
    margin-right: 17px;
  }

  &__wrapper {
    align-items: center;
  }

  &__wrapper:not(:last-child) {
    margin-bottom: 8px;
  }
}

.field {
  min-height: 39px;
  background: var(--white);
  box-shadow: 4px 4px 6px var(--black-shadow);
  border-radius: 3px;
  padding: 9px 0 15px 18px;

  &__text {
    color: var(--black);
    font-size: 13px;
    line-height: 16px;

    &_gray {
      color: map-get($lightgray-text, 'normal');
      font-size: 14px;
      line-height: 17px;
    }

    &_red {
      color: var(--button-error-color);
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
    }

    &-title {
      color: map-get($lightgray-text, 'normal');
      font-size: 13px;
      line-height: 15.6px;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}

.user-card {
  &__avatar {
    min-width: 151px;
    height: 151px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.user-info {
  margin-left: 29px;
  width: 100%;
  flex-direction: column;

  &__name {
    width: 100%;
    height: 46px;
    border-bottom: solid 0.5px rgba(185, 185, 185, 0.83);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      align-items: center;
    }

    &__text {
      color: var(--black);
      font-size: 24px;
      line-height: 28.8px;
      font-weight: 600;
    }

    &__duty {
      &-block {
        width: 157px;
        height: 21px;
        background: var(--button-error-color);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
      }

      &-text {
        color: var(--white);
        font-size: 12px;
        line-height: 14.4px;
        font-weight: 400;
      }
    }
  }
}
.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 30px;
  width: fit-content;
}
.user-card-plug {
  width: 100%;
  height: 100%;
  background: var(--light-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-card-icon {
  color: var(--white);
}
</style>
