<template>
  <div>
    <h1 class="header header_black">Справочник</h1>
    <div class="d-flex justify-content-between mb-4">
      <span @click="router.back()" class="d-flex align-items-center sub-tltle">
        <mdicon size="24" class="arrow" name="chevron-left" /><span
          >Парковка</span
        >
      </span>
    </div>
    <StreetParkingFilter @filter="onStreetParkingFilterUpdates" />
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <div v-else>
      <StreetParkingTable
        :apartments-list="apartmentsList"
        @click="onStreetParkingUpdate"
      />
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        @pageChanged="onChangePage"
        class="pagination"
      ></the-pagination>
    </div>
    <StreetParkingModal
      :apartment="selectedApartment"
      :is-street-parking-modal-visible="isStreetParkingModalVisible"
      @update="onApartmentUpdated"
      @close="setStreetParkingPopupVisibility(false)"
    />
  </div>
</template>
<script setup>
import ThePagination from '@/components/ThePagination.vue';
import { useRouter } from 'vue-router';
import { getApartmentsForUI } from '@/graphql/StreetParking.graphql';
import { useQuery } from '@vue/apollo-composable';
import { watch, ref, onMounted } from 'vue';
import StreetParkingTable from './StreetParkingTable.vue';
import StreetParkingModal from './StreetParkingModal.vue';
import StreetParkingFilter from './StreetParkingFilter.vue';
import { useStore } from 'vuex';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const store = useStore();

const totalPages = ref(0);
const elementsOnPage = ref(5);
const currentPage = ref(1);

const {
  result: allApartmentsForUI,
  fetchMore,
  refetch,
} = useQuery(getApartmentsForUI, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    q: '',
    buildingIds: null,
    complexIds: [1],
  },
});

const isLoading = ref(true);

const apartmentsList = ref(null);

let i = 0;
watch(allApartmentsForUI, (newValue) => {
  if (i === 0) {
    apartmentsList.value = newValue.getApartmentsForUI.apartments;
    getTotalPages(newValue.getApartmentsForUI.filtered);
    i++;
  }
  isLoading.value = false;
});

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      apartmentsList.value = [...fetchMoreResult.getApartmentsForUI.apartments];
      getTotalPages(fetchMoreResult.getApartmentsForUI.filtered);
    },
  });
};

onMounted(() => {
  loadMore();
});

const onStreetParkingFilterUpdates = async (filter) => {
  console.log(filter);
  const {
    data: { getApartmentsForUI },
  } = await refetch({
    filter: {
      q: filter.search,
      buildingIds: filter.buildings?.length ? filter.buildings : null,
      complexIds: [1],
    },
  });
  apartmentsList.value = [...getApartmentsForUI.apartments].reverse();
  getTotalPages(getApartmentsForUI.filtered);
  onChangePage(1);
  if (!apartmentsList.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
};

const selectedApartment = ref(null);

const onStreetParkingUpdate = (apartment) => {
  selectedApartment.value = apartment;
  setStreetParkingPopupVisibility(true);
};

const isStreetParkingModalVisible = ref(false);

const setStreetParkingPopupVisibility = (status) => {
  isStreetParkingModalVisible.value = status;
};

const onChangePage = (page) => {
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

const onApartmentUpdated = () => {
  onChangePage(currentPage.value);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.light-text {
  font-family: 'Golos';
  color: map-get($lightgray-text, 'normal');
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
