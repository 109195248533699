<template>
<base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Сформировать уведомление"
    subtitle=""
    @close="onModalClose"
>

<div class="users">
    <div v-for="(user, idx) in users" :key="idx" class="pdf-item d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <div class="mr-10">
                <img src="@/assets/images/pdf.png" alt="pdf-icon">
            </div>
            <div>
                <span class="user-gray">Пользователь: </span>
                <span class="user-name">{{ user.user }}</span> 
            </div>
        </div>
        <div class="d-flex user-button">
            <div>Просмотреть</div>
            <mdicon name="chevron-right" size="16" /> 
        </div>
    </div>
</div>

<div class="line"></div>
<div class="d-flex justify-content-between">
    <div class="d-flex">
        <base-button
            text="Распечатать"
            color="green"
            class="mr-3"
            :icon="{
                name: 'printer-outline',
                color: 'white'
            }"
            @click.prevent="onModalClose"
        />
        <base-button
            text="Скачать"
            color="gray"
            :icon="{
                name: 'download',
                color: 'grey'
            }"
            class="mr-3"
            @click.prevent="onModalClose"
        />
    </div>

    <base-button
        text="Направить в суд"
        color="red"
        @click.prevent="onFormSubmit"
    />
</div>
</base-popup>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';

const emit = defineEmits(['close']);

defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  users: { type: Array }
});

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emit('close');
  }
};

const onFormSubmit = () => {
    console.log('submit');
}
</script>

<style scoped lang="scss">
.users {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user {
    &-gray {
        font-size: 13px;
        font-weight: 500;
        color: #8A8A8A;
    }

    &-name {
        font-size: 14px;
        font-weight: 600;
        color: initial;
    }

    &-button {
        font-size: 13px;
        font-weight: 500;
        color: #DB4E4E; 
        cursor: pointer;
    }
}

.pdf-item {
    padding: 5px;
    box-shadow: 0px 4px 4px 0px #00000012;
    border-radius: 14px;
}
</style>