<template>
  <base-popup
    size="largest"
    topIndent="80"
    :is-visible="isClaimModalVisible"
    :title="claim ? `№ ${currentClaim?.id} ` : 'Новая заявка'"
    :has-right-side-content="Boolean(claim)"
    :subtitle="
      claim
        ? `Квартира ${currentClaim?.apartment?.number} &#8226;${currentClaim?.apartment?.building?.name} &#8226; Объект ${currentClaim?.apartment?.building?.complex?.name}`
        : null
    "
    @close="onClaimModalClose"
  >
    <template v-slot:right-side-content>
      <div class="claim-modal-delete" @click="onClaimDelete">
        Удалить заявку
      </div>
    </template>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <p class="claim-modal-text" v-if="currentClaim.client.user.lastName">
          Собственник:
          <span
            >{{ currentClaim.client.user.lastName }}
            {{ currentClaim.client.user.firstName }}</span
          >
        </p>
      </div>
      <v-select
        class="claim-modal-select claim-modal-select_status modal-select"
        :options="statusOptions"
        v-model="currentClaim.status"
        placeholder="Статус*"
        :reduce="(value) => value.id"
        label="value"
        :class="{
          disabled: onClaimClosed,
        }"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template>
        <!-- <template v-slot:list-footer>
          <div @click="onClaimModalClose">Footer</div>
        </template> -->
      </v-select>
    </div>
    <div
      class="d-flex justify-content-between select-group mt-40"
      v-if="!claim"
    >
      <v-select
        class="claim-modal-select modal-select"
        :options="complexOptions"
        v-model="complexValue"
        placeholder="Комплекс*"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template></v-select
      >
      <v-select
        class="claim-modal-select modal-select"
        :options="buildingOptions"
        v-model="buildingValue"
        placeholder="Дом*"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div v-if="!complexValue">Сначала выберите комплекс</div>
          <div v-else>Извините, ничего не найдено</div>
        </template>
      </v-select>
      <v-select
        class="claim-modal-select modal-select"
        :options="apartmentsOptions"
        v-model="apartmentsValue"
        placeholder="Квартира*"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div v-if="!buildingValue">Сначала выберите дом</div>
          <div v-else>Извините, ничего не найдено</div>
        </template></v-select
      >
    </div>
    <div class="d-flex justify-content-between select-group mt-20">
      <v-select
        class="claim-modal-select modal-select"
        :options="executorOptions"
        v-model="executorValue"
        placeholder="Исполнитель"
        :reduce="(value) => value.id"
        label="value"
        :multiple="false"
        :class="{
          disabled: onClaimClosed,
        }"
      >
        <template v-slot:no-options>
          <div v-if="!complexValue">Сначала выберите комплекс</div>
          <div v-else>Извините, ничего не найдено</div>
        </template>
      </v-select>
      <v-select
        class="claim-modal-select modal-select"
        :options="categoryOptions"
        v-model="category1Value"
        placeholder="Категория"
        :reduce="(value) => value.id"
        label="value"
        :class="{
          disabled: onClaimClosed,
        }"
      >
        <template v-slot:no-options>
          <div v-if="!executorValue">Сначала выберите исполнителя</div>
          <div v-else>Извините, ничего не найдено</div>
        </template></v-select
      >
      <v-select
        class="claim-modal-select modal-select"
        :options="subCategory1Options"
        v-model="currentClaim.subcategory1"
        placeholder="Подкатегория"
        :multiple="true"
        :reduce="(value) => value.id"
        label="value"
        :class="{
          disabled: onClaimClosed,
        }"
      >
        <template v-slot:no-options>
          <div v-if="!category1Value">Сначала выберите категорию</div>
          <div v-else>Извините, ничего не найдено</div>
        </template></v-select
      >
    </div>
    <div class="d-flex mt-20">
      <div class="d-flex claim-modal-date">
        <div class="d-flex mr-3">
          <span class="claim-modal-label mt-2">Сроки:</span>
        </div>
        <div
          class="d-flex flex-column"
          :class="{
            disabled: onClaimClosed,
          }"
        >
          <Datepicker
            v-model="currentClaim.dateFrom"
            class="claim-modal-date-item mb-3"
            :enableTimePicker="false"
            locale="ru-RU"
            selectText="Выбрать"
            cancelText="Отмена"
            :format="format"
          >
            <template #input-icon>
              <span class="claim-modal-date-placeholder">C</span>
            </template></Datepicker
          >
          <Datepicker
            v-model="currentClaim.dateTo"
            class="claim-modal-date-item"
            :enableTimePicker="false"
            locale="ru-RU"
            selectText="Выбрать"
            cancelText="Отмена"
            :format="format"
          >
            <template #input-icon>
              <span class="claim-modal-date-placeholder">По</span>
            </template></Datepicker
          >
        </div>
      </div>
      <div class="d-flex flex-column w-66 categories">
        <div class="d-flex mb-3" v-if="isCategory2Visible || category2Value">
          <v-select
            class="claim-modal-select modal-select"
            :options="categoryOptions"
            v-model="category2Value"
            placeholder="Категория"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div v-if="!executorValue">Сначала выберите исполнителя</div>
              <div v-else>Извините, ничего не найдено</div>
            </template></v-select
          >
          <v-select
            class="claim-modal-select modal-select"
            :options="subCategory2Options"
            v-model="currentClaim.subcategory2"
            placeholder="Подкатегория"
            :multiple="true"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div v-if="!category2Value">Сначала выберите категорию</div>
              <div v-else>Извините, ничего не найдено</div>
            </template></v-select
          >
        </div>
        <div
          class="d-flex justify-content-end category-add"
          v-else
          :class="{
            disabled: onClaimClosed,
          }"
        >
          <span @click="isCategory2Visible = true"> Добавить категорию </span>
        </div>
        <div class="d-flex" v-if="isCategory3Visible || category3Value">
          <v-select
            class="claim-modal-select modal-select"
            :options="categoryOptions"
            v-model="category3Value"
            placeholder="Категория"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div v-if="!executorValue">Сначала выберите исполнителя</div>
              <div v-else>Извините, ничего не найдено</div>
            </template></v-select
          >
          <v-select
            class="claim-modal-select modal-select"
            :options="subCategory3Options"
            v-model="currentClaim.subcategory3"
            placeholder="Подкатегория"
            :multiple="true"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div v-if="!category3Value">Сначала выберите категорию</div>
              <div v-else>Извините, ничего не найдено</div>
            </template></v-select
          >
        </div>
        <div
          class="d-flex justify-content-end category-add"
          v-else-if="
            (!isCategory3Visible && isCategory2Visible) ||
            currentClaim.category2
          "
        >
          <span @click="isCategory3Visible = true"> Добавить категорию </span>
        </div>
      </div>
    </div>
    <div class="d-flex mt-40 justify-content-between mb-5">
      <div class="w-50 mr-5">
        <div
          class="modal-textarea"
          :class="{
            smooth: onClaimClosed,
          }"
        >
          <base-textarea
            placeholder="Сообщение"
            label="Описание*:"
            :value="descriptionValue"
            @input="onModalInput($event, 'description')"
            maxLength="450"
            :readonly="onClaimClosed"
          ></base-textarea>
        </div>
        <div class="modal-file-input" v-if="!claim">
          <base-file-input
            placeholder="Добавить вложение"
            accept="image/png, image/jpeg"
            :multiple="true"
            @file="onFileChooseDescription"
          >
          </base-file-input>
        </div>
      </div>
      <!-- <div class="w-50">
        <div class="modal-textarea">
          <base-textarea
            placeholder="Сообщение"
            label="Комментарий:"
            :value="commentValue"
            @input="onModalInput($event, 'comment')"
            maxLength="450"
          ></base-textarea>
          <mdicon
            v-if="claim?.comment"
            size="20"
            name="check-all"
            class="double-check-icon"
            :title="
              claim.viewed ? 'Прочитанно клиентом' : 'Не прочитано клиентом'
            "
            :class="{ active: claim.viewed }"
          />
        </div>
      </div> -->
    </div>
    <div v-if="currentClaim.attach?.length" class="mt-3">
      <div class="form-label mb-3">Вложения:</div>
      <div class="attach-images-list">
        <TransitionGroup>
          <div
            class="attach-images-item"
            v-for="(item, idx) in currentClaim.attach"
            :key="idx"
          >
            <a target="_blank" :href="getImageUrl(item.file)">
              <img :src="getImageUrl(item.file)" alt="image" />
            </a>
            <span
              class="attach-images-close"
              v-if="!claim"
              @click="onDeleteImage(item)"
            >
              <mdicon size="20" name="close" class="attach-images-close-icon"
            /></span>
          </div>
        </TransitionGroup>
      </div>
    </div>

    <div class="chat" v-if="props.claim">
      <p class="chat__label chat__label_red" v-if="claim.status === 'OPENED'">
        Для того чтобы оставить комментарий смените статус заявки и сохраните
        изменения
      </p>
      <p class="chat__label">Чат:</p>
      <div
        class="chat__body"
        :class="[
          { chat__body_full: comments?.length },
          { disabled: claim.status === 'OPENED' },
        ]"
        ref="chatBody"
      >
        <div class="chat__day" v-for="day in comments" :key="day.date">
          <span class="chat__date_day chat__date">{{ day.date }} г.</span>
          <div
            class="chat__item"
            :class="message.fromAdmin ? 'chat__item-admin' : 'chat__item-user'"
            v-for="message in day.messages"
            :key="message.createdAt"
          >
            <p class="chat__item-text">
              {{ message.text }}
            </p>
            <div
              class="chat__item-media chat-media"
              v-if="message.files || (message.fromAdmin && message.viewed)"
            >
              <div>
                <div class="chat-media__files" v-if="message.files">
                  <mdicon
                    class="chat-media__icon-file"
                    size="17"
                    name="paperclip"
                  />
                  <div class="chat-media__text">
                    {{ message.files.length }}
                    {{
                      declinationFromNumber(
                        message.files.length,
                        'вложение',
                        'вложения',
                        'вложений'
                      )
                    }}
                  </div>
                  <mdicon
                    class="chat-media__icon-arrow"
                    size="17"
                    name="chevron-down"
                  />
                </div>
                <div
                  class="chat-media__images chat-images"
                  v-if="message.files"
                >
                  <vue-load-image>
                    <template v-slot:image>
                      <a
                        :href="image"
                        target="_blank"
                        class="chat-images__item"
                        v-for="(image, idx) in message.files"
                        :key="idx"
                      >
                        <img :src="image" :alt="`image ${message.requestId}`" />
                      </a>
                    </template>
                    <template v-slot:preloader>
                      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
                    </template>
                  </vue-load-image>
                </div>
              </div>
              <mdicon
                v-if="message.fromAdmin && message.viewed"
                class="chat-media__icon-check"
                size="20"
                name="check-all"
              />
            </div>
            <span class="chat__date_time">{{
              getTime(message.createdAt)
            }}</span>
          </div>
        </div>
        <div
          class="chat__form"
          :class="{
            disabled: onClaimClosed,
          }"
        >
          <div class="chat__form__media-input-wrapper">
            <base-file-input
              accept="image/png, image/jpeg"
              :multiple="true"
              class="chat__form__media-input"
              @file="onChatFileChooseDescription"
            >
            </base-file-input>
            <!-- <input type="file" class="chat__form__media-input" /> -->
            <mdicon class="chat__form__icon-file" size="30" name="paperclip" />
          </div>
          <input
            type="text"
            class="chat__form__input"
            :placeholder="
              comments?.length ? 'Написать сообщение' : 'Напишите комментарий'
            "
            v-model.trim="chatMessage"
            @keypress.enter="sendChatMessage"
          />
          <button
            class="chat__form__submit-button"
            @click="sendChatMessage"
            :disabled="isSendMessageButtonDisabled"
          >
            <mdicon
              class="chat__form__icon-arrow"
              size="35"
              name="chevron-right"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onClaimModalClose"
      />

      <base-button
        :text="claim ? 'Сохранить' : 'Создать'"
        color="green"
        @click.prevent="onFormSubmit"
        :disabled="
          isFormDataInvalid ||
          claim?.status === 'CLOSED' ||
          claim?.status === 'REJECTED'
        "
      />
    </div>
  </base-popup>
  <the-confirm
    :is-visible="isDeleteConfirmVisible"
    @close="onToggleDeleteConfirm"
    @confirm="isDeleteConfirmed"
  />
</template>
<script setup>
import { defineProps, ref, defineEmits, computed, watch } from 'vue';
import VueLoadImage from 'vue-load-image';
import TheConfirm from '@/components/TheConfirm.vue';
import {
  BasePopup,
  BaseTextarea, //TODO
  BaseFileInput, //TODO
  BaseButton,
} from '@/components/atoms';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import {
  getAllComplexes,
  getBuildings,
  getApartments,
  getAllExecutors,
  getExecutor,
  getAllSubCategories,
  createRequest,
  updateRequest,
  getStatuses,
  getAllComments,
  deleteRequest,
  addRequestComment,
} from '@/graphql/Claims.graphql';
import { adminInfo } from '@/graphql/AdminInfo.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';

const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');

const linkGreen = ['lk.greenwood.cirus-it.com', 'greenwood.cirus-it.com', 'admin.greenwood.cirus-it.com'];
const linkDomion = ['domion.tech', 'lk.domion.tech', 'admin.domion.tech'];

const isDomionHost = linkDomion.includes(window.location.host);
const isGreenHost = linkGreen.includes(window.location.host);

let CLAIM_MEDIA_URL;
let COMMENTS_URL;

if (THEME === 'domion') {
  CLAIM_MEDIA_URL = isDomionHost
    ? `${process.env.VUE_APP_API_PROD}/request/attach`
    : `${process.env.VUE_APP_API_DEV}/request/attach`;

  COMMENTS_URL = isDomionHost
    ? `${process.env.VUE_APP_API_PROD}/request/attach`
    : `${process.env.VUE_APP_API_DEV}/request/attach`;
} else {
  CLAIM_MEDIA_URL = isGreenHost
    ? `${process.env.VUE_APP_API_PROD}/request/attach`
    : `${process.env.VUE_APP_API_DEV}/request/attach`;

  COMMENTS_URL = isGreenHost
    ? `${process.env.VUE_APP_API_PROD}/request/attach`
    : `${process.env.VUE_APP_API_DEV}/request/attach`;
}

// const CLAIM_MEDIA_URL =
//   window.location.host === 'domion.develop.k8s.cirus-it.com'
//     ? `${process.env.VUE_APP_API_PROD}/request/attach`
//     : `${process.env.VUE_APP_API_DEV}/request/attach`;
// const COMMENTS_URL =
//   window.location.host === 'domion.develop.k8s.cirus-it.com'
//     ? `${process.env.VUE_APP_API_PROD}/comment/attach`
//     : `${process.env.VUE_APP_API_DEV}/comment/attach`;

const store = useStore();

const props = defineProps({
  isClaimModalVisible: {
    type: Boolean,
    default: false,
  },
  claim: {},
});

const claimId = ref(1);
const userId = ref(1);

const { result: getAdminInfo } = useQuery(adminInfo);

watch(getAdminInfo, (newValue) => {
  userId.value = newValue.adminInfo.id;
});

// Инициализация логики для имполнителя
const executorValue = ref('');

watch(executorValue, async (newValue, oldValue) => {
  const clearCategory = () => {
    category1Value.value = null;
    category2Value.value = null;
    category3Value.value = null;
    categoryOptions.value = [];
  };

  if (newValue) {
    if (newValue !== oldValue && oldValue && newValue !== null) {
      clearCategory();
    }
    console.log('currentClaim', currentClaim.value);
    currentClaim.value.executor = { id: newValue };
    const {
      data: { getExecutor },
    } = await fetchMoreExecutor({
      id: newValue,
    });
    console.log('getExecutor', getExecutor);
    categoryOptions.value = getExecutor.categories.map(
      ({ category: { id, title } }) => {
        return {
          id,
          value: title,
        };
      }
    );
    currentClaim.value.executor.name = getExecutor.name;
  } else {
    clearCategory();
    if (!currentClaim.value.executor) {
      currentClaim.value.executor = {};
    }
    currentClaim.value.executor.id = null;
    currentClaim.value.executor.name = null;
  }
  // }

  console.log('executor value', newValue);
  console.log('categoryOptions', categoryOptions.value);
  console.log('currentClaim', currentClaim.value);
});

const { result: allStatuses } = useQuery(getStatuses);

const statuses = ref(null);

watch(allStatuses, (newValue) => {
  statuses.value = newValue.getStatuses;
  console.log('statuses', newValue.getStatuses);
  statusOptions.value = newValue.getStatuses.map(({ name, title }) => {
    return {
      id: name,
      value: title,
    };
  });
});
/////////////////////////////

const descriptionValue = computed(() => {
  //TODO
  return currentClaim.value.description
    ? currentClaim.value.description.trim()
    : currentClaim.value.description;
});
// const commentValue = computed(() => { //TODO
//   return currentClaim.value.comment
//     ? currentClaim.value.comment.trim()
//     : currentClaim.value.comment;
// });

// Проверка на изначальные данные
watch(
  () => props.claim,
  async (data) => {
    console.log('claim', data);
    if (data) {
      claimId.value = data.id;
      data.executor
        ? (executorValue.value = data.executor.id)
        : (executorValue.value = undefined);
      category1Value.value = data.category1;
      category2Value.value = data.category2;
      category3Value.value = data.category3;
      currentClaim.value = JSON.parse(JSON.stringify(data));
      (complexValue.value = data.apartment.building.complex.id),
        console.log('CurrentClaim', currentClaim.value);
      if (
        currentClaim.value.status === 'PROCESSING' ||
        currentClaim.value.status === 'IN_PROGRESS'
      ) {
        statusOptions.value = statusOptions.value.filter(
          (item) => item.id !== 'OPENED'
        );
        console.log('watch', statusOptions.value);
      }
    } else {
      currentClaim.value = ref({ ...emptyClaim }).value;
    }
  }
);
//////////

const emit = defineEmits(['close', 'create', 'update', 'delete']);

const statusOptions = ref([]);

const complexOptions = ref([]);

const buildingOptions = ref([]);

const apartmentsOptions = ref([]);

const executorOptions = ref([]);

const categoryOptions = ref([]);

const subCategory1Options = ref([]);

const subCategory2Options = ref([]);

const subCategory3Options = ref([]);
////////////////////////////////

//Подключение к GraphQl

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (value) => {
  complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const { result: allBuildings, refetch: fetchMoreBuildings } = useQuery(
  getBuildings,
  {
    complexId: 1,
  }
);
console.log(allBuildings);

const { result: allApartments, refetch: fetchMoreApartments } = useQuery(
  getApartments,
  {
    buildingId: 1,
  }
);
console.log(allApartments);

const { result: allExecutors, refetch: fetchMoreExecutors } = useQuery(
  getAllExecutors,
  {
    complexId: 0,
    pagination: null,
    filter: null,
  }
);
console.log(allExecutors);

const { result: getOneExecutor, refetch: fetchMoreExecutor } = useQuery(
  getExecutor,
  {
    id: 1,
  }
);
console.log(getOneExecutor);

const { result: allSubCategories, refetch: fetchMoreSubCategories } = useQuery(
  getAllSubCategories,
  {
    id: 1,
  }
);

console.log(allSubCategories);
console.log(fetchMoreSubCategories);

// claim object
const emptyClaim = {
  id: null,
  status: null,
  description: '',
  dateFrom: new Date(),
  dateTo: null,
  executor: {
    name: null,
    id: null,
    categories: [],
  },
  apartment: {
    id: null,
    number: null,
    building: {
      id: null,
      number: null,
      complex: {
        id: null,
        name: null,
      },
    },
  },
  client: {
    id: null,
    user: {
      lastName: null,
      firstName: null,
    },
  },
  category1: null,
  subcategory1: null,
  category2: null,
  subcategory2: null,
  category3: null,
  subcategory3: null,
  comment: '',
  attach: [],
};

const currentClaim = ref({
  id: null,
  status: null,
  description: '',
  dateFrom: new Date(),
  dateTo: null,
  executor: {
    name: null,
    id: null,
    categories: [],
  },
  apartment: {
    id: null,
    number: null,
    building: {
      id: null,
      number: null,
      complex: {
        id: null,
        name: null,
      },
    },
  },
  client: {
    id: null,
    user: {
      lastName: null,
      firstName: null,
    },
  },
  categories: [],
  category1: null,
  subcategory1: null,
  category2: null,
  subcategory2: null,
  category3: null,
  subcategory3: null,
  comment: '',
  attach: [],
});
////////////////////////

// Валидация доступных селектов

const complexValue = ref(null);
const buildingValue = ref(null);
const apartmentsValue = ref(null);
const category1Value = ref(null);
const category2Value = ref(null);
const category3Value = ref(null);

watch(complexValue, async (newValue) => {
  currentClaim.value.apartment.building.complex.id = newValue;
  if (newValue) {
    const {
      data: { getBuildings },
    } = await fetchMoreBuildings({
      complexId: newValue,
    });
    buildingOptions.value = getBuildings.map(({ id, name }) => {
      return {
        id,
        value: name,
      };
    });

    const {
      data: { getAllExecutors },
    } = await fetchMoreExecutors({
      complexId: newValue,
    });
    executorOptions.value = getAllExecutors.executors.map(({ id, name }) => {
      return {
        id,
        value: name,
      };
    });
  } else {
    buildingOptions.value = [];
    buildingValue.value = null;
    executorOptions.value = [];
    executorValue.value = null;
  }
});

watch(buildingValue, async (newValue) => {
  currentClaim.value.apartment.building.id = newValue;
  if (newValue) {
    const {
      data: { getApartments },
    } = await fetchMoreApartments({
      buildingId: newValue,
    });
    apartmentsOptions.value = getApartments.map(({ id, number }) => {
      return {
        id,
        value: number,
      };
    });
  } else {
    apartmentsValue.value = null;
    apartmentsOptions.value = [];
  }
});

watch(apartmentsValue, (newValue) => {
  currentClaim.value.apartment.id = newValue;
});

//TODO: Создать функцию для всех категорий - подкатегорий

// const categories = reactive({});

// for (let i = 0; i < 3; i++) {
//   // categories[`category_${i}`] = i;
//   watch(categories[`category_${i}`], async (newValue) => {
//     currentClaim.value.category2 = newValue;

//     if (newValue) {
//       const {
//         data: { getAllSubCategories },
//       } = await fetchMoreSubCategories({
//         id: newValue,
//       });
//       console.log('getAllSubCategories', getAllSubCategories);
//       subCategory2Options.value = getAllSubCategories.map(({ id, title }) => {
//         return {
//           id,
//           value: title,
//         };
//       });
//     } else {
//       subCategory2Options.value = [];
//       currentClaim.value.subcategory2 = [];
//     }
//   });
// }

watch(category1Value, async (newValue, oldValue) => {
  currentClaim.value.category1 = newValue;

  if (newValue) {
    if (newValue !== oldValue && oldValue && newValue !== null) {
      subCategory1Options.value = [];
      currentClaim.value.subcategory1 = [];
    }
    const {
      data: { getAllSubCategories },
    } = await fetchMoreSubCategories({
      id: newValue,
    });
    console.log('getAllSubCategories', getAllSubCategories);
    subCategory1Options.value = getAllSubCategories.map(({ id, title }) => {
      return {
        id,
        value: title,
      };
    });
  } else {
    subCategory1Options.value = [];
    currentClaim.value.subcategory1 = [];
  }
});

watch(category2Value, async (newValue, oldValue) => {
  currentClaim.value.category2 = newValue;

  if (newValue) {
    if (newValue !== oldValue && oldValue && newValue !== null) {
      subCategory2Options.value = [];
      currentClaim.value.subcategory2 = [];
    }
    const {
      data: { getAllSubCategories },
    } = await fetchMoreSubCategories({
      id: newValue,
    });
    console.log('getAllSubCategories', getAllSubCategories);
    subCategory2Options.value = getAllSubCategories.map(({ id, title }) => {
      return {
        id,
        value: title,
      };
    });
  } else {
    subCategory2Options.value = [];
    currentClaim.value.subcategory2 = [];
  }
});

watch(category3Value, async (newValue, oldValue) => {
  currentClaim.value.category3 = newValue;

  if (newValue) {
    if (newValue !== oldValue && oldValue && newValue !== null) {
      subCategory3Options.value = [];
      currentClaim.value.subcategory3 = [];
    }
    const {
      data: { getAllSubCategories },
    } = await fetchMoreSubCategories({
      id: newValue,
    });
    console.log('getAllSubCategories', getAllSubCategories);
    subCategory3Options.value = getAllSubCategories.map(({ id, title }) => {
      return {
        id,
        value: title,
      };
    });
  } else {
    subCategory3Options.value = [];
    currentClaim.value.subcategory3 = [];
  }
});

///////////////////////////////////

// Date format

const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
/////////////

// Validation

const rules = computed(() => {
  return {
    status: { required },
    // complex: { required },
    // building: { required },
    apartment: {
      id: { required },
    },
    description: { required },
  };
});

const v$ = useVuelidate(rules, currentClaim);
console.log(v$);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});
console.log(isFormDataInvalid);
//////////////

// Add new category
const isCategory2Visible = ref(false);
const isCategory3Visible = ref(false);

//Close Modal
const onClaimModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    currentClaim.value = ref({ ...emptyClaim }).value;
    currentClaim.value.dateFrom = new Date();
    isCategory2Visible.value = false;
    isCategory3Visible.value = false;
    complexValue.value = null;
    executorValue.value = '';
    filesValue.value = [];
    filesValueFile.value = [];
    comments.value = [];
    claimId.value = 1;
    chatMessage.value = '';
    emit('close');
  }
};
//////////////

// const chatImageLoaded = (img) => {
//   const images = document.querySelectorAll('.chat-image');
//   for (const item of images) {
//     item.src = img;
//   }
// };

//On Modal input
const onModalInput = (event, type) => {
  //TODO
  currentClaim.value[type] = event.target.value;
};
//////////////

// Мутации
const { mutate: createClaimMutation } = useMutation(createRequest);
const { mutate: updateClaimMutation } = useMutation(updateRequest);
const { mutate: deleteClaimMutation } = useMutation(deleteRequest);
const { mutate: addRequestCommentMutation } = useMutation(addRequestComment);

// File input
const filesValue = ref([]);
const filesValueFile = ref([]);

const formatNumbers = (number) => {
  return ('0' + number).slice(-2);
};

const getTime = (initialDate) => {
  const date = new Date(initialDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${formatNumbers(hours)}:${formatNumbers(minutes)}`;
};

const chatBody = ref(null);

const comments = ref([]);

const initCommentsArray = async (id) => {
  const {
    data: { getAllComments },
  } = await fetchMoreComments({
    requestId: id,
    pagination: {
      page: 0,
      size: 100,
    },
  });
  console.log('getAllComments', getAllComments);

  const checkDate = (chatDate) => {
    const date = new Date(chatDate);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return `${formatNumbers(day)} ${getMountName(month)} ${year}`;
  };

  comments.value = getAllComments.comments.reduce((acc, curr) => {
    const index = acc.findIndex(
      (item) => item.date === checkDate(curr.createdAt)
    );
    if (index === -1) {
      acc.push({ date: checkDate(curr.createdAt), messages: [curr] });
    } else {
      acc[index].messages.push(curr);
    }
    const newAcc = acc.map((item) => {
      const parsedMsg = item.messages.map((msgitem) => {
        return msgitem.files
          ? {
              ...msgitem,
              files:
                typeof msgitem.files === 'string'
                  ? JSON.parse(msgitem.files)
                  : msgitem.files,
            }
          : msgitem;
      });
      return { ...item, messages: parsedMsg };
    });
    return newAcc;
  }, []);
  comments.value = comments.value.reverse();
  comments.value.forEach((item) => (item.messages = item.messages.reverse()));
  console.log('comments', comments.value);
};

watch(claimId, async (newValue) => {
  initCommentsArray(newValue);
  setTimeout(() => {
    initCommentsArray(newValue);
    setTimeout(() => {
      scrollToChatBottom();
    }, 200);
  }, 500);
});

const { refetch: fetchMoreComments } = useQuery(getAllComments, {
  requestId: claimId.value,
  pagination: {
    page: 0,
    size: 100,
  },
});

// watch(allComments, (newValue) => {
//   const checkDate = (chatDate) => {
//     const date = new Date(chatDate);
//     const month = date.getMonth() + 1;
//     const day = date.getDate();
//     const year = date.getFullYear();
//     return `${day}${getMountName(month)}${year}`;
//   };

//   comments.value = newValue.getAllComments.map((item) => {
//     comments.value.forEach((commentsItem) => {
//       if (
//         commentsItem?.date &&
//         commentsItem.date === checkDate(item.createdAt)
//       ) {
//         commentsItem.messages.push(item);
//       } else {
//         comments.value.push({
//           date: checkDate(item.createdAt),
//           messages: [item],
//         });
//       }
//     });
//   });
//   console.log('comments', comments.value);
// });

watch(
  () => props.isClaimModalVisible,
  (newValue) => {
    if (
      newValue &&
      currentClaim.value.status !== 'PROCESSING' &&
      currentClaim.value.status !== 'IN_PROGRESS'
    ) {
      statusOptions.value = statuses.value.map(({ name, title }) => {
        return {
          id: name,
          value: title,
        };
      });
    }
    console.log('watch1', statusOptions.value);
  }
);

const scrollToChatBottom = () => {
  if (props.isClaimModalVisible) {
    setTimeout(() => {
      chatBody.value.scrollTop = chatBody.value.scrollHeight;
    }, 300);
  }
};

const getMountName = (value) => {
  const mountName = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  return mountName[value];
};

const getImageUrl = (file) => {
  //TODO
  if (props.claim) {
    return file;
  } else {
    return URL.createObjectURL(file);
  }
};

const chatMessage = ref('');
const isSendMessageButtonDisabled = ref(false);
const sendChatMessage = async () => {
  if (chatMessage.value && !isSendMessageButtonDisabled.value) {
    isSendMessageButtonDisabled.value = true;
    const { data } = await addRequestCommentMutation({
      requestId: claimId.value,
      message: chatMessage.value,
    });
    chatMessage.value = '';
    console.log('commentMessage', data);

    const {
      data: { getAllComments },
    } = await fetchMoreComments({
      pagination: {
        page: 0,
        size: 100,
      },
    });

    initCommentsArray(claimId.value);

    scrollToChatBottom();
    isSendMessageButtonDisabled.value = false;
    console.log(getAllComments);
  }
};

const chatFiles = ref([]);

const onChatFileChooseDescription = (files) => {
  chatFiles.value = Object.values(files).map((item) => {
    return {
      file: item,
    };
  });
  // chatFiles.value = filesListFile;
  onLoadChatFiles(claimId.value, userId.value);
};

const isCanUpdateChat = ref(false);

const onLoadChatFiles = (requestId, currentUserId) => {
  const files = chatFiles.value.map(({ file }) => {
    console.log('file', file);
    return file;
  });

  console.log('files', files);
  if (files.length) {
    const form = new FormData();
    form.append('requestId', requestId);
    form.append('userId', currentUserId);
    for (const file of files) {
      form.append('files', file);
    }

    const request = new XMLHttpRequest();

    // request.upload.addEventListener('progress', (e) => {
    //   let filesSize = 0;
    //   for (const file of files) {
    //     filesSize = file.size;
    //     console.log('filesSize', filesSize);
    //   }
    //   // if (e.loaded <= filesSize) {

    //   // }
    //   if (e.loaded == e.total) {
    //     isCanUpdateChat.value = true;
    //     console.log('CHAT UPDATED');
    //   }
    // });

    request.upload.addEventListener('loadend', (e) => {
      console.log('LOADED', e);
      setTimeout(() => {
        initCommentsArray(claimId.value);
        scrollToChatBottom();
      }, 200);
    });
    request.upload.addEventListener('error', (e) => {
      console.log('LoadImageError', e);
    });
    request.upload.addEventListener('abort', (e) => {
      console.log('LoadImageAbort', e);
    });

    request.open('post', COMMENTS_URL);
    request.timeout = 45000;
    request.send(form);

    isCanUpdateChat.value = false;
    chatFiles.value = [];
  }
};

// watch(isCanUpdateChat, (newValue) => {
//   if (newValue) {
//     console.log('upd');
//     initCommentsArray(claimId.value);
//     setTimeout(() => {
//       scrollToChatBottom();
//     }, 200);
//   }
// });

const onFileChooseDescription = (files) => {
  //TODO
  console.log(files);
  // currentClaim.value.attach.push({ file: URL.createObjectURL(files) });
  const filesList = Object.values(files).map((item) => {
    return {
      file: URL.createObjectURL(item),
    };
  });
  const filesListFile = Object.values(files).map((item) => {
    return {
      file: item,
    };
  });
  // currentClaim.value.attach = [...currentClaim.value.attach, ...filesList];
  currentClaim.value.attach = [...currentClaim.value.attach, ...filesListFile];
  console.log('currentClaim.value.attach', currentClaim.value.attach);

  filesValue.value = [...filesValue.value, ...filesList];
  filesValueFile.value = [...filesValueFile.value, ...filesListFile];
  console.log('filesValueFile.value', filesValueFile.value);
};

/////////////////////////

const onDeleteImage = async (item) => {
  //TODO
  const index = currentClaim.value.attach.indexOf(item);
  if (index !== -1) {
    currentClaim.value.attach.splice(index, 1);
  }
  console.log('filesValueFile.value before', filesValueFile.value);
  const indexFile = filesValueFile.value.indexOf(item);
  if (indexFile !== -1) {
    filesValueFile.value.splice(indexFile, 1);
  }
  console.log('filesValueFile.value after', filesValueFile.value);
  document.getElementById('upload-input');
};

const onLoadFiles = (requestId) => {
  console.log('filesValueFile in onLoadFiles', filesValueFile.value);
  for (let i = 0; i < filesValueFile.value.length; i++) {
    const { file } = filesValueFile.value[i];
    const form = new FormData();
    form.append('requestId', requestId);
    form.append('files', file);
    console.log('FILE', file);

    fetch(CLAIM_MEDIA_URL, {
      method: 'POST',
      header: {
        'Content-Type': 'multipart/form-data',
      },
      body: form,
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }
};

const isFormCanBeSubmited = ref(true);

const onClaimCreate = async () => {
  const {
    status,
    description,
    dateFrom,
    dateTo,
    executor: { id: executorId },
    apartment: { id: apartmentId },
    comment,
    category1,
    subcategory1,
    category2,
    subcategory2,
    category3,
    subcategory3,
  } = currentClaim.value;
  console.log('submited', currentClaim.value);

  if (comment && status === 'OPENED') {
    store.dispatch('notification/showNotification', {
      text: `Для того чтобы оставить комментарий смените статус заявки`,
      type: 'error',
    });
    isFormCanBeSubmited.value = false;
  } else {
    const { data } = await createClaimMutation({
      data: {
        status,
        description,
        dateFrom,
        dateTo,
        executor: {
          id: executorId,
        },
        apartment: {
          id: apartmentId,
        },
        comment,
        category1,
        subcategory1,
        category2,
        subcategory2,
        category3,
        subcategory3,
      },
    });

    console.log('data', { data });
    onLoadFiles(data.createRequest.id);
    // const attachList = [{ file: URL.createObjectURL(filesValue.value) }];

    // data.createRequest.attach = filesValueFile.value;
    data.createRequest.attach = filesValueFile.value.map((item) => {
      return {
        file: URL.createObjectURL(item.file),
      };
    });

    // data.createRequest.attach = filesValue.value;
    console.log('data.createRequest.attach', data.createRequest.attach);
    isFormCanBeSubmited.value = true;
    emit('create', data.createRequest);
  }
};

console.log(updateRequest);
const onClaimUpdate = async () => {
  const {
    id,
    status,
    description,
    dateFrom,
    dateTo,
    executor: { id: executorId },
    apartment: { id: apartmentId },
    comment,
    category1,
    subcategory1,
    category2,
    subcategory2,
    category3,
    subcategory3,
  } = currentClaim.value;
  console.log('submited', currentClaim.value);

  if (comment && status === 'OPENED') {
    store.dispatch('notification/showNotification', {
      text: `Для того чтобы оставить комментарий смените статус заявки`,
      type: 'error',
    });
    isFormCanBeSubmited.value = false;
  } else {
    await updateClaimMutation({
      data: {
        id,
        status,
        description,
        dateFrom,
        dateTo,
        executor: {
          id: executorId,
        },
        apartment: {
          id: apartmentId,
        },
        comment,
        category1,
        subcategory1,
        category2,
        subcategory2,
        category3,
        subcategory3,
      },
    });

    emit('update', currentClaim.value);
    isFormCanBeSubmited.value = true;
  }
};

const isDeleteConfirmVisible = ref(false);
const isClaimShouldBeDeleted = ref(false);

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isClaimShouldBeDeleted.value = true;
};

const onClaimDelete = async () => {
  isDeleteConfirmVisible.value = true;
  watch(isClaimShouldBeDeleted, async (newValue) => {
    if (newValue) {
      const { id } = currentClaim.value;
      await deleteClaimMutation({
        id,
      });
      store.dispatch('notification/showNotification', {
        text: `Заявка успешно удалена`,
        type: 'success',
      });
      onClaimModalClose(true);
      isClaimShouldBeDeleted.value = false;
      emit('delete', id);
    }
  });
};

const onFormSubmit = async () => {
  if (isFormDataInvalid.value) return;

  const status = currentClaim.value.status;

  let hasMessagesFromAdmin = false;

  for (const item of comments.value) {
    hasMessagesFromAdmin = Boolean(
      item.messages.find((item) => item.fromAdmin && item.type !== 'GREETING')
    );
    if (hasMessagesFromAdmin) break;
  }

  const isCommentsEmptyAndClaimClosed =
    ['CLOSED', 'REJECTED'].includes(status) && !hasMessagesFromAdmin;

  if (isCommentsEmptyAndClaimClosed) {
    store.dispatch('notification/showNotification', {
      text: `Заявка не может быть ${
        status === 'CLOSED' ? 'закрыта' : 'отклонена'
      } без комментария менеджера`,
      type: 'error',
    });
    return;
  }

  if (props.claim) {
    await onClaimUpdate();
  } else {
    await onClaimCreate();
  }
  if (isFormCanBeSubmited.value) {
    store.dispatch('notification/showNotification', {
      text: `Заявка успешно ${props.claim ? 'сохранена' : 'создана'}`,
      type: 'success',
    });
    onClaimModalClose(true);
  }
};
const declinationFromNumber = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

const onClaimClosed = computed(() => {
  return props.claim?.status === 'CLOSED' || props.claim?.status === 'REJECTED';
});
</script>

<style>
.modal-select {
  font-family: 'Golos' !important;
  font-size: 16px !important;
  width: 100%;
  color: var(--grey-text-color);
  --vs-border-radius: 10px;
  --vs-actions-padding: 4px 25px 0px 3px;
  --vs-border-color: var(--grey-border-color);
  --vs-dropdown-option--active-bg: var(--primary-color);
  --vs-font-size: 16px;
}
.dp__pointer {
  font-family: 'Golos' !important;
}
</style>
<style lang="scss">
.dp__input {
  border-radius: 10px;
}
</style>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.claim-modal-select_status {
  width: 230px;
}
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.smooth {
  opacity: 0.6;
}
.select-group .claim-modal-select:not(:last-child),
.categories .claim-modal-select:not(:last-child) {
  margin-right: 20px;
}
.claim-modal-date {
  width: 300px;
  margin-right: 20px;
}
.claim-modal-date-item {
  font-family: 'Golos' !important;
  width: 100%;
}
.claim-modal-date-placeholder {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
}
.claim-modal-text {
  font-family: 'Golos';
  font-size: 14px;
  font-weight: 500;
  color: map-get($lightgray-text, 'normal');
}
.claim-modal-text span {
  color: black;
  font-size: 16px;
}
.claim-modal-label {
  color: map-get($lightgray-text, 'normal');
}
.w-66 {
  width: 66%;
}

.claim-modal-delete {
  color: map-get($red, 'normal');
  cursor: pointer;
}
.category-add {
  color: map-get($green, 'normal');
  transition: all 0.3s ease 0s;

  span {
    cursor: pointer;
    width: fit-content;
  }
}
.category-add:hover {
  color: map-get($green, 'hover');
}
.modal-textarea {
  // width: 48%;
  height: 133px;
  position: relative;
}
.double-check-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: map-get($lightgray-text, 'normal');
}
.double-check-icon.active {
  color: map-get($green, 'hover');
}
.modal-file-input {
  margin: 20px 0 0 0;
}
.form-label {
  color: map-get($lightgray-text, 'normal');
  font-family: 'Golos', sans-serif;
}
.attach-images-list {
  display: flex;
  flex-wrap: wrap;
}
.attach-images-item {
  width: 200px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  &:hover .attach-images-close {
    opacity: 1;
    visibility: visible;
  }
}
.attach-images-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--white);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;

  &-icon {
    color: map-get($red, 'dark');
  }
}
.attach-images-item {
  margin-bottom: 10px;
}
.attach-images-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
// .modal-textarea:not(:last-child) {
//   margin-right: 20px;
// }
</style>
