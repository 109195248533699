<template>
  <base-popup
    class="modal"
    size="large"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    @close="onModalClose"
  >
    <div class="modal__body">
      <div class="modal__body-item">
        <p class="modal__body-title">Тема</p>
        <p class="modal__body-text">
          {{ news?.title }}
        </p>
      </div>
      <div class="modal__body-item">
        <p class="modal__body-title">Содержание</p>
        <p class="modal__body-text">
          {{ news?.text }}
        </p>
      </div>
    </div>
  </base-popup>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import { BasePopup } from '@/components/atoms';
defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  news: {
    type: Object,
  },
});

const emit = defineEmits(['close']);

const onModalClose = () => {
  emit('close');
};
</script>
<style scoped>
.modal__body-title {
  color: #232326;
  margin-bottom: 10px;
}
.modal__body-text {
  color: var(--grey-text-color);
  line-height: 1.25;
}
.modal__body-item:not(:last-child) {
  margin-bottom: 30px;
}
.modal__body {
  padding-bottom: 20px;
}
</style>
