<template>
  <div class="base-textarea">
    <textarea
      :class="disabled ? 'base-textarea__field base-textarea__field--disabled' : 'base-textarea__field'"
      :placeholder="placeholder"
      :value="inputValue"
      @input="onInput"
      :maxlength="maxLength"
      :disabled="disabled"
    ></textarea>
    <label class="base-textarea__label">{{ label }}</label>
  </div>
</template>
<script setup>
  import { defineProps, watch, ref, defineEmits } from 'vue';

  const props = defineProps({
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    maxLength: {
      type: String,
    },
    disabled: { type: Boolean }
  });

  const emit = defineEmits(['input']);

  const inputValue = ref(props.value);

  watch(
    () => props.value,
    (newValue) => {
      inputValue.value = newValue;
    }
  );

  const onInput = (event) => {
    emit('input', event);
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/styles/_colors';
  .base-textarea {
    position: relative;
    padding-top: 25px;
    height: 100%;

    &__field {
      padding: 10px;
      font-family: 'Golos', sans-serif;
      color: map-get($black-text, 'normal');
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid var(--grey-border-color);
      resize: none;
      width: 100%;
      height: 100%;
      font-weight: 500;
      transition: all 0.3s ease 0s;
      font-size: 16px;

      &:focus {
        border: 1px solid map-get($green, 'normal');
      }

      &--disabled {
        background-color: #D9D9D91C;
        border: none;
        user-select: none;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      color: map-get($lightgray-text, 'normal');
      font-family: 'Golos', sans-serif;
    }
  }
</style>