<template>
  <ul class="list">
    <li
      v-for="(item, idx) in list"
      :key="idx"
      class="list__item"
      @click="onClick(item)"
    >
      <router-link class="list__text" v-if="item.link" :to="item.link">{{
        item.title
      }}</router-link>
      <p class="list__text" v-else>{{ item.title }}</p>
    </li>
  </ul>
</template>
<script setup>
  import { defineProps, defineEmits } from 'vue';

  const emit = defineEmits(['click']);

  defineProps({
    list: {
      type: Array,
      required: true,
    },
  });

  const onClick = (item) => {
    emit('click', item);
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/styles/_colors';

  .list {
    &__text {
      text-decoration: none;
      font-family: 'Golos';
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: map-get($gray-text, 'normal');
      width: 100%;
      display: block;
      padding: 13px 18px;
    }

    &__item {
      position: relative;
      cursor: pointer;
      border-radius: 10px;
      transition: all 0.3s ease 0s;
    }
    &__item:nth-child(odd) {
      background: map-get($gray, 'normal');
    }
    &__item:nth-child(odd):hover {
      background: map-get($gray, 'hover');
    }
  }
</style>