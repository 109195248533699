<template>
  <header>
    <nav class="main-nav">
      <div class="main-nav__container">
        <div v-if="role !== 'CLIENT'" class="main-nav__updates updates" @click="setPopupVisibility(true)">
          <mdicon size="23" name="message-text" class="updates__icon" />
          &nbsp;Новых уведомлений
          <span :class="{ green: newNotifications }">&thinsp; {{ newNotifications || 'нет' }}</span>
        </div>
        <div v-else />

        <div class="main-nav__item" v-click-outside="disablePopover">
          <span class="main-nav__item-text main-nav__item-text_user">Здравствуйте,
            {{ role === 'CLIENT' ? client?.firstName : admin.firstName }}</span>
          <mdicon class="main-nav__item-icon main-nav__item-icon_user" size="23" name="account" @click="togglePopover" />
          <user-popover v-if="isPopoverVisible"></user-popover>
        </div>
      </div>
    </nav>
  </header>
  <GlobalNotificationsModal v-if="isModalVisible" :isModalVisible="isModalVisible" @close="onModalClose()" />
</template>

<script setup>
import UserPopover from './UserPopover.vue';
import GlobalNotificationsModal from './GlobalNotificationsModal.vue';
import { ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { adminInfo } from '@/graphql/AdminInfo.graphql';
import { myClientInfo } from '@/graphql/Login.graphql';
import { getAllEvents } from '@/graphql/Events.graphql';
import { useStore } from 'vuex';

const store = useStore();
const role = store.getters['app/userRole'];
const user = store.getters['app/getUser'];

const admin = ref('');
const client = ref(JSON.parse(user));

let { result: adminInfoResult } = useQuery(adminInfo);

if (role === 'CLIENT' && user === '{}') {
  console.log('work user');
  const token = store.getters['app/userToken'];
  const { result: getClientInfo } = useQuery(myClientInfo);

  watch(getClientInfo, async (newValue) => {
    console.log(newValue, 'CLIENT INFO');
    client.value = newValue.myClientInfo;

    await store.dispatch('app/setAuth', {
      role,
      token,
      user: JSON.stringify(client.value),
    });
  });
}

watch(adminInfoResult, (newValue) => {
  admin.value = newValue.adminInfo;
});

const newNotifications = ref('');

let { result: allEvents, refetch } = useQuery(getAllEvents, {
  pagination: {
    offset: 0,
    limit: 0,
  },
});

watch(allEvents, (newValue) => {
  console.log(newValue);
  newNotifications.value = newValue.getAllEvents.all;
});

const isPopoverVisible = ref(false);

function togglePopover() {
  isPopoverVisible.value = !isPopoverVisible.value;
}

function disablePopover() {
  isPopoverVisible.value = false;
}

const isModalVisible = ref(false);

const setPopupVisibility = (status) => {
  isModalVisible.value = status;
};

const onModalClose = async () => {
  setPopupVisibility(false);
  const {
    data: { getAllEvents },
  } = await refetch();
  newNotifications.value = getAllEvents.all;
};

// onMounted(() => {
//   console.log(role, "ROLE");
// })
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors.scss';

.main-nav {
  padding: 0 36px;
  width: 100%;
  height: 88px;
  border-bottom: 1px solid var(--light-bg);
  display: flex;
  justify-content: flex-end;
  z-index: -1;

  &__logo {
    margin-right: 20px;
  }

  &__container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    display: flex;
    height: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__item {
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    &-text {
      font-family: Golos;
      color: var(--dark);
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
    }

    &-icon {
      color: map-get($primary-text, 'hover');

      &_platform {
        margin-right: 9px;
      }

      &_update,
      &_message {
        margin-right: 6px;
      }

      &_user {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}

.main-nav__updates {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 10px 10px 0;
}

.updates__icon {
  color: map-get($green, 'normal');
}

.green {
  color: map-get($green, 'normal');
}
</style>
