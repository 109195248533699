<template>
  <section class="text-center">
    <return-arrow></return-arrow>
    <main-text></main-text>
    <the-form></the-form>
  </section>
</template>

<script>
  import TheForm from './TheForm.vue';
  import MainText from './MainText.vue';
  import ReturnArrow from './ReturnArrow.vue';

  export default {
    components: { MainText, TheForm, ReturnArrow },
  };
</script>
