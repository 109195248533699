<template>
    <div class="d-flex">
        <h1 class="header header_black">Должники</h1>
    </div>

    <base-tabs-wrapper>
        <base-tab title="Информация о должниках">
           <DebtorsInfo @click="onClickSettingsButton" @showFilters="setFilterVisibility(true)" /> 
        </base-tab>
        <base-tab title="Архив">
           <DebtorsArchive /> 
        </base-tab>
    </base-tabs-wrapper>

    <DebtorsFilterModal 
        :is-modal-visible="isFilterVisible"
        @close="setFilterVisibility(false)"
    />
    <DebtorsModal
        :is-modal-visible="isDebtorsModalVisible"
        @close="setDebtorsPopupVisibility(false)"
    />
</template>
<script setup>
import { BaseTabsWrapper, BaseTab } from '@/components/atoms/';
import DebtorsInfo from './DebtorsInfo.vue';
import DebtorsArchive from './DebtorsArchive.vue';
import { ref, reactive, watch } from 'vue';
import DebtorsModal from './DebtorsModal.vue';
import DebtorsFilterModal from './DebtorsFilterModal.vue';
import { getDebtors } from '@/graphql/Debtors.graphql';
import { useQuery } from '@vue/apollo-composable';

const isDebtorsModalVisible = ref(false);
const isFilterVisible = ref(false);

const filters = reactive({
  pagination: {
    limit: 10,
    offset: 0
  },
  filters: {
    complexName: "",
    buildingNumber: 0,
    debtDur: 0,
    debtSum: 0,
    warnCount: 0
  }
});

const { result: debtors } = useQuery(getDebtors, filters);

watch(debtors, (value) => {
    console.log(value, '[DEBTORS]');
});

const setDebtorsPopupVisibility = async (status) => {
    isDebtorsModalVisible.value = status;

//   if (!status) {
//   }
};

const setFilterVisibility = async (status) => {
    isFilterVisible.value = status;

//   if (!status) {
//   }
};

const onClickSettingsButton = (status) => {
    setDebtorsPopupVisibility(status);
}
</script>
<style scoped lang="scss">
</style>