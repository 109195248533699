import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/admin-uk/managers',
    name: 'AdminUkManagersPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/clients',
    name: 'AdminUkClientsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
    children: [
      {
        path: 'card/:id',
        name: 'AdminUkUserCardPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
    ],
  },
  {
    path: '/app/admin-uk/cameras',
    name: 'AdminUkCamerasPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/clients-with-faceId',
    name: 'AdminUkClientsWithFaceIdPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/house-complex',
    name: 'AdminUkHouseComplexPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/notifications',
    name: 'AdminUkNotificationsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/news',
    name: 'AdminUkNewsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/logs',
    name: 'AdminUkLogsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/claims',
    name: 'AdminUkClaimsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
  {
    path: '/app/admin-uk/directory',
    name: 'AdminUkDirectoryPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
    children: [
      {
        path: 'categories',
        name: 'AdminUkCategoriesPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
      {
        path: 'executors',
        name: 'AdminUkExecutorsPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
      {
        path: 'posts',
        name: 'AdminUkPostsPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
      {
        path: 'complexes',
        name: 'AdminUkComplexesPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
      {
        path: 'parking',
        name: 'AdminUkParkingPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
      {
        path: 'street-parking',
        name: 'AdminUkStreetParkingPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['adminuk'],
        },
      },
    ],
  },
  {
    path: '/app/admin-uk/votes',
    name: 'AdminUkVotesPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['adminuk'],
    },
  },
];
