<template>
  <ul class="pagination d-flex">
    <li class="pagination__item">
      <button
        class="pagination__button pagination__arrow pagination__arrow_back"
        :class="{ disabled: isInFirstPage }"
        type="button"
        @click="onClickPreviousPage"
      >
        <mdicon name="chevron-left" /><span>назад</span>
      </button>
    </li>

    <li class="pagination__item" v-if="currentPage > 2">
      <button
        type="button"
        @click="onClickFirstPage"
        class="pagination__button pagination__page"
      >
        1
      </button>
    </li>
    <li v-if="currentPage > 3" class="pagination__item pagination__dots">…</li>

    <li class="pagination__item" v-for="(page, idx) in pages" :key="idx">
      <button
        type="button"
        @click="onClickPage(page.number)"
        :class="[
          { pagination_active: isPageActive(page.number) },
          'pagination__button',
          'pagination__page',
        ]"
        :disabled="page.isDisabled"
      >
        {{ page.number }}
      </button>
    </li>

    <li
      v-if="currentPage < totalPages - 2"
      class="pagination__item pagination__dots"
    >
      …
    </li>
    <li
      class="pagination__item"
      v-if="currentPage < totalPages - 1 && totalPages !== 3"
    >
      <button
        type="button"
        @click="onClickLastPage"
        class="pagination__button pagination__page"
      >
        <span class="pagination__text">{{ totalPages }}</span>
      </button>
    </li>

    <li class="pagination__item">
      <button
        type="button"
        @click="onClickNextPage"
        class="pagination__button pagination__arrow pagination__arrow_next"
        :class="{ disabled: isInLastPage }"
      >
        <span>вперед</span><mdicon name="chevron-right" />
      </button>
    </li>
  </ul>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['page-changed'],

  setup(props, { emit }) {
    const startPage = computed(() => {
      if (props.currentPage === 1) return 1;
      if (props.currentPage === props.totalPages) {
        return (
          props.totalPages -
          props.maxVisibleButtons +
          (props.maxVisibleButtons - 1)
        );
      }
      return props.currentPage - 1;
    });

    const endPage = computed(() => {
      return Math.min(
        startPage.value + props.maxVisibleButtons - 1,
        props.totalPages
      );
    });

    const pages = computed(() => {
      let range = [];
      for (let i = startPage.value; i <= endPage.value; i++) {
        range.push({
          number: i,
          isDisabled: i === props.currentPage,
        });
      }
      return range;
    });

    const isInFirstPage = computed(() => {
      return props.currentPage === 1;
    });

    const isInLastPage = computed(() => {
      return props.currentPage === props.totalPages;
    });

    const onClickFirstPage = () => {
      emit('page-changed', 1);
    };

    const onClickLastPage = () => {
      emit('page-changed', props.totalPages);
    };

    const onClickPreviousPage = () => {
      emit('page-changed', props.currentPage - 1);
    };

    const onClickNextPage = () => {
      emit('page-changed', props.currentPage + 1);
    };

    const onClickPage = (page) => {
      emit('page-changed', page);
    };

    const isPageActive = (page) => {
      return props.currentPage === page;
    };

    return {
      onClickPreviousPage,
      isInFirstPage,
      onClickFirstPage,
      pages,
      onClickLastPage,
      onClickNextPage,
      isInLastPage,
      onClickPage,
      isPageActive,
    };
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/styles/_colors';

.pagination {
  justify-content: flex-end;
  align-items: center;
  &__item {
    margin-right: 6px;
  }
  &__button {
    border: none;
    background-color: var(--white);
    cursor: pointer;
    /* width: 24px;
    height: 24px; */
    padding: 3px 7px;
    color: var(--grey-text-color);
    border-radius: 3px;
  }

  &__page {
    &:hover:enabled {
      background-color: #e9ecef;
    }
  }

  &__arrow {
    width: fit-content;
    &_back {
      margin-right: 10px;
    }
    &_next {
      margin-left: 10px;
    }
  }

  &__dots {
    padding-top: 3px;
    color: var(--grey-text-color);
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  &_active {
    background-color: map-get($primary-text, 'hover');
    color: var(--white);
  }
}
.pagination__button.disabled {
  opacity: 0.4;
  pointer-events: none;
}
</style>
