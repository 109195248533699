<template>
  <div
    :class="[
        'base-input',
        {
          [`base-input_type_${type}`]: type,
          [`base-input_icon_${icon}`]: icon,
        },
      ]"
  >
    <input
      :type="type"
      :icon="icon"
      class="base-input__field"
      :placeholder="placeholder"
      :value="inputValue"
      @input="onInput"
    />
    <label class="base-input__label">{{ label }}</label>
  </div>
</template>
<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'base-input',
    props: {
      value: [String, Number],
      placeholder: String,
      type: {
        type: String,
        default: 'text'
      },
      label: String,
      icon: String
    },
    setup(props, { emit }) {
      const inputValue = ref(props.value);

      watch(() => props.value, (newValue) => {
        inputValue.value = newValue;
      });

      const onInput = (event) => {
        emit('input', event);
      };

      return { inputValue, onInput };
    }
  }
</script>
<style lang="scss" scoped>
  @import '~@/assets/styles/_colors';

  .base-input {
    position: relative;
    z-index: 0;
    // padding-top: 10px;
    padding-top: 25px;

    &__field {
      padding: 10px;
      font-family: 'Golos', sans-serif;
      color: map-get($black-text, 'normal');
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid var(--grey-border-color);
      resize: none;
      width: 100%;
      height: 100%;
      font-weight: 500;
      transition: all 0.3s ease 0s;
      font-size: 16px;

      &:focus {
        border: 1px solid map-get($green, 'normal');
      }

      &--disabled {
        background-color: #D9D9D91C;
        border: none;
        user-select: none;
      }
      // padding: 8px 0 5px 0;
      // width: 100%;
      // color: map-get($black-text, 'normal');
      // font-family: "Open Sans", sans-serif;
      // font-style: normal;
      // font-weight: normal;
      // font-size: 14px;
      // line-height: 24px;
      // background-color: transparent;
      // border: none;
      // border-bottom: 1px solid map-get($lightgray-stroke, 'normal');
      // box-sizing: border-box;

      // &:hover {
      //   border-bottom-color: map-get($lightgray-stroke, 'hover');
      // }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      color: map-get($lightgray-text, 'normal');
      font-family: 'Golos', sans-serif;

      // position: absolute;
      // left: 0;
      // z-index: -1;
      // color: map-get($lightgray-text, 'normal');
      // font-family: 'Golos', sans-serif;
      // font-style: normal;
      // font-weight: normal;
      // transition: 200ms;
      // top: 0;
      // font-size: 12px;
      // line-height: 16px;
    }

    &__field::placeholder {
      color: map-get($lightgray-text, 'normal');
      opacity: 90%;
      font-size: 16px;
    }
  }
</style>
