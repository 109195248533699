<template>
  <div
    :class="[
      'notification d-flex',
      isVisible ? 'notification-active' : 'notification-disable',
    ]"
  >
    <div class="notification-status" :class="`notification_${type}`">
      <mdicon
        size="40"
        class="notification-icon"
        :name="type === 'error' ? 'close' : 'check-bold'"
      />
    </div>
    <p class="notification__text">{{ text }}</p>
    <div @click="closeNotification">
      <mdicon name="close" size="40" class="notification-close" />
    </div>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import { useStore } from 'vuex';

  export default {
    setup() {
      const VISIBILITY_TIMEOUT = 5000;
      const store = useStore();
      const isVisible = ref(false);
      const text = ref('');
      const type = ref('');
      let timer = null;

      const notifications = computed(() => {
        return store.getters['notification/isNotificationVisible'];
      });

      function closeNotification() {
        clearTimeout(timer);
        store.dispatch('notification/hideNotification');
      }

      watch(notifications, () => {
        text.value = store.getters['notification/notificationText'];
        type.value = store.getters['notification/notificationType'];

        if (notifications.value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }

        timer = setTimeout(() => {
          closeNotification();
        }, VISIBILITY_TIMEOUT);
      });

      return { isVisible, text, type, closeNotification };
    },
  };
</script>

<style lang="scss">
  @import '~@/assets/styles/_colors';
  .notification {
    position: fixed;
    z-index: 32001;
    bottom: 0;
    right: 0;
    padding: 26px 37px 26px 42px;
    background-color: var(--white);
    border-radius: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    justify-content: space-between;
    margin-top: 15px;
    border-radius: 5px;
    transition: all 0.3s ease 0s;

    &-disable {
      transform: translate(110%, 0);
    }
    &-active {
      transform: translate(0, 0);
    }

    &-status {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 40px;
    }

    &_success {
      background-color: map-get($green, 'normal');
    }
    &_error {
      background-color: map-get($red, 'dark');
    }

    &-icon {
      color: var(--white);
    }

    &__text {
      color: var(--black);
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;
    }

    &-close {
      margin-left: 60px;
      color: map-get($lightgray-stroke, 'normal');
      cursor: pointer;
    }
  }
</style>
