<template>
  <div class="card">
    <div class="d-flex justify-content-between">
      <span class="card__date">{{ setDate }}</span>
      <div
        class="button__delete"
        @click="onDeleteVote(vote.vote.id)"
        v-if="!vote.totalUsers && !vote.vote.archived"
      >
        Удалить
      </div>
    </div>
    <div class="card__title">{{ vote.vote.title }}</div>
    <div class="card__count">
      {{ vote.totalUsers }}
      {{
        declinationFromNumber(
          vote.totalUsers,
          'человек проголосовал',
          'человека проголосовало',
          'человек проголосовало'
        )
      }}
    </div>
    <div class="result__list">
      <div
        class="result__item"
        v-for="result in results"
        :key="result.optionId"
        :class="{ active: showMore || results.indexOf(result) <= 2 }"
      >
        <div
          v-if="showMore || results.indexOf(result) <= 2"
          class="result__body"
        >
          <span class="result__text">{{ result.title }}</span>
          <span class="result__text"
            >{{ calcPercent(result.count, vote.totalResults) }}%</span
          >
          <div
            v-if="THEME === 'domion'"
            class="result__progress"
            :style="`width: ${calcPercent(
              result.count,
              vote.totalResults
            )}%; background: rgba(2, 103, 255, ${
              results.indexOf(result) === 0 || result.count === results[0].count
                ? 0.33
                : 0.15
            })`"
          ></div>
          <div
            v-else
            class="result__progress"
            :style="`width: ${calcPercent(
              result.count,
              vote.totalResults
            )}%; background: rgba(160, 232, 175, ${
              results.indexOf(result) === 0 || result.count === results[0].count
                ? 0.33
                : 0.15
            })`"
          ></div>
        </div>
      </div>
      <span
        class="result__more"
        @click="toggleResult"
        v-if="vote.resultsCount.length > 3"
        >{{ showMore ? 'Скрыть' : 'Показать еще' }}</span
      >
    </div>
    <div class="card__buttons" :class="{ archived: vote.vote.archived }">
      <div>
        <div class="button__more" @click="setPopupVisibility(true)">
          Подробнее
        </div>
      </div>
      <base-button
        v-if="!vote.vote.archived"
        color="green"
        text="Завершить"
        size="extra-small"
        @click="onArchiveVote(vote.vote.id)"
        :disabled="vote.vote.archived"
      ></base-button>
      <div class="card__footer" v-else>
        <p>Опрос завершен</p>
      </div>
    </div>
    <Teleport to="body">
      <the-confirm
        :is-visible="isDeleteConfirmVisible"
        @close="onToggleDeleteConfirm"
        @confirm="isDeleteConfirmed"
      />
      <VotesInfoModal
        :isModalVisible="isModalVisible"
        @close="setPopupVisibility(false)"
        :vote="vote"
      />
    </Teleport>
  </div>
</template>
<script setup>
import { ref, defineProps, computed, defineEmits, watch } from 'vue';
import { BaseButton } from '@/components/atoms';
import TheConfirm from '@/components/TheConfirm.vue';
import VotesInfoModal from './VotesInfoModal.vue';
import { useMutation } from '@vue/apollo-composable';
import { deleteVote } from '@/graphql/Votes.graphql';
import { useStore } from 'vuex';

const THEME = process.env.VUE_APP_THEME;
const store = useStore();

const emit = defineEmits(['archive', 'delete']);

const props = defineProps({
  vote: {
    type: Object,
    required: true,
  },
});

const results = computed(() => {
  const voteItem = props.vote.resultsCount;
  return voteItem
    .slice()
    .sort((a, b) => a.count - b.count)
    .reverse();
});

const showMore = ref(false);

const toggleResult = () => {
  showMore.value = !showMore.value;
};

const setDate = computed(() => {
  return new Date(props.vote.vote.createdAt).toLocaleDateString('ru-RU');
});

const onArchiveVote = (id) => {
  const isVoteCanBeArchived = confirm(
    'Вы действительно хотите завершить опрос?'
  );
  if (isVoteCanBeArchived) {
    emit('archive', id);
  }
};
const isDeleteConfirmVisible = ref(false);
const isVoteShouldBeDeleted = ref(false);

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isVoteShouldBeDeleted.value = true;
};

const { mutate: deleteVotesMutation } = useMutation(deleteVote);

const onDeleteVote = (id) => {
  isDeleteConfirmVisible.value = true;
  let prevId;
  watch(isVoteShouldBeDeleted, async (newValue) => {
    if (newValue && prevId !== id) {
      const data = await deleteVotesMutation({
        id,
      });

      if (data.data.deleteVote) {
        store.dispatch('notification/showNotification', {
          text: `Опрос успешно удален`,
          type: 'success',
        });
      } else {
        store.dispatch('notification/showNotification', {
          text: `Произошла ошибка`,
          type: 'error',
        });
      }
      prevId = id;
      emit('delete');
      isVoteShouldBeDeleted.value = false;
    }
  });
};

const declinationFromNumber = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

const calcPercent = (count, total) => {
  return ((count / total) * 100 || 0).toFixed(2);
};

const isModalVisible = ref(false);

const setPopupVisibility = (status) => {
  isModalVisible.value = status;
};
</script>
<style>
.card {
  padding: 10px 20px 15px 20px;
  width: 350px;
  box-shadow: 0px 4px 4px var(--black-notification-shadow);
  border-radius: 17px;
  box-sizing: border-box;
  position: relative;
  margin: 0 25px 25px 0;
  height: fit-content;
}
.card__date {
  color: var(--grey-text-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.25;
  display: block;
  width: fit-content;
  margin-bottom: 15px;
}
.card__title {
  color: var(--dark);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}
.card__count {
  color: var(--grey-text-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.25;
  text-align: center;
  margin-top: 10px;
}
.result__list {
  padding: 10px 0 30px 0;
  position: relative;
}
.result__list::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--grey-purple);
}
.card.disabled .result__list::after {
  content: none;
}
.card.card.disabled {
  opacity: 0.5;
}
.result__item {
  position: relative;
  overflow: hidden;
}
.result__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 40px; */
  border: 1px solid var(--grey-purple);
  filter: drop-shadow(0px 4px 4px var(--black-notification-shadow));
  border-radius: 10px;
  padding: 11px 16px;
  position: relative;
  overflow: hidden;
}
.result__item.active:not(:last-child) {
  margin-bottom: 10px;
}
.result__text {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.25;
  color: var(--dark);
}
.result__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
}
.card__buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__buttons.archived {
  margin-top: 18.5px;
  margin-bottom: 10px;
}
.button__delete {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  color: var(--button-error-disable-color);
  cursor: pointer;
}
.result__more {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  color: var(--primary-color);
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  display: block;
}
/* .card__footer {
  margin-top: 25.75px;
  margin-bottom: 19px;
} */
.card__footer p {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25px;
  color: #bababa;
  text-align: right;
}
.button__more {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  color: var(--primary-color);
  cursor: pointer;
}
</style>
