export const claimData = {
    attach: [],
    id: null,
    status: null,
    description: '',
    dateFrom: new Date(),
    apartment: {
        id: null,
        number: null
    },
    comment: null
}