import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

const linkGreen = ['lk.greenwood.cirus-it.com', 'greenwood.cirus-it.com', 'admin.greenwood.cirus-it.com'];
const linkDomion = ['domion.tech', 'lk.domion.tech', 'admin.domion.tech'];

const isDomionHost = linkDomion.includes(window.location.host);
const isGreenHost = linkGreen.includes(window.location.host);

const THEME = process.env.VUE_APP_THEME;
const HOST = THEME === 'domion' ? isDomionHost : isGreenHost;

const httpLink = createUploadLink({
  uri:
    HOST
      ? `${process.env.VUE_APP_API_PROD}/graphql`
      : `${process.env.VUE_APP_API_DEV}/graphql`,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

export const apolloUploadClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});
