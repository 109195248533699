<template>
  <div>
    <h1 class="header header_black">Справочник</h1>
    <base-list :list="list" />
  </div>
</template>
<script setup>
import { BaseList } from '@/components/atoms';
import { ref } from 'vue';
const list = ref([
  {
    title: 'Категории',
    link: '/app/admin-uk/directory/categories',
  },
  {
    title: 'Исполнители',
    link: '/app/admin-uk/directory/executors',
  },
  {
    title: 'Должности',
    link: '/app/admin-uk/directory/posts',
  },
  {
    title: 'Комплексы',
    link: '/app/admin-uk/directory/complexes',
  },
  {
    title: 'Паркинг',
    link: '/app/admin-uk/directory/parking',
  },
  {
    title: 'Парковка',
    link: '/app/admin-uk/directory/street-parking',
  },
]);
</script>
<style scoped></style>
