<template>
    <div class="mt-20">
        <base-table class="history-table">
        <template v-slot:head>
            <base-table-row>
            <base-table-head-cell width="9%">
                <base-checkbox
                    :value="0"
                    v-model:values="allChecked"
                    class="checkbox_big"
                ></base-checkbox>
            </base-table-head-cell>
            <base-table-head-cell width="10%">Пользователь</base-table-head-cell>
            <base-table-head-cell width="15%">ЖК</base-table-head-cell>
            <base-table-head-cell width="10%">Лицевой счет</base-table-head-cell>
            <base-table-head-cell width="10%">Адрес</base-table-head-cell>
            <base-table-head-cell width="15%">Задолженность</base-table-head-cell>
            <base-table-head-cell width="15%">Период задолженности</base-table-head-cell>
            <base-table-head-cell width="15%">Предупреждения</base-table-head-cell>
            <base-table-head-cell width="9%"></base-table-head-cell>
        </base-table-row>
        </template>
        <template v-slot:body>
            <base-table-row
                v-for="data in debtors"
                :key="data.id"
                @click="onRowClick(data)"
            >
                <base-table-cell width="9%">
                <base-checkbox
                    :value="data.id"
                    v-model:values="checkedDebtor"
                    class="checkbox_big"
                ></base-checkbox>
                </base-table-cell>
                <base-table-cell width="10%">
                    <span class="cell-text">
                        {{ data.user }}
                    </span>
                </base-table-cell>
                <base-table-cell width="15%">
                    <span class="cell-text">
                        {{ data.complex }}
                    </span>
                </base-table-cell>
                <base-table-cell width="10%">
                    <span class="cell-text">
                        {{ data.clientId }}
                    </span>
                </base-table-cell>
                <base-table-cell width="10%">
                    <span class="cell-text">
                        {{ data.address }}
                    </span>
                </base-table-cell>
                <base-table-cell width="15%">
                    <span class="cell-text--price">
                        {{ data.debth }}₽
                    </span>
                </base-table-cell>
                <base-table-cell width="15%">
                    <span class="cell-text">
                        {{ data.period }} дней
                    </span>
                </base-table-cell>
                <base-table-cell width="15%">
                    <span class="cell-text">
                        {{ data.warnings }}
                    </span>
                </base-table-cell>
                <base-table-cell width="9%">
                <div class="message">
                    <mdicon name="phone-outline" size="30" class="message__icon" />
                </div>
                </base-table-cell>
            </base-table-row>
        </template>
        </base-table>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { BaseCheckbox } from '@/components/atoms';

const emit = defineEmits(['onRowClick']);
defineProps({
    filter: { type: String, required: true }
});

const allChecked = ref([]);
const checkedDebtor = ref([]);
const debtors = ref([
    { 
        id: 'a', 
        user: 'Петр', 
        complex: 'Альфа', 
        clientId: '57-24a', 
        address: 'Кирова 24',
        debth: '35 000',
        period: '35',
        warnings: '2 раза' 
    },
]);

const onRowClick = (row) => {
    emit('onRowClick', row);
}
</script>

<style scoped lang="scss">
.message {
  margin-bottom: 7px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
  padding: 7px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.message__icon {
  color: #50ad2e;
}

.cell-text {
    font-weight: 500;
    font-size: 13px;
    color: #8A8A8A;
}
.cell-text--price {
    font-weight: 500;
    font-size: 14px;
    color: #1C252CC7;
}
</style>