export const statusClass = (status) => {
    switch (status) {
      case 'OPENED':
        return 'open';
      case 'PROCESSING':
        return 'processed';
      case 'IN_PROGRESS':
        return 'closed';
      case 'CLOSED':
        return 'closed';
      case 'REJECTED':
        return 'rejected';
    }
};