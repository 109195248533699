<template>
  <div class="call-logs">
    <div class="d-flex">
      <div class="search-input-wrapper">
        <input type="text" class="search-input" v-model="filter.search" />
        <mdicon size="20" name="magnify" class="search-input-icon" />
      </div>
      <Datepicker
        class="datepicker"
        v-model="filterDate"
        range
        locale="ru-RU"
        selectText="Выбрать"
        cancelText="Отмена"
        :format="'dd/MM/yyyy HH:mm'"
        placeholder="Выберите дату и время"
      >
      </Datepicker>
    </div>
    <div v-if="isLoading" class="mt-20 text-center">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <base-table class="table" v-else>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="15%">Дата/время</base-table-head-cell>
          <base-table-head-cell width="10%">Дом</base-table-head-cell>
          <base-table-head-cell width="10%">Квартира</base-table-head-cell>
          <base-table-head-cell width="41%">Событие</base-table-head-cell>
          <base-table-head-cell width="12%"
            >Длительность звонка</base-table-head-cell
          >
          <base-table-head-cell width="15%"
            >Длительность разговора</base-table-head-cell
          >
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          @click="onModalOpen(callLog)"
          class="table-row"
          v-for="callLog in callLogs"
          :key="callLog.id"
        >
          <base-table-cell width="15%">{{
            setDate(callLog.createdAt)
          }}</base-table-cell>
          <base-table-cell width="10%">
            {{ callLog.building.name }}</base-table-cell
          >
          <base-table-cell width="10%">
            Квартира {{ callLog.apartment.number }}</base-table-cell
          >
          <base-table-cell width="41%">
            <span :class="{ red: !callLog.answerer }">
              {{
                callLog.answerer
                  ? `На звонок ответил ${callLog.answerer.lastName} ${callLog.answerer?.firstName}`
                  : 'Звонок пропущен'
              }}
            </span>
          </base-table-cell>
          <base-table-cell width="12%">
            {{ callLog.callDuration }}
          </base-table-cell>
          <base-table-cell width="15%">
            {{ callLog.talkDuration ?? '-' }}
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
    <the-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @pageChanged="onChangePage"
      class="pagination"
      :class="{ hidden: totalPages <= 1 }"
    ></the-pagination>
  </div>
  <CallLogsModal
    :is-modal-visible="isModalVisible"
    @close="setPopupVisibility(false)"
    :callLog="selectedCallLog"
  />
</template>
<script setup>
import { ref, watch, onMounted, reactive } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import CallLogsModal from './CallLogsModal.vue';
import { getCallsHistory } from '@/graphql/Logs.graphql';
import { useQuery } from '@vue/apollo-composable';
import ThePagination from '@/components/ThePagination.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();

const route = useRoute();
const router = useRouter();

const date = ref();
watch(date, (newDate) => {
  console.log(newDate);
});

const callLogs = ref([]);
const isLoading = ref(true);

const selectedCallLog = ref(null);

const totalPages = ref(0);
const elementsOnPage = ref(5);

const isModalVisible = ref(false);

const setPopupVisibility = (status) => {
  isModalVisible.value = status;
};
const onModalOpen = (item) => {
  selectedCallLog.value = item;
  setPopupVisibility(true);
};
const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const filterDate = ref(null);

watch(filterDate, (newValue) => {
  if (newValue) {
    filter.fromDate = new Date(newValue[0]).toLocaleString('Ru');
    if (new Date(newValue[1]).getTime()) {
      filter.toDate = new Date(newValue[1]).toLocaleString('Ru');
    }
  } else {
    filter.fromDate = null;
    filter.toDate = null;
  }
});

const filter = reactive({
  search: route.query.search || null,
  fromDate: null,
  toDate: null,
});

let {
  result: callLogsResult,
  fetchMore,
  refetch,
} = useQuery(getCallsHistory, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    q: route.query.search || null,
    fromDate: null,
    toDate: null,
  },
});

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      callLogs.value = [...fetchMoreResult.getCallsHistory.calls];
    },
  });
};

onMounted(() => {
  loadMore(+route.query.page || 0);
});

watch(callLogsResult, (newValue) => {
  getTotalPages(newValue?.getCallsHistory.filtered);
  isLoading.value = false;
});

const currentPage = ref(+route.query.page || 1);

const onChangePage = (page, filterList = null) => {
  // if (filterList) {
  //   filter = filterList;
  // }
  console.log('filter', filterList);
  router.replace({
    route,
    query: {
      page,
      search: filterList?.search,
    },
  });
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

let onLoad = true;

watch(filter, async (newValue) => {
  const {
    data: { getCallsHistory },
  } = await refetch({
    filter: {
      q: newValue.search,
      fromDate: newValue.fromDate,
      toDate: newValue.toDate,
    },
  });
  callLogs.value = [...getCallsHistory.calls];
  getTotalPages(getCallsHistory.filtered);
  if (onLoad) {
    onChangePage(+route.query.page || 1, newValue);
    onLoad = false;
  } else {
    onChangePage(1, newValue);
  }

  if (!callLogs.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
});

const setDate = (date) => {
  const currentDate = new Date(date.slice(0, -1));
  return `${currentDate.toLocaleDateString('ru-RU')} / ${String(
    currentDate.getHours()
  ).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}`;
};
</script>
<style scoped>
.call-logs {
  margin-top: 40px;
}
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 38px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.datepicker {
  width: 400px;
}
.table {
  margin-top: 40px;
}
.icon {
  color: var(--grey-disabled-color);
  margin-right: 3px;
  transform: rotate(45deg);
}
.table-row {
  cursor: pointer;
}
.red {
  color: var(--button-error-color);
}
.pagination {
  margin-top: 25px;
}
.pagination.hidden {
  opacity: 0;
  visibility: hidden;
}
</style>
