const state = {
    client: {
        debt: ''
    }
}

const mutations = {
    SAVE_DEBT(state, payload) {
        state.client = {
            debt: payload.debt
        };
    },
};

const actions = {
    saveDebt({ commit }, payload) {
        commit('SAVE_DEBT', payload);
    },
};

const getters = {
    getDebt(state) {
        return state.client.debt
    },
};

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state
};