<template>
  <div
    :class="[
      'button',
      {
        [`button_color_${color}`]: color,
        [`button_size_${size}`]: size,
        'is-loading': isLoading,
        'is-disabled': disabled,
      },
    ]"
    @click="onClick"
  >
    <router-link v-if="to" :to="to" class="button__item">
      <span class="button__text">{{ text }}</span>
    </router-link>
    <button v-if="text" class="button__item">
      <span class="button__text">{{ text }}</span>
    </button>
    <mdicon
    v-if="icon"
    :name="icon.name"
    :style="{ color: icon.color }"
    />
    <div class="button__loader" v-if="isLoading">
      <img src="@/assets/images/spinner.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      color: String,
      required: true,
    },
    color: {
      type: String,
      color: 'green',
    },
    disabled: Boolean,
    size: String,
    to: String,
    icon: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    return {
      onClick: (event) => {
        emit('onClick', event);
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/colors.scss';

.button {
  position: relative;

  display: inline-flex;
  width: fit-content;
  padding: 0 32px;
  height: 36px;

  align-items: center;
  justify-content: center;

  border-radius: 5px;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &__item {
    width: 100%;
    height: 100%;

    background: none;
    border: none;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: button;
    cursor: pointer;
  }

  &__text {
    display: inline-block;
    height: 20px;

    font-family: Golos;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
  }

  &_color {
    &_green {
      background-color: #50ad2e;
      box-shadow: 0 4px 4px rgba(80, 173, 46, 0.2);

      &:hover {
        background-color: #419125;
      }

      &.is-disabled {
        cursor: default;
        background-color: #89ae70;
        pointer-events: none;
      }
    }

    &_red {
      background-color: rgba(219, 78, 78, 0.8);
      box-shadow: 0 4px 4px rgba(219, 78, 78, 0.2);

      &:hover {
        background-color: #db3e29;
      }

      &.is-disabled {
        cursor: default;
        background-color: #89ae70;
        pointer-events: none;
      }
    }

    &_gray {
      background: rgba(83, 83, 82, 0.3);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        background: rgba(83, 82, 82, 0.6);
      }
      &.is-disabled {
        cursor: default;
        background-color: rgba(83, 83, 82, 0.2);
        pointer-events: none;
      }
    }
  }

  &_size_big {
    width: 236px;
    height: 40px;
  }

  &_size_big &__text {
    font-size: 18px;
    font-weight: 400;
    // line-height: 10px;
  }

  &_size_m {
    height: 36px;
  }

  &_size_extra-m {
    width: 179px;
    height: 36px;
  }

  &_size_extra-small {
    padding: 0 10px;
    height: 36px;
  }

  &__loader {
    position: absolute;
    top: 3px;
    left: 5px;

    img {
      width: 30px;
    }
  }

  &.is-loading {
    pointer-events: none;
  }

  &.is-loading &__text {
    opacity: 0.5;
  }
}
</style>
