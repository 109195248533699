<template>
  <div class="mt-156">
    <h2 class="text text_bold m-0">забыли пароль?</h2>
    <p class="text">Пожалуйста, укажите Ваш номер телефона и лицевой счет</p>
  </div>
</template>

<style scoped lang="scss">
.text {
  font-size: 18px;
  line-height: 22px;
  color: var(--grey-text-color);
  &_bold {
    font-weight: 600;
    font-size: 36px;
    line-height: 60px;
    color: var(--dark);
    text-transform: uppercase;
  }
}
</style>
