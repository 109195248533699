<template>
  <div class="filter">
    <div class="search-input-wrapper">
      <input type="text" class="search-input" v-model="filter.search" />
      <mdicon size="20" name="magnify" class="search-input-icon" />
    </div>
    <v-select
      :options="buildingsOptions"
      class="select modal-select"
      placeholder="Башня"
      :multiple="true"
      :reduce="(value) => value.id"
      label="value"
      v-model="filter.buildings"
    >
    </v-select>
  </div>
</template>
<script setup>
import { reactive, watch, ref, defineEmits } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { getBuildings } from '@/graphql/Claims.graphql';

const emit = defineEmits(['filter']);

const filter = reactive({
  search: '',
  buildings: null,
});

watch(filter, (newValue) => {
  emit('filter', newValue);
});

const buildingsOptions = ref([]);

const { result: allBuildings } = useQuery(getBuildings, () => ({
  complexId: 1,
}));

watch(allBuildings, (newValue) => {
  buildingsOptions.value = newValue.getBuildings.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});
</script>
<style scoped>
.filter {
  display: flex;
  align-items: center;
}
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.select {
  width: 250px;
}
</style>
