<template>
  <component :is="pageComponent" />
</template>

<script>
  import { watch, shallowRef } from 'vue';
  import { useRoute } from 'vue-router';

  import * as PublicPages from '@/components/pages/public';

  export default {
    name: 'PublicPagesController',
    components: { ...PublicPages },
    setup() {
      const route = useRoute();
      const pageComponent = shallowRef(null);

      const getPageComponent = (pageName) => {
        pageComponent.value = pageName;
      };

      watch(() => route.name, () => {
        getPageComponent(route.name);
      });

      getPageComponent(route.name);

      return { pageComponent };
    },
  };
</script>
