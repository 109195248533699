<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-for="title in tabTitles" :key="title" :class="{ selected: title == selectedTitle }"
        @click="selectedTitle = title">
        {{ title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from "vue";
export default {
  name: "base-tabs-wrapper",
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);

    provide("selectedTitle", selectedTitle);

    return {
      tabTitles,
      selectedTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
}

.tabs__header {
  width: 100%;
  display: flex;
}

.tabs__header li {
  padding: 7px 0 11px 22px;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px var(--black-shadow);
  border-radius: 0px 4px 4px 0px;
  color: #7f7f7e;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.tabs__header li.selected {
  color: var(--black);
  background: var(--white);
  font-weight: 600;
}
</style>
