<template>
  <h1 class="header header_black">Справочник</h1>
  <div class="d-flex justify-content-between mb-4">
    <span @click="router.back()" class="d-flex align-items-center sub-tltle">
      <mdicon size="24" class="arrow" name="chevron-left" /><span
        >Должности</span
      >
    </span>
    <div class="d-flex align-items-center">
      <base-button
        text="Создать должность"
        color="green"
        @click.prevent="setPostPopupVisibility(true)"
      />
    </div>
  </div>
  <div>
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <base-list :list="posts" @click="onPostUpdate" v-else />
  </div>
  <PostModal
    :is-post-modal-visible="isPostModalVisible"
    @close="setPostPopupVisibility(false)"
    :post="selectedPost"
    @create="onPostCreated"
    @delete="onPostDeleted"
    @update="onPostUpdated"
  />
</template>
<script setup>
import { ref, watch } from 'vue';
import { BaseButton, BaseList } from '@/components/atoms';
import PostModal from './PostModal.vue';
import { getAllPosts } from '@/graphql/ExecutorPost.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const isLoading = ref(true);

const posts = ref(null);

const selectedPost = ref(null);

const { result: allPosts } = useQuery(getAllPosts);

watch(allPosts, (newValue) => {
  posts.value = newValue.getAllPosts.map(({ title, id }) => {
    return {
      id,
      title,
    };
  });
  isLoading.value = false;
});

const isPostModalVisible = ref(false);

const setPostPopupVisibility = (status) => {
  isPostModalVisible.value = status;

  if (!status) {
    selectedPost.value = null;
  }
};

const onPostCreated = (post) => {
  posts.value = [...posts.value, post];
};

const onPostUpdated = (post) => {
  posts.value = posts.value.map((item) => {
    if (item.id === post.id) {
      return { ...post };
    }
    return item;
  });
};

const onPostDeleted = (id) => {
  posts.value = posts.value.filter((item) => item.id !== id);
};

const onPostUpdate = (post) => {
  selectedPost.value = post;
  console.log('selected post', selectedPost.value);
  setPostPopupVisibility(true);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
