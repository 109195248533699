import { createRouter, createWebHistory } from 'vue-router';

import routerConfig from '@/config/router';
import PublicRoutes from './public';
import Admins from './admin';
import AdminsUk from './adminUk';
import Manager from './manager';
import Client from './client';
import AuthPagesController from '@/controllers/AuthPagesController';

const hostname = window.location.hostname;
const subdomain = hostname.split('.')[0];

const routes = [
  {
    path: '/app',
    name: 'Home',
    component: AuthPagesController,
    meta: {
      auth: true,
      dependOnRole: true,
    },
  },
  ...PublicRoutes,
  ...Admins,
  ...AdminsUk,
  ...Manager,
  ...Client,
  {
    path: '/:catchAll(.*)',
    redirect: subdomain === 'admin' ? '/admin' : subdomain === 'lk' ? '/client' : '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

routerConfig.routerHandler(router);

export default router;
