<template>
  <div class="notifications">
    <h1 class="notifications__title">Центр уведомлений</h1>
    <div>
      <base-tabs-wrapper>
        <base-tab title="Рассылки">
          <send-notification-tab
            @submit="onNotificationsSubmit"
          ></send-notification-tab>
        </base-tab>
        <base-tab title="История">
          <div class="tab-container">
            <div class="history__table">
              <div v-if="isLoading" class="mt-20 text-center">
                <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
              </div>
              <base-table class="history-table" v-else>
                <template v-slot:head>
                  <base-table-row>
                    <base-table-head-cell width="10%"
                      >Дата</base-table-head-cell
                    >
                    <base-table-head-cell width="15%"
                      >Тема</base-table-head-cell
                    >
                    <base-table-head-cell width="35%"
                      >Содержимое</base-table-head-cell
                    >
                    <base-table-head-cell width="25%"
                      >ФИО владельца</base-table-head-cell
                    >
                    <base-table-head-cell width="15%"
                      >Лицевой счет</base-table-head-cell
                    >
                  </base-table-row>
                </template>
                <template v-slot:body>
                  <base-table-row
                    v-for="notification in notifications"
                    :key="notification.id"
                  >
                    <base-table-cell width="10%">
                      {{ notification.createdAt.substr(0, 10) }}
                    </base-table-cell>
                    <base-table-cell width="15%">{{
                      notification.title
                    }}</base-table-cell>
                    <base-table-cell width="35%">
                      {{ notification.text }}
                    </base-table-cell>
                    <base-table-cell width="25%">
                      {{ notification.client.user.lastName }}
                      {{ notification.client.user.firstName }}
                    </base-table-cell>
                    <base-table-cell width="15%">
                      {{ notification.ownership.account }}
                    </base-table-cell>
                  </base-table-row>
                </template>
              </base-table>
            </div>
            <the-pagination
              :totalPages="totalPages"
              :currentPage="currentPage"
              @pageChanged="onChangePage"
              class="pagination"
            ></the-pagination>
          </div>
        </base-tab>
      </base-tabs-wrapper>
    </div>
  </div>
</template>
<script setup>
import SendNotificationTab from './SendNotificationTab.vue';
import { BaseTabsWrapper, BaseTab } from '@/components/atoms';
import ThePagination from '@/components/ThePagination.vue';
import { ref, watch, onMounted } from 'vue';
import { getNotificationsHistory } from '@/graphql/Notification.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const route = useRoute();
const router = useRouter();

const isLoading = ref(true);
const notifications = ref([]);

const totalPages = ref(0);
const perPage = ref(5);
const currentPage = ref(+route.query.page || 1);

let {
  result: notificationsResult,
  refetch,
  fetchMore,
} = useQuery(getNotificationsHistory, {
  pagination: {
    limit: perPage.value,
    offset: 0,
  },
});

const loadMore = async (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  await fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * perPage.value,
        limit: perPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      console.log('fetchMoreResult', fetchMoreResult);
      (notifications.value =
        fetchMoreResult.getNotificationsHistory.notifications),
        getTotalPages(fetchMoreResult.getNotificationsHistory.total);
    },
  });
};

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / perPage.value);
};

onMounted(async () => {
  if (route.query.page) {
    isNotificationsResultCanBeUpdated = false;
  }
  await loadMore(+route.query.page || 0);
});

let isNotificationsResultCanBeUpdated = true;

watch(notificationsResult, (value) => {
  if (isNotificationsResultCanBeUpdated) {
    notifications.value = value.getNotificationsHistory.notifications;
    isNotificationsResultCanBeUpdated = false;
  }
  isLoading.value = false;
});

const onChangePage = async (page) => {
  router.replace({
    route,
    query: {
      page,
    },
  });
  currentPage.value = page;
  await loadMore(page);
};

const onNotificationsSubmit = async () => {
  const {
    data: { notificationsResult },
  } = await refetch();
  console.log(notificationsResult);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.pagination {
  margin-top: 25px;
}

.notifications {
  &__title {
    color: var(--black);
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
    margin-bottom: 21px;
  }
}

.tab-container {
  margin-top: 37px;
  border-top: 1px solid var(--grey-opacity);
}

.history {
  &__table {
    margin-top: 30px;
  }
}
</style>
