<template>
    <div class="payment-history">
        <div class="payment-history__title">История начислений</div>
        <div v-if="sortedHistory.length === 0" class="cards-client__empty">
            <img :src="emptyImg" class="cards-client__empty-img" alt="empty-claims" />
            <div class="cards-client__empty-text">
                На данный момент у вас еще нет истории начислений
            </div>
        </div>

        <base-table v-if="sortedHistory.length > 0">
            <template v-slot:head>
                <base-table-row>
                    <base-table-head-cell width="33%">Дата</base-table-head-cell>
                    <base-table-head-cell width="33%">Начисление</base-table-head-cell>
                    <base-table-head-cell width="34%">Оплата</base-table-head-cell>
                </base-table-row>
            </template>

            <template v-slot:body>
                <base-table-row v-for="(item) in sortedHistory" :key="item.id" @click="(() => { })">
                    <base-table-cell v-if="item.__typename === 'Matched'" width="33%">{{ new
                        Date(item.date).toLocaleDateString('ru-RU') }}</base-table-cell>
                    <base-table-cell v-if="item.__typename === 'Matched'" width="33%">
                        <!-- <div class="payment-history__table-cell"> -->
                        {{ item.total }}₽
                        <!-- <mdicon @click="getPdfFunc(new Date(item.date).toLocaleDateString('ru-RU'))" class="payment-history__table-icon" name="file-pdf-box" size="30" /> -->
                        <!-- </div> -->
                    </base-table-cell>
                    <base-table-cell v-if="item.__typename === 'Matched'" width="34%">
                        <div class="payment-history__table-cell">
                            +{{ item.amount }}₽
                            <mdicon @click="getPdfFunc(new Date(item.date).toLocaleDateString('ru-RU'))"
                                class="payment-history__table-icon" name="file-pdf-box" size="30" />
                        </div>
                    </base-table-cell>

                    <base-table-cell v-if="item.__typename === 'Payment'" width="33%">{{ new
                        Date(item.updatedAt).toLocaleDateString('ru-RU') }}</base-table-cell>
                    <base-table-cell v-if="item.__typename === 'Payment'" width="33%"></base-table-cell>
                    <base-table-cell v-if="item.__typename === 'Payment'" width="34%">+{{ item.amount }}₽</base-table-cell>

                    <base-table-cell v-if="item.__typename === 'OwnershipDebt'" width="33%">{{ new
                        Date(item.collectionDate).toLocaleDateString('ru-RU') }}</base-table-cell>
                    <base-table-cell v-if="item.__typename === 'OwnershipDebt'" width="33%">
                        <!-- <div class="payment-history__table-cell"> -->
                        {{ item.total }}₽
                        <!-- <mdicon @click="getPdfFunc(new Date(item.collectionDate).toLocaleDateString('ru-RU'))" class="payment-history__table-icon" name="file-pdf-box" size="30" /> -->
                        <!-- </div> -->
                    </base-table-cell>
                    <base-table-cell v-if="item.__typename === 'OwnershipDebt'" width="34%">
                        <div class="payment-history__table-cell">
                            <div>&#x200b;</div>
                            <mdicon @click="getPdfFunc(new Date(item.date).toLocaleDateString('ru-RU'))"
                                class="payment-history__table-icon" name="file-pdf-box" size="30" />
                        </div>
                    </base-table-cell>
                </base-table-row>
            </template>
        </base-table>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import {
    BaseTable,
    BaseTableCell,
    BaseTableHeadCell,
    BaseTableRow,
} from '@/components/BaseTable';
import { useQuery } from '@vue/apollo-composable';
import { getMyPayments, getMyDebts, getChargeDoc } from '@/graphql/Payments.graphql';
import { useStore } from 'vuex';
import emptyDomion from '@/assets/images/empty-claims.png';
import emptyGreen from '@/assets/images/claims-green/empty.png';

const THEME = process.env.VUE_APP_THEME
const store = useStore();

const emptyImg = THEME === 'domion' ? emptyDomion : emptyGreen;
const paymentHistory = ref([]);
const debtHistory = ref([]);
const combinedHistory = ref([]);
const sortedHistory = ref([]);
const formattedDate = ref();

const { result: paymentsResult } = useQuery(getMyPayments);
const { result: debtResult } = useQuery(getMyDebts);
const { refetch: getPdfDoc } = useQuery(getChargeDoc, { date: formattedDate.value });

function findMatchingDates(mergedArray) {
    const matchingDates = [];

    for (let i = 0; i < mergedArray.length; i++) {
        const currentItem = mergedArray[i];

        if (currentItem.__typename === 'Payment' && mergedArray[i + 1]?.__typename === 'OwnershipDebt') {
            const nextItem = mergedArray[i + 1];
            if (new Date(currentItem.updatedAt).setHours(0, 0, 0, 0) === new Date(nextItem.collectionDate).setHours(0, 0, 0, 0)) {
                matchingDates.push({
                    __typename: "Matched",
                    date: currentItem.updatedAt,
                    total: nextItem.total,
                    amount: currentItem.amount
                });
                i++;
            }
        }
    }

    return matchingDates;
}

function removeMatchingDates(merged, matchedDates) {
    const matchedDatesSet = new Set(matchedDates.map(item => {
        const date = new Date(item.date);
        date.setHours(0, 0, 0, 0);
        return date.getTime();
    }));

    console.log(matchedDatesSet, "SET");

    const filteredMerged = merged.filter(item => {
        const itemDate = new Date(item.updatedAt || item.collectionDate);
        itemDate.setHours(0, 0, 0, 0);
        return !matchedDatesSet.has(itemDate.getTime());
    });

    return filteredMerged;
}

function mergeAndSortHistory(payments, debts) {
    const merged = [...payments, ...debts];

    merged.sort((a, b) => {
        if (a.__typename === 'Payment' && b.__typename === 'OwnershipDebt') {
            return new Date(a.createdAt) - new Date(b.collectionDate);
        } else if (a.__typename === 'OwnershipDebt' && b.__typename === 'Payment') {
            return new Date(a.collectionDate) - new Date(b.createdAt);
        } else {
            return new Date(a.createdAt || a.collectionDate) - new Date(b.createdAt || b.collectionDate);
        }
    });

    let matchedDates = findMatchingDates(merged);
    let newTotal = removeMatchingDates(merged, matchedDates);

    newTotal = [...newTotal, ...matchedDates];

    newTotal.sort((a, b) => {
        const aDate = a.date || a.createdAt || a.collectionDate;
        const bDate = b.date || b.createdAt || b.collectionDate;

        return new Date(aDate).setHours(0, 0, 0, 0) - new Date(bDate).setHours(0, 0, 0, 0);
    });

    // console.log(newTotal, "TOTAL");

    return newTotal.reverse();
}

const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('.').map(Number);

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

const getPdfFunc = async (date) => {
    formattedDate.value = convertDateFormat(date);

    const { data } = await getPdfDoc(
        { date: formattedDate.value }
    );

    if (data.getChargeDoc === '') {
        store.dispatch('notification/showNotification', {
            text: 'Ссылки на этот расчет еще нет, попробуйте позже',
            type: 'error',
        });
        return;
    }

    window.open(data.getChargeDoc, '_blank');
}


watch(paymentsResult, (value) => {
    let arr = [...value.getMyPayments];
    paymentHistory.value = arr.reverse();

    combinedHistory.value = [
        ...combinedHistory.value,
        ...paymentHistory.value
    ];
});

watch(debtResult, (value) => {
    let arr = [...value.getMyDebts];
    debtHistory.value = arr.reverse();

    combinedHistory.value = [
        ...combinedHistory.value,
        ...debtHistory.value
    ];
});

watch(
    () => combinedHistory.value,
    async () => {
        sortedHistory.value = mergeAndSortHistory(paymentHistory.value, debtHistory.value);
    },
    { deep: true }
);
</script>

<style scoped lang="scss">
.payment-history {
    margin-top: 40px;

    &__title {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
        color: var(--grey-color);
    }

    &__table {
        &-cell {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-icon {
            margin-left: 10px;
            color: var(--primary-color);
        }
    }
}
</style>
