<template>
  <div
    class="el-table-cell"
    :title="title"
    :style="{
      width: width,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'base-table-cell',
    props: {
      title: {
        type: String,
        default: '',
      },
      width: String,
    },
  };
</script>

<style lang="scss">
  @import '../atoms/assets/styles/colors';

  .el {
    &-table-cell {
      //border-bottom: 1px solid map-get($table, 'borderColor');
      //border-right: 1px solid map-get($table, 'borderColor');
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      padding: 4px 16px;
      min-height: 58px;
      color: map-get($table, 'cellColor');
      // line-height: 1.5;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        //border-left: 1px solid map-get($table, 'borderColor');
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        //border-left: 1px solid map-get($table, 'borderColor');
      }
    }
  }
</style>
