<template>
  <router-link to="/login"><i class="return-arrow"></i></router-link>
</template>

<style scoped lang="scss">
  .return-arrow {
    position: absolute;
    left: 4%;
    top: 8%;

    display: inline-block;
    width: 6px;
    height: 6px;

    border: solid var(--button-hover-color);
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
</style>
