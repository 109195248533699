<template>
<footer class="footer">
    <p class="footer__text">
    Для регистрации в приложении обратитесь к Вашему менеджеру
    </p>
</footer>
</template>

<style scoped lang="scss">
.footer {
    margin: 60px 0 61px 0;

    &__text {
    color: var(--primary-color);
    font-size: 20px;
    line-height: 24px;
    }
}
</style>