<template>
  <div class="chat" v-if="!isCreating">
    <p
      class="chat__label chat__label_red"
      v-if="currentClaim.status === 'OPENED'"
    >
      Для того чтобы оставить комментарий дождитесь когда статус заявки будет "В
      работе"
    </p>
    <div class="chat__header">
      <p class="chat__label">Чат:</p>
      <div v-if="currentClaim.status !== 'OPENED'" class="chat__header-btn" @click="updateChat">
        <mdicon class="chat__header-btn-icon" size="16" :name="textButtonUpdate === 'обновить чат' ? 'reload' : 'check'" />
        {{ textButtonUpdate }}
      </div>
    </div>
    <div
      class="chat__body"
      :class="[
        { chat__body_full: comments?.length },
        { disabled: currentClaim.status === 'OPENED' },
      ]"
      ref="chatBody"
    >
      <div class="chat__day" v-for="day in comments" :key="day.date">
        <span class="chat__date_day chat__date">{{ day.date }} г.</span>
        <div
          class="chat__item"
          :class="!message.fromAdmin ? 'chat__item-admin' : 'chat__item-user'"
          v-for="message in day.messages"
          :key="message.createdAt"
        >
          <p class="chat__item-text">
            {{ message.text }}
          </p>
          <div
            class="chat__item-media chat-media"
            v-if="message.files || (!message.fromAdmin && message.viewed)"
          >
            <div>
              <div class="chat-media__files" v-if="message.files">
                <mdicon
                  class="chat-media__icon-file"
                  size="17"
                  name="paperclip"
                />
                <div class="chat-media__text">
                  {{ message.files.length }}
                  {{
                    declinationFromNumber(
                      message.files.length,
                      'вложение',
                      'вложения',
                      'вложений'
                    )
                  }}
                </div>
                <mdicon
                  class="chat-media__icon-arrow"
                  size="17"
                  name="chevron-down"
                />
              </div>
              <div class="chat-media__images chat-images" v-if="message.files">
                <vue-load-image>
                  <template v-slot:image>
                    <a
                      :href="image"
                      target="_blank"
                      class="chat-images__item"
                      v-for="(image, idx) in message.files"
                      :key="idx"
                    >
                      <img :src="image" :alt="`image ${message.requestId}`" />
                    </a>
                  </template>
                  <template v-slot:preloader>
                    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
                  </template>
                </vue-load-image>
              </div>
            </div>
            <mdicon
              v-if="!message.fromAdmin && message.viewed"
              class="chat-media__icon-check"
              size="20"
              name="check-all"
            />
          </div>
          <span class="chat__date_time">{{ getTime(message.createdAt) }}</span>
        </div>
      </div>
      <div
        class="chat__form"
        :class="{
          disabled: onClaimClosed,
        }"
      >
        <div class="chat__form__media-input-wrapper">
          <base-file-input
            accept="image/png, image/jpeg"
            :multiple="true"
            class="chat__form__media-input"
            @file="onChatFileChooseDescription"
          >
          </base-file-input>
          <!-- <input type="file" class="chat__form__media-input" /> -->
          <mdicon class="chat__form__icon-file" size="30" name="paperclip" />
        </div>
        <input
          type="text"
          class="chat__form__input"
          :placeholder="
            comments?.length ? 'Написать сообщение' : 'Напишите комментарий'
          "
          v-model.trim="chatMessage"
          @keypress.enter="sendChatMessage"
        />
        <button
          class="chat__form__submit-button"
          @click="sendChatMessage"
          :disabled="isSendMessageButtonDisabled"
        >
          <mdicon
            class="chat__form__icon-arrow"
            size="35"
            name="chevron-right"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BaseFileInput } from '@/components/atoms';
import { getMonthName } from '../../../../constant/monthName';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { getAllComments, addRequestComment } from '@/graphql/Claims.graphql';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { EventBus } from '@/utils/eventBus';
// import { claimData } from '@/constant/claimData';
import VueLoadImage from 'vue-load-image';
// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();

const props = defineProps({
  isCreating: { type: Boolean, default: true },
  claim: {},
});

const linkGreen = ['lk.greenwood.cirus-it.com', 'greenwood.cirus-it.com', 'admin.greenwood.cirus-it.com'];
const linkDomion = ['domion.tech', 'lk.domion.tech', 'admin.domion.tech'];

const isDomionHost = linkDomion.includes(window.location.host);
const isGreenHost = linkGreen.includes(window.location.host);

let COMMENTS_URL;

if (THEME === 'domion') {
  COMMENTS_URL = isDomionHost
    ? `${process.env.VUE_APP_API_PROD}/comment/attach`
    : `${process.env.VUE_APP_API_DEV}/comment/attach`;
} else {
  COMMENTS_URL = isGreenHost
    ? `${process.env.VUE_APP_API_PROD}/comment/attach`
    : `${process.env.VUE_APP_API_DEV}/comment/attach`;
}

// const COMMENTS_URL =
//   window.location.host === 'domion.develop.k8s.cirus-it.com'
//     ? `${process.env.VUE_APP_API_PROD}/comment/attach`
//     : `${process.env.VUE_APP_API_DEV}/comment/attach`;

const currentClaim = ref(props.claim);
const comments = ref([]);
const chatMessage = ref('');
const chatBody = ref(null);
const isSendMessageButtonDisabled = ref(false);
const user = store.getters['app/getUser'];
const userId = ref(JSON.parse(user).id);
const textButtonUpdate = ref('обновить чат');
// const isUpdate = ref(true);

const { mutate: addRequestCommentMutation } = useMutation(addRequestComment);
const { refetch: fetchMoreComments } = useQuery(getAllComments, {
  requestId: currentClaim.value.id,
  pagination: {
    page: 0,
    size: 100,
  },
});

const onClaimClosed = computed(() => {
  return props.claim?.status === 'CLOSED' || props.claim?.status === 'REJECTED';
});

const declinationFromNumber = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

const scrollToChatBottom = () => {
  if (props.claim) {
    setTimeout(() => {
      chatBody.value.scrollTop = chatBody.value.scrollHeight;
    }, 300);
  }
};

const updateChat = () => {
  if (textButtonUpdate.value === 'чат обновлен') return;
  getComments();
  textButtonUpdate.value = 'чат обновлен';
  setTimeout(() => {
    textButtonUpdate.value = 'обновить чат';
  }, 5000);
}

const sendChatMessage = async () => {
  if (chatMessage.value && !isSendMessageButtonDisabled.value) {
    isSendMessageButtonDisabled.value = true;
    const { data } = await addRequestCommentMutation({
      requestId: currentClaim.value.id,
      message: chatMessage.value,
    });
    chatMessage.value = '';
    console.log('commentMessage', data);

    const {
      data: { getAllComments },
    } = await fetchMoreComments({
      pagination: {
        page: 0,
        size: 100,
      },
    });

    getComments();

    scrollToChatBottom();
    isSendMessageButtonDisabled.value = false;
    console.log(getAllComments);
  }
};

const chatFiles = ref([]);

const onChatFileChooseDescription = (files) => {
  chatFiles.value = Object.values(files).map((item) => {
    return {
      file: item,
    };
  });
  // chatFiles.value = filesListFile;
  onLoadChatFiles(currentClaim.value.id, userId.value);
};

const isCanUpdateChat = ref(false);

const onLoadChatFiles = (requestId, currentUserId) => {
  const files = chatFiles.value.map(({ file }) => {
    console.log('file', file);
    return file;
  });

  console.log('files', files);
  if (files.length) {
    const form = new FormData();
    form.append('requestId', requestId);
    form.append('userId', currentUserId);
    for (const file of files) {
      form.append('files', file);
    }

    const request = new XMLHttpRequest();

    request.upload.addEventListener('loadend', (e) => {
      console.log('LOADED', e);
      setTimeout(() => {
        getComments();
        scrollToChatBottom();
      }, 200);
    });
    request.upload.addEventListener('error', (e) => {
      console.log('LoadImageError', e);
    });
    request.upload.addEventListener('abort', (e) => {
      console.log('LoadImageAbort', e);
    });

    request.open('post', COMMENTS_URL);
    request.timeout = 45000;
    request.send(form);

    isCanUpdateChat.value = false;
    chatFiles.value = [];
  }
};

const formatNumbers = (number) => ('0' + number).slice(-2);

const getTime = (initialDate) => {
  const date = new Date(initialDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${formatNumbers(hours)}:${formatNumbers(minutes)}`;
};

const getComments = async () => {
  const {
    data: { getAllComments },
  } = await fetchMoreComments({
    requestId: currentClaim.value.id,
    pagination: {
      page: 0,
      size: 100,
    },
  });

  const checkDate = (chatDate) => {
    const date = new Date(chatDate);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return `${formatNumbers(day)} ${getMonthName(month)} ${year}`;
  };

  comments.value = getAllComments.comments.reduce((acc, curr) => {
    const index = acc.findIndex(
      (item) => item.date === checkDate(curr.createdAt)
    );
    if (index === -1) {
      acc.push({ date: checkDate(curr.createdAt), messages: [curr] });
    } else {
      acc[index].messages.push(curr);
    }
    const newAcc = acc.map((item) => {
      const parsedMsg = item.messages.map((msgitem) => {
        return msgitem.files
          ? {
              ...msgitem,
              files:
                typeof msgitem.files === 'string'
                  ? JSON.parse(msgitem.files)
                  : msgitem.files,
            }
          : msgitem;
      });
      return { ...item, messages: parsedMsg };
    });
    return newAcc;
  }, []);

  comments.value = comments.value.reverse();
  comments.value.forEach((item) => (item.messages = item.messages.reverse()));
  scrollToChatBottom();
  console.log('comments', comments.value);
};

const clearComments = () => {
  // console.log('clearComments');
  // comments.value = [];
  // currentClaim.value = claimData;
};

// function compareWithoutDescription(obj1, obj2) {
//   const { description: _, ...restObj1 } = obj1;
//   const { description: __, ...restObj2 } = obj2;

//   // console.log(_, __);
//   _ + __
//   const keys1 = Object.keys(restObj1);
//   const keys2 = Object.keys(restObj2);

//   if (keys1.length !== keys2.length) {
//     return true;
//   }

//   for (let key of keys1) {
//     if (restObj1[key] !== restObj2[key]) {
//       return true;
//     }
//   }

//   return false;
// }

onMounted( async () => {
  console.log(props.claim, "IN CHAT");
    // isUpdate.value = compareWithoutDescription(props.claim, currentClaim.value);
    currentClaim.value = props.claim;

    // console.log(props.isCreating, "IS CREATING?");
    if (!props.isCreating) {
      console.log('chat upd');
      await getComments();
    }
})

// watch(
//   () => props.claim,
//   async () => {
//     console.log(props.claim, "IN CHAT");
//     isUpdate.value = compareWithoutDescription(props.claim, currentClaim.value);
//     currentClaim.value = props.claim;

//     // console.log(props.isCreating, "IS CREATING?");
//     if (!props.isCreating && isUpdate.value) {
//       console.log('chat upd');
//       await getComments();
//     }
//   }
// );

onMounted(() => {
  EventBus.on('clear-client-comments', () => clearComments());
});

onUnmounted(() => {
  EventBus.off('clear-client-comments', () => clearComments());
});
</script>