<template>
  <base-popup
    class="modal"
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    :title="camera ? 'Редактировать камеру' : 'Добавить камеру'"
    @close="onModalClose"
  >
    <form class="form" @submit.prevent="onFormSubmit">
      <div class="form-row">
        <div class="col">
          <div class="form__field">
            <base-input
              label="Камера*"
              placeholder="Наименование камеры"
              :value="currentCamera.name"
              @input="onManagerUserInput($event, 'name')"
            />
          </div>
        </div>
        <div class="col">
          <div class="form__field">
            <base-select
              v-if="complexes.length"
              label="Комплекс"
              :value="complexes[0].name"
              :options="
                complexes.map(({ name, id }, idx) => ({
                  name,
                  id,
                  value: !Boolean(idx),
                }))
              "
              @change="onSelectManagerStatus"
              placeholder="Статус"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form__field">
            <base-input
              label="Ссылка на ресурс*"
              placeholder="https://..."
              :value="currentCamera.serialNumber"
              @input="onManagerUserInput($event, 'serialNumber')"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form__field">
            <base-file-input
              placeholder="Загрузите изображение"
              accept="image/png, image/jpeg"
              @file="onFileChoose"
            />
            <div class="upload-image" v-if="currentCamera.thumbnail">
              <img :src="currentCamera.thumbnail" class="form__thumb" />
            </div>
          </div>
        </div>
      </div>
      <div class="form__field text-right mt-24">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />
        <base-button
          text="Сохранить"
          color="green"
          :is-loading="isUserDataUploading"
          :disabled="isFormDataInvalid"
          @click.prevent="onFormSubmit"
        />
      </div>
    </form>
  </base-popup>
</template>

<script>
import { ref, watch, computed } from 'vue';

import useVuelidate from '@vuelidate/core';

import {
  BaseInput,
  BaseButton,
  BasePopup,
  BaseSelect,
  BaseFileInput,
} from '@/components/atoms';
import { getComplexes } from '@/graphql/Complex.graphql';
import { createCamera, updateCamera } from '@/graphql/Cameras.graphql';

import { useMutation, useQuery } from '@vue/apollo-composable';
import { required } from '@vuelidate/validators';
// import {getAllApartments} from '@/graphql/Client.graphql';

export default {
  name: 'manager-modal',
  components: {
    BasePopup,
    BaseInput,
    BaseButton,
    BaseSelect,
    BaseFileInput,
  },
  props: {
    camera: {
      name: String,
      serialNumber: String,
      id: Number,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let IMAGE_FILE = null;
    let isUserDataUploading = ref(false);
    const emptyCamera = {
      id: null,
      complexId: null,
      name: '',
      serialNumber: '',
    };
    const currentCamera = ref({
      id: null,
      complexId: null,
      name: '',
      serialNumber: '',
      thumbnail: null,
    });

    const complexes = ref([]);

    const rules = computed(() => {
      return {
        complexId: { required },
        name: { required },
        serialNumber: { required },
      };
    });

    const isFormDataInvalid = computed(() => {
      return v$.value.$invalid;
    });

    const v$ = useVuelidate(rules, currentCamera);

    let { result: complexesResult } = useQuery(getComplexes);

    watch(complexesResult, (value) => {
      complexes.value = value.getAllComplexes;
      currentCamera.value.complexId = value.getAllComplexes[0]?.id;
      // isLoading.value = false;
    });

    const { mutate: createBuildingCamera } = useMutation(createCamera);
    const { mutate: updateCameraData } = useMutation(updateCamera);

    const onManagerUserInput = (event, type) => {
      currentCamera.value[type] = event.target.value;
    };

    const onSelectManagerStatus = (opt) => {
      currentCamera.value.complexId = opt.id;
    };

    const onCameraUpdate = async () => {
      const { name, serialNumber, id, complexId } = currentCamera.value;

      await updateCameraData({
        thumbFile: IMAGE_FILE,
        data: {
          name,
          serialNumber,
          complexId,
        },
        id,
      });

      emit('update', currentCamera.value);
    };

    const onCameraCreate = async () => {
      const { name, serialNumber, complexId } = currentCamera.value;

      const { data } = await createBuildingCamera({
        thumbFile: IMAGE_FILE || null,
        data: {
          serialNumber,
          name,
          complexId,
        },
      });

      emit('create', {
        ...data.createCamera,
        thumbnail: currentCamera.value.thumbnail,
      });
    };

    const onFormSubmit = async () => {
      if (isFormDataInvalid.value) return;

      isUserDataUploading.value = true;

      if (props.camera) {
        await onCameraUpdate();
      } else {
        await onCameraCreate();
      }

      isUserDataUploading.value = false;

      onModalClose();
    };

    watch(
      () => props.camera,
      (data) => {
        if (data) {
          currentCamera.value = JSON.parse(JSON.stringify(data));
        } else {
          currentCamera.value = ref({ ...emptyCamera }).value;
        }
      }
    );

    const onModalClose = () => {
      isUserDataUploading.value = false;

      currentCamera.value = ref({ ...emptyCamera }).value;
      emit('close');
    };

    const onFileChoose = (file) => {
      IMAGE_FILE = file;
      currentCamera.value.thumbnail = URL.createObjectURL(file);
    };

    return {
      isFormDataInvalid,
      v$,
      currentCamera,
      complexes,
      isUserDataUploading,
      onSelectManagerStatus,
      onManagerUserInput,
      onModalClose,
      onFormSubmit,
      onFileChoose,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-image {
  width: 100%;
  height: 200px;
  margin-top: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
