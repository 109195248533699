<template>
<base-popup
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Информация о пользователе"
    subtitle=""
    @close="onModalClose"
>

<div class="d-flex justify-content-between">
    <table class="no-border no-padding">
        <tr>
            <th>Пользователь:</th>
            <td>{{ debtor.user }}</td>
        </tr>
        <tr>
            <th>Жк:</th>
            <td>{{ debtor.complex }}</td>
        </tr>
        <tr>
            <th>№ Дома:</th>
            <td>{{ debtor.address }}</td>
        </tr>
        <tr>
            <th>Квартира:</th>
            <td>{{ debtor.clientId }}</td>
        </tr>
        <tr>
            <th class="cell-bold">Задолженность:</th>
            <td>{{ debtor.debth }}₽</td>
        </tr>
        <tr>
            <th>Время задолженности:</th>
            <td>{{ debtor.period }} дней</td>
        </tr>
        <tr>
            <th class="cell-bold">Предупреждения:</th>
            <td>{{ debtor.warnings }}</td>
        </tr>
    </table>
    <div>
        <BaseDropdown 
            color="green"
            text="Выбрать действие"
            size="extra-small"
            class="mr-10"
            :options="optionsBtn"
            @choose="onChooseOption"
        />
    </div>
</div>

<div class="line"></div>
<div class="d-flex justify-content-end">
    <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
    />

    <base-button
        text="Сохранить"
        color="green"
        @click.prevent="onFormSubmit"
    />
</div>
</base-popup>
</template>
<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';
import BaseDropdown from '../atoms/components/BaseDropdown.vue';

const emit = defineEmits(['close']);

defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  debtor: { type: Object }
});

const optionsBtn = ref([
  { id: 'notif', name: 'Сформировать уведомление' },
  { id: 'law', name: 'Подать документы в суд' },
  { id: 'time', name: 'Дать отсрочку', dropdown: [{ name: 'Подопция 1.1' }, { name: 'Подопция 1.2' }] },
]);

const onChooseOption = (item) => {
  console.log(item);
}

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emit('close');
  }
};

const onFormSubmit = () => {
    console.log('submit');
}
</script>

<style scoped>
.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;  
}

.no-border {
    border-collapse: collapse;
}

.no-border th, .no-border td {
    text-align: left;
    border: none;
    padding: 10px 15px 10px 0;
}

.no-border th {
    font-size: 13px;
    font-weight: 500;
    color: #8A8A8A;
}

.no-border td {
    font-size: 14px;
    font-weight: 600;
    color: initial;
}

.cell-bold {
    font-weight: 700 !important;
}
</style>