<template>
  <the-user-card></the-user-card>
</template>
<script>
  import TheUserCard from '@/components/TheUserCard';
  export default {
    name: 'user-card',
    components: {
      TheUserCard,
    },
  };
</script>
<style lang="">
</style>