import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/client/main',
    name: 'ClientMainPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['client'],
    },
  },
  {
    path: '/app/client/claims',
    name: 'ClientClaimsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['client'],
    },
  },
  {
    path: '/app/client/payments',
    name: 'ClientPaymentsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['client'],
    },
  }
]