<template>
  <div
    class="range-input-wrapper"
    :style="`width: ${width}px`"
    :class="{ disabled: disabled }"
  >
    <input
      type="range"
      :min="min"
      :max="max"
      :value="value"
      @input="onChange"
      class="range-input"
      ref="range"
    />
    <div class="scale">
      <span :class="`scale-item-${item}`" v-for="item in 7" :key="item"></span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';

const props = defineProps({
  min: {
    type: Number,
    default: 0,
    required: false,
  },
  max: {
    type: Number,
    default: 100,
    required: false,
  },
  value: {
    type: [Number, String],
    default: 0,
    required: false,
  },
  width: {
    type: Number,
    default: 300,
    required: false,
  },
  disabled: {
    type: Boolean,
  },
});
const emit = defineEmits(['change']);

const range = ref(null);

const fillInput = () => {
  const valPercent = (range.value.value / range.value.max) * 100;
  if (props.disabled) {
    range.value.style.background = `linear-gradient(to right, var(--grey-disabled-color) ${valPercent}%, rgba(39, 39, 39, 0.1) ${valPercent}%)`;
  } else {
    range.value.style.background = `linear-gradient(to right, var(--primary-range-color) ${valPercent}%, rgba(39, 39, 39, 0.1) ${valPercent}%)`;
  }
};

const onChange = (event) => {
  fillInput();
  emit('change', event.target.value);
};
onMounted(() => {
  fillInput();
});
</script>
<style scoped>
.range-input-wrapper {
  position: relative;
  user-select: none;
}
.range-input-wrapper.disabled {
  pointer-events: none;
}
.range-input {
  width: 100%;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  height: 10px;
  border-radius: 20px;
  background-color: rgba(39, 39, 39, 0.1);
  outline: none;
}
.range-input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 10px;
}
.range-input::-moz-track {
  -moz-appearance: none;
  height: 10px;
}
.range-input::-ms-track {
  appearance: none;
  height: 10px;
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-color: var(--primary-range-color);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-top: -5px;
}
.range-input-wrapper.disabled .range-input::-webkit-slider-thumb {
  background-color: var(--grey-disabled-color);
}
.range-input::-moz-range-thumb {
  -moz-appearance: none;
  height: 20px;
  width: 20px;
  background-color: var(--primary-range-color);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-top: -5px;
}
.range-input-wrapper.disabled .range-input::-moz-range-thumb {
  background-color: var(--grey-disabled-color);
}
.range-input::-ms-thumb {
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: var(--primary-range-color);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-top: -5px;
}
.range-input-wrapper.disabled .range-input::-ms-thumb {
  background-color: var(--grey-disabled-color);
}
.scale {
  width: 100%;
  position: relative;
  height: 12px;
  margin-top: 10px;
}
.scale span {
  position: absolute;
  top: 0;
  /* transform: translateX(-50%); */
  width: 1px;
  background: #7a7a7a;
}
.scale-item-1 {
  left: 20%;
  height: 6px;
}
.scale-item-2 {
  left: 30%;
  height: 12px;
}
.scale-item-3 {
  left: 40%;
  height: 6px;
}
.scale-item-4 {
  left: 50%;
  height: 12px;
}
.scale-item-5 {
  left: 60%;
  height: 6px;
}
.scale-item-6 {
  left: 70%;
  height: 12px;
}
.scale-item-7 {
  left: 80%;
  height: 6px;
}
</style>
