<template>
    <div>
        <div class="d-flex justify-content-between mt-20">
          <div class="d-flex">
            <div class="search-input-wrapper">
                <input type="text" class="search-input" v-model="filters.search" />
                <mdicon size="20" name="magnify" class="search-input-icon" />
            </div>
            <button
              class="filter__button filter__button_disable"
              @click="onShowFilters"
            >
                <mdicon name="filter" class="filter__button-icon" />
                <span class="filter__button-text">Очистить фильтры</span>
                <mdicon
                @click="clearFilters"
                name="close"
                class="filter__button-icon filter__button-icon_close"
                />
            </button>
          </div>

          <div class="d-flex">
            <BaseDropdown 
              color="green"
              text="Выбрать действие"
              size="extra-small"
              class="mr-10"
              :options="optionsBtn"
              @choose="onChooseOption"
            />
            <base-button
              color="green"
              size="extra-small"
              :icon="{
                  name: 'cog',
                  color: 'white'
              }"
              @click="onClick"
            ></base-button>
          </div>

        </div>
        <div class="mt-20">
            <base-tabs-wrapper>
                <base-tab title="Все">
                    <DebtorsList filter="all" @onRowClick="onRowClick" /> 
                </base-tab>
                <base-tab title="Уведомлены">
                    <DebtorsList filter="notify" /> 
                </base-tab>
                <base-tab title="Направлено в суд">
                    <DebtorsList filter="subpoenaed" /> 
                </base-tab>
            </base-tabs-wrapper>
        </div>

        <div class="d-flex debtors-info__settings-btn">
            <base-button
                color="green"
                text="Настроить страницу"
                size="extra-small"
                :icon="{
                  name: 'cog',
                  color: 'white'
                }"
                @click="onClick"
            ></base-button> 
        </div>
    </div>

    <DebtorsModalInfo 
      :is-modal-visible="isModalVisible"
      :debtor="selectedDebtor"
      @close="setPopupVisibility(false)" 
    />
    <DebthNotification 
      :is-modal-visible="isDebthNotificationVisible"
      :users="chosedUsers"
      @close="setNotificationVisibility(false)" 
    />
</template>
<script setup>
import { reactive, defineEmits, ref } from 'vue';
import { BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms/';
import DebtorsList from './DebtorsList.vue';
import BaseDropdown from '../atoms/components/BaseDropdown.vue';
import DebtorsModalInfo from './DebtorsModalInfo.vue';
import DebthNotification from './DebthNotification.vue';

const emit = defineEmits(['click', 'showFilters']);

const filters = reactive({
  search: null,
});

const optionsBtn = ref([
  { id: 'notif', name: 'Сформировать уведомление' },
  { id: 'law', name: 'Подать документы в суд' },
  { id: 'time', name: 'Дать отсрочку', dropdown: [{ name: 'Подопция 1.1' }, { name: 'Подопция 1.2' }] },
]);

const isModalVisible = ref(false);
const isDebthNotificationVisible = ref(false);
const selectedDebtor = ref(null);
const chosedUsers = ref([
  { user: 'Петр Петров' },
  { user: 'Петр Петров' },
  { user: 'Петр Петров' },
  { user: 'Петр Петров' },
  { user: 'Петр Петров' },
]);

const setPopupVisibility = async (status) => {
  isModalVisible.value = status;

//   if (!status) {
//   }
};

const setNotificationVisibility = async (status) => {
  isDebthNotificationVisible.value = status;

//   if (!status) {
//   }
};

const onRowClick = (item) => {
  selectedDebtor.value = item;
  console.log('selected', item);
  setPopupVisibility(true);
}

const onChooseOption = (item) => {
  if (item.id === 'notif') {
    setNotificationVisibility(true);
  }
  console.log(item);
}

const onShowFilters = () => {
  emit('showFilters')
}
const clearFilters = () => {}

const onClick = () => {
  emit('click', true);
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.debtors-info__settings-btn {
    width: 100%;
    justify-content: center;
}
.filter-input {
  font-family: 'Golos' !important;
  font-size: 18px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 4px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  color: #535352;
}
.filter__item {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 40px;
}
.filter-select:not(:last-child) {
  margin-right: 20px;
}
.filter__item_active .filter__item-text_title {
  font-weight: 700 !important;
  color: map-get($green, 'normal') !important;
}
.filter__item_active .filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
}
.filter__item-text {
  font-size: 18px;
  margin-right: 5px;
}
.filter__item-text_title {
  color: map-get($lightgray-text, 'normal');
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
.filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
// .filter {
//   max-width: 778px;
// }
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #f4f4f4;
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: #535352;
}
.filter__button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
.filter__button_disable {
  background: map-get($lightgray-text, 'normal');
}
.filter__button_disable:hover {
  background: map-get($lightgray-text, 'hover');
}
.filter__button-text {
  font-family: 'Golos';
  font-weight: 600;
  font-size: 15px;
  margin: 0 20px 0 5px;
}
.filter__button_active {
  background: map-get($green, 'normal');
}
.filter__button_active:hover {
  background: map-get($green, 'hover');
}

// .mt-20 {
//     margin-top: 20px;
// }
.mr-20 {
  margin-right: 20px;
}
</style>