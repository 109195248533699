<template>
  <div
    class="el-table-head-cell"
    :style="{
      'width': width,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'base-table-head-cell',
    props: {
      width: String,
    },
  };
</script>

<style lang="scss" scoped>
  @import '../atoms/assets/styles/colors';

  .el {
    &-table-head-cell {
      //border-right: 1px solid map-get($table, 'borderColor');
      display: inline-flex;
      align-items: center;
      padding: 4px 16px;
      font-size: 13px;
      color: map-get($table, 'cellColor');
      min-height: 58px;
      background-color: map-get($table, 'headCellBg');
      font-weight: 600;
      font-family: Inter;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        //border-left: 1px solid map-get($table, 'borderColor');
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        //border-left: 1px solid map-get($table, 'borderColor');
      }
    }
  }
</style>
