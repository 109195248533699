<template>
  <div
    v-if="isPopupContainerVisible"
    :class="[
      'popup-wrapper fade',
      {
        show: isPopupCanAnimate,
      },
    ]"
    @click="onClose"
  >
    <div
      :class="['popup', `popup_size_${size}`]"
      @click.stop
      :style="{ top: `${topIndent}px` }"
    >
      <div v-if="title || hasCloseIcon" class="popup__header">
        <div>
          <div v-if="title" class="popup__title">{{ title }}</div>
          <div v-if="subtitle" class="mt-2 popup__subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div v-if="hasCloseIcon" @click="onClose" class="popup__close">
          <mdicon name="close" size="30" class="popup__close-icon" />
        </div>
        <div v-if="hasRightSideContent">
          <slot name="right-side-content" />
        </div>
      </div>
      <div class="popup__body">
        <slot></slot>
      </div>

      <!--      todo задел под возможность листать те или иные данные открываемые в модалке-->
      <!--      <div class="popup__arrow-left">-->
      <!--        <mdicon name="chevron-left" class="icon" size="45"/>-->
      <!--      </div>-->
      <!--      <div class="popup__arrow-right">-->
      <!--        <mdicon name="chevron-right" class="icon" size="45"/>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
  import { toRefs, ref, watch } from 'vue';

  export default {
    name: 'BasePopup',
    props: {
      title: [String, Number],
      subtitle: String,
      topIndent: {
        type: [String, Number],
        default: 200,
      },
      hasCloseIcon: {
        type: Boolean,
        default: false,
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'middle',
      },
      hasRightSideContent: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const { isVisible } = toRefs(props);
      const isPopupContainerVisible = ref(isVisible.value);
      const isPopupCanAnimate = ref(isVisible.value);

      const onClose = (event) => {
        emit('close', event);
      };

      watch(isVisible, (visibilityStatus) => {
        if (visibilityStatus) {
          isPopupContainerVisible.value = visibilityStatus;
          setTimeout(() => (isPopupCanAnimate.value = visibilityStatus), 25);
        } else {
          setTimeout(
            () => (isPopupContainerVisible.value = visibilityStatus),
            300
          );
          isPopupCanAnimate.value = visibilityStatus;
        }
      });

      return {
        isPopupContainerVisible,
        isPopupCanAnimate,
        onClose,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/colors';

  .popup {
    min-width: 250px;
    position: absolute;
    left: 50%;
    background: var(--white);
    box-shadow: 0 4px 20px var(--black-popup-shadow);
    border-radius: 6px;
    padding: 25px 30px;
    z-index: 32001;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transform: translate(-50%, -25%);

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 32000;
      background: var(--grey-opacity);

      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
        pointer-events: none;
      }

      &.show {
        pointer-events: auto;
        opacity: 1;
        overflow: auto;

        .popup {
          transform: translate(-50%, 0);
        }
      }
    }

    &__header {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-family: Golos;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: map-get($primary-text, 'hover');
    }

    &__subtitle {
      font-family: Golos;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: map-get($lightgray-text, 'normal');
    }

    &__close {
      margin-left: auto;

      &-icon {
        transition: color 0.2s ease-in-out;
        cursor: pointer;
        color: map-get($primary-text, 'normal');
      }
    }

    &__arrow-left,
    &__arrow-right {
      color: map-get($lightblue-text, 'normal');
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translate(0, -50%);
      background-color: rgba(205, 205, 205, 0.7);
      width: 60px;
      height: 60px;
      font-size: 20px;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: rgba(205, 205, 205, 0.9);
      }
    }

    &__arrow-left {
      left: -100px;

      .icon {
        margin-left: -4px;
      }
    }

    &__arrow-right {
      right: -100px;

      .icon {
        margin-left: 2px;
      }
    }

    &_size_small {
      width: 250px;
    }

    &_size_auto {
      width: auto;
    }

    &_size_middle {
      width: 400px;
    }

    &_size_large {
      width: 600px;
    }

    &_size_extra-large {
      width: 800px;
    }
    &_size_largest {
      width: 1000px;
    }
  }
</style>
