<template>
  <div
    class="el-table"
  >
    <template v-if="loading">
      <div class="el-table__loading">
        loading...
      </div>
    </template>
    <template v-else>
      <div
        v-if="$slots.head"
        class="el-table__head"
      >
        <div>
          <slot name="head"></slot>
        </div>
      </div>
      <div
        v-if="$slots.body"
        class="el-table__body"
      >
        <slot name="body"></slot>
      </div>
      <div
        v-if="$slots.foot"
        class="el-table__foot"
      >
        <slot name="foot"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'base-table',
  props: {
    loading: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss">
.el {
  &-table {
    width: 100%;
    font-family: Golos;
    &__loading {
    }
  }
}
</style>
