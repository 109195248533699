<template>
  <div class="file-input d-flex" @click="onClickOnFileUpload">
    <mdicon class="file-input__icon" size="20" name="attachment" />
    <div v-if="!fileName" class="file-input__placeholder">
      {{ placeholder }}
    </div>
    <div v-if="!multiple" class="file-input__name">{{ fileName }}</div>
    <input
      id="upload-input"
      type="file"
      class="file-input__item"
      :accept="accept"
      @change="onFileChoose"
      :multiple="multiple ? 'multiple' : 'none'"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'BaseFileInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fileName = ref('');

    // const onFileChoose = (e) => {
    //   emit('file', e.target.files[0]);
    //   fileName.value = e.target.files[0].name;
    // };
    const onFileChoose = (e) => {
      props.multiple
        ? emit('file', e.target.files)
        : emit('file', e.target.files[0]);
      // console.log(e.target.files);
      fileName.value = e.target.files[0]?.name;
    };

    const onClickOnFileUpload = () => {
      const fileInput = document.getElementById('upload-input');
      fileInput.click();
    };

    return { fileName, onFileChoose, onClickOnFileUpload };
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  cursor: pointer;
  padding-bottom: 8px;

  font-family: Golos;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid var(--grey-border-color);

  &__item {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;

    opacity: 0;
    background: var(--grey-light-color);
  }

  &__icon {
    display: inline-block;
    transform: rotate(90deg);
    color: var(--grey-color);
  }

  &__placeholder {
    margin-left: 5px;
    pointer-events: none;
    color: var(--grey-color);
    opacity: 90%;
    line-height: 20px;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    font-size: 14px;
    white-space: nowrap;
  }
}
.modal-file-input .file__input-name {
  display: none;
}
</style>
