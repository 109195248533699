// Функция для шифрования строки
export const encryptString = (str, key) => {
  let encrypted = '';
  for (let i = 0; i < str.length; i++) {
    encrypted += String.fromCharCode(str.charCodeAt(i) ^ key);
  }
  return encrypted;
}

// Функция для расшифровки строки
export const decryptString = (encrypted, key) => {
  let decrypted = '';
  for (let i = 0; i < encrypted.length; i++) {
    decrypted += String.fromCharCode(encrypted.charCodeAt(i) ^ key);
  }
  return decrypted;
}
