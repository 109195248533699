<template>
  <div @click="onClose" class="confirm">
    <base-popup
      :is-visible="isPopupVisible"
      @close="onTogglePopup"
      size="large"
    >
      <div class="confirm__body">
        <p class="confirm__text">Вы уверены что хотите удалить элемент?</p>
        <p class="confirm__subtext">
          Для подтверждения введите "удалить" в поле ниже
        </p>
        <base-input
          type="text"
          placeholder="Введите"
          class="mt-24"
          :value="valueToDelete"
          @input="onValueToDeleteInput"
        ></base-input>
        <div v-if="isWrongValue" class="confirm__wrong-value">
          <p class="confirm__wrong-value-text">
            Пожалуйста введите текст правильно для подтверждения
          </p>
        </div>
        <div class="confirm__button">
          <base-button
            color="gray"
            text="Отменить"
            size="extra-small"
            @click="onClose"
            class="confirm__button_cancel"
          ></base-button>
          <base-button
            color="green"
            text="Удалить"
            size="extra-m"
            @click="onDelete"
          ></base-button>
        </div>
      </div>
    </base-popup>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { BaseInput, BaseButton, BasePopup } from '@/components/atoms';

export default {
  name: 'TheConfirm',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BasePopup,
    BaseInput,
    BaseButton,
  },
  setup(props, { emit }) {
    const { isVisible } = toRefs(props);
    const isPopupVisible = ref(false);
    const isConfirmVisible = ref(isVisible.value);
    const onTogglePopup = () => {
      isPopupVisible.value = !isPopupVisible.value;
    };

    const onClose = (event) => {
      emit('close', event);
      isPopupVisible.value = !isPopupVisible.value;
      valueToDelete.value = '';
      isWrongValue.value = false;
    };

    watch(isVisible, () => {
      isConfirmVisible.value = isVisible.value;
      isPopupVisible.value = isVisible.value;
    });

    const isWrongValue = ref(false);
    const valueToDelete = ref('');
    const onValueToDeleteInput = (event) => {
      valueToDelete.value = event.target.value;
    };

    const onDelete = (event) => {
      if (valueToDelete.value.toLowerCase().trim() === 'удалить') {
        onClose();
        emit('confirm', event);
      } else {
        isWrongValue.value = true;
      }
    };

    return {
      onTogglePopup,
      isPopupVisible,
      isConfirmVisible,
      onClose,
      valueToDelete,
      onValueToDeleteInput,
      onDelete,
      isWrongValue,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/colors';

.confirm {
  &__text {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    text-align: center;
    color: map-get($primary-text, 'normal');
    font-family: 'Golos';
  }
  &__subtext {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: map-get($primary-text, 'normal');
    font-family: 'Golos';
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    &_cancel {
      margin-right: 23px;
    }
  }

  &__wrong-value {
    &-text {
      color: var(--button-error-active-color);
      line-height: 24px;
    }
  }
}
</style>
