<template>
  <div class="popover">
    <ul class="popover__content">
      <li class="popover__item" @click="logout">Выйти</li>
    </ul>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const logout = async () => {
      await store.dispatch('app/logout');
      
      router.push('/');
    }

    return {
      logout,
    };
  },
};
</script>
<style lang="scss">
.popover {
  position: fixed;
  top: 54px;
  right: 0;
  margin: 7px 7px 0 0;
  width: 118px;
  padding: 0.5rem;
  height: max-content;
  background: var(--white);
  border: #c0b9b9 solid 1px;
  border-radius: 0.3rem;

  &__item {
    cursor: pointer;
    user-select: none;
    color: var(--dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
