<template>
  <div @click="() => click" class="tab-content" v-show="title == selectedTitle">
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "base-tab",
  props: ["title", "click"],
  setup() {
    const selectedTitle = inject("selectedTitle");

    return {
      selectedTitle,
    };
  },
};
</script>
