<template>
  <label class="claim-file-input cursor-pointer">
    <input
      type="file"
      name="claimFileInput[]"
      :accept="accept"
      class="claim-file-input__input"
      @change="onFileChoose"
      :multiple="multiple" 
    />
    <mdicon
      size="42"
      name="plus-circle-outline"
      class="claim-file-input__icon"
    />
  </label>
</template>

<script setup>
const emit = defineEmits(['file']);

defineProps({
  accept: { type: String, required: true },
  multiple: { type: Boolean, default: false }
});

const onFileChoose = (e) => {
  emit('file', e.target.files);
};
</script>

<style scoped lang="scss">
.claim-file-input {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-light-opacity-color);
  border-radius: 17px;

  &__icon {
    color: var(--primary-color);
  }
}

input[type='file'] {
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
