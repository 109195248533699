<template>
  <div>
    <div class="d-flex">
      <div class="search-input-wrapper">
        <input type="text" class="search-input" v-model="filterObj.search" />
        <mdicon size="20" name="magnify" class="search-input-icon" />
      </div>
      <button
        class="filter__button filter__button_disable"
        @click="clearFilters"
      >
        <mdicon name="filter" class="filter__button-icon" />
        <span class="filter__button-text">Очистить фильтры</span>
        <mdicon
          name="close"
          class="filter__button-icon filter__button-icon_close"
        />
      </button>
    </div>
    <div class="d-flex mt-40 align-items-center">
      <div
        class="filter__item"
        @click="toggleActiveFilter('ALL')"
        :class="filterObj.type === 'ALL' ? 'filter__item_active' : null"
      >
        <span class="filter__item-text filter__item-text_title">Все</span>
        <span class="filter__item-text filter__item-text_number">{{
          totalClaims
        }}</span>
      </div>
      <div
        class="filter__item"
        @click="toggleActiveFilter('ACTIVE')"
        :class="filterObj.type === 'ACTIVE' ? 'filter__item_active' : null"
      >
        <span class="filter__item-text filter__item-text_title">Активные</span>
        <span class="filter__item-text filter__item-text_number">{{
          activeClaims
        }}</span>
      </div>
      <div
        class="filter__item"
        @click="toggleActiveFilter('DONE')"
        :class="filterObj.type === 'DONE' ? 'filter__item_active' : null"
      >
        <span class="filter__item-text filter__item-text_title"
          >Завершенные
        </span>
        <span class="filter__item-text filter__item-text_number">{{
          completedClaims
        }}</span>
      </div>
      <div class="filter__item">
        <base-checkbox
          taggable
          value="1"
          v-model:values="filterCheckBoxActivity"
          label="По срокам исполнения"
        />
      </div>
    </div>
    <div class="d-flex mt-30">
      <v-select
        v-model="filterObj.category"
        :options="categoriesOptions"
        placeholder="Категория"
        class="filter-select"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template>
      </v-select>
      <v-select
        v-model="filterObj.status"
        :options="statusOptions"
        placeholder="Статус"
        class="filter-select"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template>
      </v-select>
      <input
        type="text"
        placeholder="Квартиры"
        class="filter-input"
        v-model="filterObj.apartment"
      />
      <v-select
        :options="executorOptions"
        placeholder="Исполнитель"
        class="filter-select"
        v-model="filterObj.executor"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, watch, defineEmits, defineProps, onMounted } from 'vue';
import { BaseCheckbox } from '@/components/atoms/';
import {
  getRequests,
  getAllExecutors,
  getStatuses,
  getAllCategories,
} from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  claimWatcher: {
    type: Number,
  },
});

const emit = defineEmits(['filter']);

const totalClaims = ref(0);
const completedClaims = ref(0);
const activeClaims = ref(0);

// Select Settings

const categoriesOptions = ref([]);

const statusOptions = ref([]);

const executorOptions = ref([]);

/////////////////////////////

const { result: allClaims, refetch } = useQuery(getRequests, {
  pagination: {
    limit: 10,
    offset: 0,
  },
  filter: {
    type: 'ACTIVE',
  },
});

watch(allClaims, (newValue) => {
  console.log('allClaims', newValue);
  totalClaims.value = newValue.getRequests.total;
  completedClaims.value = newValue.getRequests.done;
  activeClaims.value = newValue.getRequests.active;
});

const { result: allExecutors } = useQuery(getAllExecutors, {
  complexId: 1,
});

const executorUrlQuery = ref(null);

watch(allExecutors, (newValue) => {
  console.log('Filter Executors', newValue);
  executorOptions.value = newValue.getAllExecutors.executors.map(
    ({ id, name }) => {
      return {
        id,
        value: name,
      };
    }
  );
  executorUrlQuery.value = executorOptions.value.find(
    (item) => item.id === +route.query.executor
  );
  filterObj.executor = executorUrlQuery.value || null;
});

const { result: allStatuses } = useQuery(getStatuses);

watch(allStatuses, (newValue) => {
  statusOptions.value = newValue.getStatuses.map(({ name, title }) => {
    return {
      id: name,
      value: title,
    };
  });
});

const { result: allCategories } = useQuery(getAllCategories);

const categoryUrlQuery = ref(null);

watch(allCategories, (newValue) => {
  categoriesOptions.value = newValue.getAllCategories.map(({ title, id }) => {
    return {
      id,
      value: title,
    };
  });
  categoryUrlQuery.value = categoriesOptions.value.find(
    (item) => item.id === +route.query.category
  );
  filterObj.category = categoryUrlQuery.value || null;
});

watch(
  () => props.claimWatcher,
  async () => {
    const {
      data: { getRequests },
    } = await refetch();
    totalClaims.value = getRequests.total;
    completedClaims.value = getRequests.done;
    activeClaims.value = getRequests.active;
  }
);

// Filter Object

const filterObj = reactive({
  type: route.query.type || 'ACTIVE',
  category: null,
  status: route.query.status || null,
  executor: null,
  byDate: route.query.byDate === 'true' ? true : false,
  apartment: route.query.apartment || null,
  search: route.query.search || null,
});

watch(filterObj, (value) => {
  emit('filter', value);
});

onMounted(() => {
  emit('filter', filterObj);
});

const clearFilters = () => {
  filterObj.type = 'ACTIVE';
  filterObj.category = null;
  filterObj.status = null;
  filterObj.executor = null;
  filterObj.apartment = null;
  filterObj.search = null;
  filterCheckBoxActivity.value = [];
};

// Checkbox activity

console.log('query byDate', route.query.byDate);

const filterCheckBoxActivity = ref(route.query.byDate === 'true' ? ['1'] : []);
const isFilterCheckBoxActive = ref(false);

watch(filterCheckBoxActivity, (value) => {
  // filterCheckBoxActivity.value = value;
  console.log(
    'filterCheckBoxActivity',
    route.query.byDate === 'true' ? true : false
  );
  value.length
    ? (isFilterCheckBoxActive.value = true)
    : (isFilterCheckBoxActive.value = false);
  filterObj.byDate = isFilterCheckBoxActive.value;
});

///////////////////

// Check which filter active

const toggleActiveFilter = (value) => {
  filterObj.type = value;
};

///////////////////////
</script>
<style>
.filter-select {
  font-family: 'Golos' !important;
  font-size: 18px !important;
  width: 100%;
  color: var(--grey-text-color);
  --vs-border-radius: 5px;
  --vs-actions-padding: 4px 25px 0px 3px;
  --vs-border-color: var(--grey-border-color);
  --vs-dropdown-option--active-bg: var(--primary-color);
  --vs-font-size: 18px;
}
.filter-select .vs__search {
  color: var(--grey-dark-color);
}
</style>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.filter-input {
  font-family: 'Golos' !important;
  font-size: 18px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 4px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  color: var(--grey-dark-color);
}
.filter__item {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 40px;
}
.filter-select:not(:last-child) {
  margin-right: 20px;
}
.filter__item_active .filter__item-text_title {
  font-weight: 700 !important;
  color: map-get($green, 'normal') !important;
}
.filter__item_active .filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
}
.filter__item-text {
  font-size: 18px;
  margin-right: 5px;
}
.filter__item-text_title {
  color: map-get($lightgray-text, 'normal');
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
.filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
// .filter {
//   max-width: 778px;
// }
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.filter__button {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
.filter__button_disable {
  background: map-get($lightgray-text, 'normal');
}
.filter__button_disable:hover {
  background: map-get($lightgray-text, 'hover');
}
.filter__button-text {
  font-family: 'Golos';
  font-weight: 600;
  font-size: 15px;
  margin: 0 20px 0 5px;
}
.filter__button_active {
  background: map-get($green, 'normal');
}
.filter__button_active:hover {
  background: map-get($green, 'hover');
}

.mr-20 {
  margin-right: 20px;
}
</style>
