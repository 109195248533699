<template>
<section class="text-center login__container d-flex">
    <div>
        <the-logo></the-logo>
        <the-form></the-form>
    </div>
    <div>
        <the-footer></the-footer>
    </div>
</section>
</template>

<script>
import TheLogo from './TheLogo';
import TheForm from './TheForm';
import TheFooter from './TheFooter';

export default {
    components: {
        TheLogo,
        TheForm,
        TheFooter,
    },
};
</script>

<style lang="scss" scoped>
.login__container {
    width: 100%;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
</style>
