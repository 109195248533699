<template>
<base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Условия попадания в список должников"
    subtitle=""
    @close="onModalClose"
    >
    <div class="debtors-modal__select-block d-flex mt-30">
        <div>
          <span class="select-label">Период задолженности</span>
          <v-select
            v-model="filters.debthPeriod"
            :options="debthPeriodOptions"
            placeholder="Выберите период задолжности"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
        <div>
          <span class="select-label">Сумма задолжености (руб.)</span>
          <v-select
            v-model="filters.debthSum"
            :options="debthSumOptions"
            placeholder="Выберите сумму"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
    </div>

    <div class="debtors-modal__notify">
      <h1 class="header header_black">Уведомления</h1>

      <base-tabs-wrapper>
        <base-tab title="SMS">
          <SmsTab />
        </base-tab>
        <base-tab title="Пуш-уведомление">
          <PushTab />
        </base-tab>
        <base-tab title="E-mail">
          <EmailTab />
        </base-tab>
        <base-tab title="Звонки">
          <CallTab />
        </base-tab>
      </base-tabs-wrapper>
    </div>

    <div class="line"></div>

    <div class="d-flex justify-content-end">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button
        text="Сохранить"
        color="green"
        @click.prevent="onFormSubmit"
      />
    </div>
</base-popup>
</template>
<script setup>
import { BasePopup, BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits, reactive } from 'vue';
import SmsTab from './ModalTabs/SmsTab.vue';
import PushTab from './ModalTabs/PushTab.vue';
import EmailTab from './ModalTabs/EmailTab.vue';
import CallTab from './ModalTabs/CallTab.vue';

const emit = defineEmits(['close']);

defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
});

const filters = reactive({
    debthPeriod: null,
    debthSum: null
});

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    // currentClaim.value = ref({ ...emptyClaim }).value;
    // currentClaim.value.dateFrom = new Date();
    // isCategory2Visible.value = false;
    // isCategory3Visible.value = false;
    // complexValue.value = null;
    // executorValue.value = '';
    // filesValue.value = [];
    // filesValueFile.value = [];
    // comments.value = [];
    // claimId.value = 1;
    // chatMessage.value = '';
    emit('close');
  }
};

const onFormSubmit = () => {
    console.log('submit');
}
</script>

<style scoped lang="scss">
.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;  
}

.select-label {
  font-size: 12px;
  margin-bottom: 9px;
  display: block;
}

.debtors-modal {
  &__select-block {
    gap: 12px;

    &-select {
      width: 20rem;
    }
  }

  &__notify {
    margin-top: 36px;

    & > h1 {
      font-size: 23px;
      font-weight: 500;
      opacity: 70%;
    }
  }
}
</style>