<template>
    <form v-if="!showSendSmsForm" class="form mt-24" @submit.prevent="onSendSMS">
        <div class="d-flex align-items-baseline">
            <mdicon name="cellphone" size="20" class="icon-phone" />
            <div class="input wd-100">
                <input :value="form.phone" type="tel" placeholder="телефон"
                    class="input__field input__field_phone input__field_auth" @input="onChangeInput" maxlength="16" />
            </div>
        </div>

        <div class="mt-40 justify-content-center align-items-center">
            <div class="text-center">
                <base-button text="Получить код" :disabled="isEmptyField" color="green" size="small" />
            </div>
            <!-- <router-link to="/forgot-password" class="forgot-password">
                Забыли пароль?
            </router-link> -->
        </div>
    </form>

    <the-sms-form v-else :phone="form.phone" />
    <div v-if="showSendSmsForm" @click="toggleShowSmsForm" class="back-btn">
        <mdicon name="arrow-left" size="24" />
        Назад
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useMutation } from '@vue/apollo-composable';

import { BaseButton } from '@/components/atoms';
import { sendSMS } from '@/graphql/Login.graphql';
import TheSmsForm from './TheSmsForm.vue';
import { maskPhone, cleanPhoneNumber } from '@/utils/phoneMask';

// const emits = defineEmits(['toggle-password-alert'])

const store = useStore();
// const router = useRouter();

const form = reactive({
    phone: '',
});
const isEmptyField = ref(true);
const showSendSmsForm = ref(false);

const { mutate: sendSMSMutation } = useMutation(sendSMS);

const onChangeInput = (e) => {
    form.phone = maskPhone(e.target.value);
    if (e.target.value !== '') isEmptyField.value = false;
    else isEmptyField.value = true;
}

const toggleShowSmsForm = () => {
    showSendSmsForm.value = false;
    form.phone = '';
}

const onSendSMS = async () => {
    if (form.phone.length < 16 || /[a-zA-Z]/.test(form.phone)) {
        store.dispatch('notification/showNotification', {
            text: 'Ошибка, номер введен не корректно',
            type: 'error',
        });
        return;
    }
    const { data } = await sendSMSMutation({
        phone: cleanPhoneNumber(form.phone)
    });

    if (data.sendSMS) {
        showSendSmsForm.value = true;
        return;
    }

    store.dispatch('notification/showNotification', {
        text: 'Ошибка, возможно такого номера не существует, попробуйте еще раз',
        type: 'error',
    });

    console.log('data', { data });
};
</script>

<style scoped lang="scss">
.form {
    width: 300px;
    margin: 0 auto;
}

.icon-phone {
    color: var(--primary-opacity-color);
}

.back-btn {
    margin-top: 30px;
    padding: 12px;
    border: 1px solid var(--grey-border-color);
    border-radius: 100px;
    cursor: pointer;
    transition: .3s all;

    &:hover {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
}

.forgot-password {
    margin-top: 14px;
    display: inline-block;

    font-size: 12px;
    line-height: 14px;
    color: var(--primary-color);
    // text-decoration: none;
    cursor: pointer;
}

.phone-icon,
.password-icon {
    margin-right: 10px;
    color: var(--primary-color);
}
</style>
