<template>
  <div class="d-flex mt-30">
    <div class="search-input-wrapper">
      <input type="text" class="search-input" v-model="filterObj.search" />
      <mdicon size="20" name="magnify" class="search-input-icon" />
    </div>
    <!-- <v-select
      v-model="filterObj.complex"
      :options="complexOptions"
      placeholder="Комплекс"
      class="filter-select"
      :reduce="(value) => value.id"
      label="value"
    >
      <template v-slot:no-options>
        <div>Извините, ничего не найдено</div>
      </template>
    </v-select> -->
    <!-- <button class="filter__button filter__button_disable">
      <mdicon name="filter" class="filter__button-icon" />
      <span class="filter__button-text">Очистить фильтры</span>
      <mdicon
        name="close"
        class="filter__button-icon filter__button-icon_close"
      />
    </button> -->
  </div>
</template>
<script setup>
import { reactive, ref, watch, defineEmits } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { getAllComplexes } from '@/graphql/Claims.graphql';

const emit = defineEmits(['filter']);

const filterObj = reactive({
  // complex: null,
  search: '',
});

watch(filterObj, (newValue) => {
  emit('filter', newValue);
});

const { result: allComplexes } = useQuery(getAllComplexes);

const complexOptions = ref([]);

watch(allComplexes, (newValue) => {
  complexOptions.value = newValue.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.filter-select {
  width: 300px;
}
.filter-select:not(:last-child) {
  margin-right: 20px;
}
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.filter__button {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
.filter__button_disable {
  background: map-get($lightgray-text, 'normal');
}
.filter__button_disable:hover {
  background: map-get($lightgray-text, 'hover');
}
.filter__button-text {
  font-family: 'Golos';
  font-weight: 600;
  font-size: 15px;
  margin: 0 20px 0 5px;
}
</style>
