import { createApp, provide, h } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';

import App from './App.vue';
import router from './router';
import store from './store';
import { apolloUploadClient } from './config/graphql';
import phoneMask from './utils/phoneMask';
import clickOutside from './utils/clickOutside';
import Datepicker from '@vuepic/vue-datepicker';
import VueLazyload from 'vue-lazyload';

import '@vuepic/vue-datepicker/dist/main.css';

import './assets/styles/index.scss';

//import fontawesome icons
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/js/all';

import 'vue-select/dist/vue-select.css';

//import material icons
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';

import vSelect from 'vue-select';

if (process.env.VUE_APP_THEME === 'domion') {
  require('@/assets/styles/domion.scss');
} else if (process.env.VUE_APP_THEME === 'greenwood') {
  require('@/assets/styles/greenwood.scss');
}

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloUploadClient);
  },
  render: () => h(App),
});

export const loadimage = process.env.VUE_APP_THEME === 'domion' ? require('./assets/images/loader.svg') : require('./assets/images/loader-green.svg');

app.directive('phone', phoneMask);
app.directive('click-outside', clickOutside);

app.component('v-select', vSelect);
app.component('Datepicker', Datepicker);

app
  .use(store)
  .use(router)
  .use(VueLazyload, {
    loading: loadimage,
  })
  .use(mdiVue, {
    icons: mdijs,
  })
  .mount('#app');
