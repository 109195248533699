<template>
  <label :class="['container', { [`container_${size}`]: size }]">
      <img v-if="image" :src="image" alt="Checkbox image" class="checkbox-image">
      <span v-else>{{ label }}</span>
      <input
        type="checkbox"
        class="base-checkbox"
        :checked="checked"
        :value="value"
        @input="check()"
        v-bind="$attrs"
      />
      <span :class="['checkmark', { [`checkmark_${size}`]: size }]"></span>
  </label>
</template>

<script>
import { computed } from 'vue';
export default {
  name: 'Checkbox',
  emits: ['update:values'],
  props: {
    value: {
      type: [String, Number, Array],
      required: true,
    },
    size: String,
    values: {
      type: Array,
      required: true,
    },
    label: String,
    image: String,
  },
  setup(props, context) {
    const checked = computed(() => props.values.includes(props.value));

    function check() {
      let updatedNames = [...props.values];
      if (this.checked) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames.push(props.value);
      }
      context.emit('update:values', updatedNames);
    }

    return {
      check,
      checked,
    };
  },
};
</script>


<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 8px;
  height: 25px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: map-get($lightgray-text, 'normal');
}

.container_small {
  display: block;
  position: relative;
  padding-left: 25px;
  /* margin-bottom: 0px; */
  cursor: pointer;
  font-size: 12px;
  line-height: 14.4px;
  color: #1c252c;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_medium {
  display: block;
  position: relative;
  padding-left: 30px;
  /* margin-bottom: 0px; */
  cursor: pointer;
  font-size: 16px;
  line-height: 14.4px;
  color: #1c252c;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 0.5px solid #e6e2e2;
  border-radius: 3px;
}

.checkmark_small {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  border-radius: 2px;
}
.checkmark_medium {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
  border: 0.5px solid #e6e2e2;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #50ad2e;
}
.container input:checked ~ .checkmark_medium {
  margin-top: -2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 2px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container .checkmark_small:after {
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container .checkmark_medium:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>