<template>
<div class="mt-20 d-flex w-100">
    <div class="first-block">
        <div>
            <span class="select-label">Задать периодичность отправки</span>
            <v-select
                v-model="filters.period"
                :options="periodOptions"
                placeholder="Выберите периодичность звонка"
                class="select modal-select"
                :reduce="(value) => value.id"
                :multiple="true"
                label="value"
            >
                <template v-slot:no-options>
                <div>Извините, ничего не найдено</div>
                </template>
            </v-select>
        </div>
        <div class="d-flex justify-end">
            <div class="text-button">Добавить значение</div>
        </div>
    </div>

    <div class="second-block">
        <span class="select-label">Содержание</span>
        <textarea
            type="text"
            placeholder="Напишите тему письма"
            class="filter-input filter-textarea"
            v-model="filters.message"
        />
    </div>
</div>  
</template>
<script setup>
import { reactive, ref } from 'vue';

const periodOptions = ref([]);

const filters = reactive({
    notify: '',
    period: '',
    message: ''
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.mb-10 {
    margin-bottom: 10px;
}

.select {
    font-size: 14px !important;
}
.select::placeholder {
    font-size: 14px !important;
}

.checkboxes {
    margin: 30px 0;

    &__item {
        margin: 0;
        width: 200px;
    }
}

.select-label {
    font-family: 'Golos';
    font-size: 12px;
    margin-bottom: 9px;
    display: block;
}

.justify-end {
    margin-top: 5px;
    justify-content: end;
}

.text-button {
    font-size: 13px;
    font-weight: 500;
    color: map-get($primary-text, 'hover');
    cursor: pointer;
}

.w-100 {
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
}

.first-block {
    width: 503px;
}

.second-block {
    width: 100%;
}

.filter-input {
    font-family: 'Golos' !important;
    margin-bottom: 15px;
    height: 34px;
    font-size: 14px !important;
    width: 100%;
    margin-right: 20px;
    border: 1px solid map-get($lightgray-stroke, 'normal');
    border-radius: 10px;
    padding: 0 0 0 10px;
}
.filter-input::placeholder {
    font-size: 14px;
    font-weight: 400;
    opacity: .9;
}

.filter-textarea {
    width: 100% !important;
    padding-top: 5px;
    margin: 0;
    font-family: 'Golos' !important;
    height: 175px;
    resize: none;
}
</style>