<template>
    <div class="client-second-slide">
        <div class="client-second-slide__banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
            <div class="client-second-slide__banner-text">
                <div class="client-second-slide__banner-text-title"  :style="THEME === 'domion' ? 'color: var(--white)' : ''">Заявки</div>
                <div class="client-second-slide__banner-text-subtitle" :style="THEME === 'domion' ? 'color: var(--white)' : 'opacity: .5;'">
                    {{ subtitle }}
                </div>
                <div @click="pushToClaims" :class="THEME === 'domion' ? 'client-second-slide__banner-text-button' : 'client-second-slide__banner-text-button--green'" class="client-second-slide__banner-text-button">
                    Подробнее
                </div> 
            </div>
        </div>

        <div class="client-second-slide__image">
            <img class="client-fs__image-item" :src="girlImage" alt="girl"/> 
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import greenGirl from '@/assets/images/claims-green/girl.png';
import domionGirl from '@/assets/images/client/slides/girl.png';
import greenBgClaims from '@/assets/images/claims-green/orders-bg.png';
import domionBgClaims from '@/assets/images/client/slides/claims-bg.png';

const THEME = process.env.VUE_APP_THEME;
const router = useRouter();

const girlImage = THEME === 'domion' ? domionGirl : greenGirl;
const backgroundImage = THEME === 'domion' ? domionBgClaims : greenBgClaims; 
const subtitle = THEME === 'domion' ? 
`Оптимизируйте процесс обслуживания вашего дома с личным кабинетом Домион!\n\n Создавайте заявки онлайн и следите за их выполнением управляющей компанией. Быстро и удобно решайте любые вопросы, связанные с обслуживанием вашего жилого комплекса.`
:
`Оптимизируйте процесс обслуживания вашего дома с личным кабинетом Грин.Дом!\n\n Создавайте заявки онлайн и следите за их выполнением управляющей компанией. Быстро и удобно решайте любые вопросы, связанные с обслуживанием вашего жилого комплекса.`

const pushToClaims = async () => {
    await router.push('/app/client/claims');
}
</script>

<style scoped lang="scss">
.client-second-slide {
    position: relative;
    width: 100%;
    height: 530px;
    display: flex;
    align-items: end;

    &__banner {
        padding: 47px 35px;
        width: 100%;
        height: 399px;
        // background-image: url('../../../../../../src/assets/images/client/slides/claims-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 29px;
        box-sizing: border-box;

        &-text {
            max-width: 50%;
            text-align: left;

            &-title {
                margin-bottom: 20px;
                font-size: 37px;
                font-weight: 500;
                // color: var(--white);
            }

            &-subtitle {
                max-width: 473px;
                margin-bottom: 38px;
                font-size: 16px;
                font-weight: 500; 
                // color: var(--white);
            }

            &-button {
                width: 240px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 9px;
                background: var(--white);
                color: var(--primary-color) !important;
                cursor: pointer;

                &--green {
                    width: 240px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 9px;
                    background: var(--primary-color);
                    color: var(--white) !important;
                    cursor: pointer;
                }
            }

        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>