<template>
  <form class="mt-63" @submit.prevent="">
    <div class="input">
      <input
        type="tel"
        class="input__field input__field_phone input__field_auth"
        placeholder="+7 (999) 99-99-999"
      />
    </div>
    <div class="input">
      <input
        type="text"
        class="input__field input__field_id input__field_auth mt-30"
        placeholder="xxx-xxx-xxx"
      />
    </div>

    <div class="mt-55 text-center">
      <base-button text="восстановить" color="green" size="big" />
    </div>
  </form>
</template>

<script>
  import { BaseButton } from '@/components/atoms';

  export default {
    components: {
      BaseButton,
    },
  };
</script>
