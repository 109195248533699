<template>
  <base-popup
    size="large"
    :is-visible="isModalVisible"
    title="Редактировать клиента"
    @close="onModalClose"
  >
    <div class="inputs-wrapper">
      <div class="input-item">
        <label class="model-label">
          <span class="gray">Телефон:</span>
          <input
            v-mask="phoneMask"
            v-model="currentUser.phone"
            type="text"
            placeholder="+7(xxx)xxx-xx-xx"
            @complete="onComplete"
            class="modal-input"
          />
        </label>
      </div>
      <!-- <div class="input-item">
        <label class="model-label">
          <span class="gray">Почта:</span>
          <input
            type="email"
            v-model="currentUser.email"
            placeholder="Введите адрес электронной почты"
            class="modal-input"
          />
        </label>
      </div> -->
      <!-- <div class="input-item">
        <label class="model-label">
          <span class="gray">Комментарий:</span>
          <input type="text" placeholder="\" class="modal-input" />
        </label>
      </div> -->
    </div>
    <div class="d-flex justify-content-end mt-20">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button
        text="Сохранить"
        :disabled="isFormDataInvalid || isParkingInvalid"
        color="green"
        @click="onUserUpdate"
      />
    </div>
  </base-popup>
</template>
<script setup>
import { ref, watch, computed } from 'vue';
import { IMaskDirective } from 'vue-imask';
import { BasePopup, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits } from 'vue';
import { editUser } from '@/graphql/Client.graphql';
import { useMutation } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { helpers, required, numeric } from '@vuelidate/validators';

const emit = defineEmits(['close', 'update']);
const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  user: {},
});

// const emptyUser = {
//   id: null,
//   phone: '',
//   email: '',
// };

watch(
  () => props.isModalVisible,
  (newValue) => {
    if (newValue) {
      currentUser.value = JSON.parse(JSON.stringify(props.user));
    }
  }
);

const currentUser = ref({
  id: null,
  phone: '',
  unmaskedPhone: '',
  email: '',
});

const rules = computed(() => {
  return {
    phone: {
      minLengthValue: minLength(16),
    },
  };
});
const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});
const v$ = useVuelidate(rules, currentUser);

const onComplete = (e) => {
  currentUser.value.phone = e.detail.value;
  currentUser.value.unmaskedPhone = `+${e.detail.unmaskedValue}`;
};

const { mutate: editUserMutation } = useMutation(editUser);

const onUserUpdate = async () => {
  const { id, unmaskedPhone, email, phone } = currentUser.value;

  await editUserMutation({
    data: {
      phone: phone ? unmaskedPhone : '',
      email,
    },
    id,
  });
  emit('update', currentUser.value);
  onModalClose();
};

const vMask = IMaskDirective;

const phoneMask = ref({
  mask: '+{7}(000)000-00-00',
});

const onModalClose = () => {
  // currentUser.value = ref({ ...emptyUser }).value;
  emit('close');
};

const parkingItems = ref([]);

const rulesParking = computed(() => {
  return {
    $each: helpers.forEach({
      number: { required },
      level: { required, numeric },
    }),
  };
});
const v$Parking = useVuelidate(rulesParking, parkingItems);

const isParkingInvalid = computed(() => {
  return v$Parking.value.$invalid;
});
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  width: 100%;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
  width: 300px;
}
.modal-input_small {
  width: 130px;
}
.model-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.model-label span {
  margin-right: 20px;
}
.inputs-wrapper {
  width: 400px;
}
.input-item:not(:last-child) {
  margin-bottom: 20px;
}
.input-item__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-item__flex-wrapper {
  margin-top: 40px;
  width: 600px;
}
.input-select-wrapper {
  display: flex;
  align-items: center;
}
.input-select-wrapper span {
  margin-right: 20px;
}
.delete-item {
  height: 29px;
  width: 29px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}
.input-create {
  color: map-get($green, 'normal');
  cursor: pointer;
  width: fit-content;
}
.input-create-wrapper {
  display: flex;
  margin-top: 20px;
}
</style>
