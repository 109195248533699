import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/manager/clients',
    name: 'ManagerClientsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
    children: [
      {
        path: 'card/:id',
        name: 'ManagerUserCardPage',
        component: AuthPagesController,
        meta: {
          auth: true,
          roles: ['manager'],
        },
      },
    ],
  },
  {
    path: '/app/manager/clients-with-faceId',
    name: 'ManagerClientsWithFaceIdPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
  {
    path: '/app/manager/cameras',
    name: 'ManagerCamerasPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
  {
    path: '/app/manager/notification',
    name: 'ManagerNotificationsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
  {
    path: '/app/manager/news',
    name: 'ManagerNewsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
  {
    path: '/app/manager/claims',
    name: 'ManagerClaimsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
  {
    path: '/app/manager/debtors',
    name: 'ManagerDebtorsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['manager'],
    },
  },
];
