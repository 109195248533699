<template>
  <div>
    <base-popup
      size="large"
      :is-visible="isPostModalVisible"
      :title="post ? 'Редактировать должность' : 'Новая должность'"
      :has-right-side-content="Boolean(post)"
      @close="onModalClose"
    >
      <template v-slot:right-side-content>
        <div class="post-modal-delete" @click="onPostDelete">
          Удалить должность
        </div>
      </template>
      <div>
        <input
          type="text"
          placeholder="Введите должность..."
          class="modal-input modal-item"
          v-model="currentPost.title"
        />
      </div>
      <div class="d-flex justify-content-end mt-20">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />

        <base-button
          :text="post ? 'Сохранить' : 'Создать'"
          color="green"
          @click.prevent="onFormSubmit"
          :disabled="isFormDataInvalid"
        />
      </div>
    </base-popup>
  </div>
  <the-confirm
    :is-visible="isDeleteConfirmVisible"
    @close="onToggleDeleteConfirm"
    @confirm="isDeleteConfirmed"
  />
</template>
<script setup>
  import { BasePopup, BaseButton } from '@/components/atoms';
  import { defineProps, defineEmits, ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import TheConfirm from '@/components/TheConfirm.vue';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import {
    createPost,
    deletePost,
    updatePost,
  } from '@/graphql/ExecutorPost.graphql';
  import { useMutation } from '@vue/apollo-composable';

  const store = useStore();

  const emit = defineEmits(['close', 'create', 'delete', 'update']);

  const props = defineProps({
    isPostModalVisible: {
      type: Boolean,
      default: false,
    },
    post: {},
  });

  watch(
    () => props.post,
    (data) => {
      if (data) {
        console.log('post', data);
        currentPost.value = JSON.parse(JSON.stringify(data));
      } else {
        currentPost.value = ref({ ...emptyPost }).value;
      }
    }
  );

  const emptyPost = {
    title: '',
    id: null,
  };
  const currentPost = ref({
    title: '',
    id: null,
  });

  //Validation

  const rules = computed(() => {
    return {
      title: { required },
    };
  });

  const v$ = useVuelidate(rules, currentPost);

  const isFormDataInvalid = computed(() => {
    return v$.value.$invalid;
  });

  // Close Modal
  const onModalClose = () => {
    currentPost.value = ref({ ...emptyPost }).value;
    emit('close');
  };

  const { mutate: createPostMutation } = useMutation(createPost);
  const { mutate: deletePostMutation } = useMutation(deletePost);
  const { mutate: updatePostMutation } = useMutation(updatePost);

  const isDeleteConfirmVisible = ref(false);
  const isPostShouldBeDeleted = ref(false);

  const onToggleDeleteConfirm = () => {
    isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
  };

  const isDeleteConfirmed = () => {
    isPostShouldBeDeleted.value = true;
  };

  const onPostDelete = async () => {
    isDeleteConfirmVisible.value = true;
    watch(isPostShouldBeDeleted, async (newValue) => {
      if (newValue) {
        const { id } = currentPost.value;

        try {
          await deletePostMutation({
            id,
          });
          store.dispatch('notification/showNotification', {
            text: `Должность успешно удалена`,
            type: 'success',
          });
          onModalClose();
          emit('delete', id);
        } catch (error) {
          store.dispatch('notification/showNotification', {
            text: error,
            type: 'error',
          });
        } finally {
          isPostShouldBeDeleted.value = false;
        }
      }
    });
  };

  const onPostCreate = async () => {
    const { title } = currentPost.value;

    const { data } = await createPostMutation({
      title,
    });

    emit('create', data.createPost);
    console.log('postData', { data });
  };

  const onPostUpdate = async () => {
    const { id, title } = currentPost.value;

    await updatePostMutation({
      id,
      title,
    });

    emit('update', currentPost.value);
  };

  //on Form submit
  const onFormSubmit = async () => {
    if (isFormDataInvalid.value) return;

    console.log('post form submited', currentPost.value);

    if (props.post) {
      await onPostUpdate();
    } else {
      await onPostCreate();
    }

    store.dispatch('notification/showNotification', {
      text: `Должность успешно ${props.post ? 'сохранена' : 'создана'}`,
      type: 'success',
    });
    onModalClose();
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/styles/_colors';
  .modal-input {
    border: 1px solid map-get($lightgray-stroke, 'normal');
    border-radius: 10px;
    padding: 6px 10px;
    width: 100%;
    font-family: 'Golos';
    font-size: 16px;
    font-weight: 400;
  }
  .modal-input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: map-get($lightgray-text, 'normal');
  }
  .post-modal-delete {
    color: map-get($red, 'normal');
    cursor: pointer;
  }
</style>