<template>
  <div class="accordion__body" :class="{ active: isAccordionOpen }">
    <div class="accordion" @click="toggleAccordion">
      <span>{{ header }}</span>
      <mdicon size="26" class="arrow" name="chevron-down" />
    </div>
    <div class="accordion__content" ref="content">
      <slot />
    </div>
  </div>
</template>
<script setup>
  import { ref, defineProps } from 'vue';
  defineProps({
    header: {
      type: String,
      required: true,
    },
  });
  const content = ref(null);
  const isAccordionOpen = ref(false);
  const toggleAccordion = () => {
    isAccordionOpen.value = !isAccordionOpen.value;
    if (content.value.style.maxHeight) {
      content.value.style.maxHeight = null;
    } else {
      content.value.style.maxHeight = content.value.scrollHeight + 'px';
    }
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/styles/colors.scss';
  .accordion {
    background: var(--white);
    border: 1px solid var(--grey-purple);
    border-radius: 10px;
    padding: 10px 20px;
    position: relative;
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }
  .accordion span {
    color: map-get($primary-text, 'normal');
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  // .accordion__body.active .accordion {
  //   border-radius: 10px 10px 0 0;
  // }
  // .accordion__body.active .accordion__content {
  //   border: 1px solid #e5e5e5;
  //   border-top: none;
  // }
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    transition: all 0.4s ease 0s;
    color: map-get($lightgray-text, 'normal') !important;
    transform-origin: 50% 50% 0;
  }
  .accordion__body.active .arrow {
    transform: rotate(180deg) translate(0, 50%);
  }
  .accordion__content {
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    /* transition: all 0.2s ease-out; */
    transition: max-height 0.2s ease-out;
    border-radius: 0 0 10px 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: map-get($primary-text, 'normal');
  }
  @media (max-width: 556px) {
    .accordion {
      padding: 20px;
      padding-right: 50px;
    }
    .accordion__content {
      padding: 0 20px;
    }
  }
</style>