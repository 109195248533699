<template>
<div v-if="!loading" class="payments-banner" :style="{ backgroundImage: `url(${banner})` }">
    <div class="payments-banner__title">Состояние лицевого счета: 
        <span v-if="debt > 0" class="payments-banner__title--price payments-banner__title--price-red">-{{ debt }}₽</span>
        <span v-if="debt <= 0" class="payments-banner__title--price payments-banner__title--price-blue">+{{ debt }}₽</span>
    </div>
    <base-button v-if="debt > 0" @click="openModal" class="payments-banner__button" text="Оплатить" color="green" size="big" />
</div>
</template>

<script setup>
import { BaseButton } from '@/components/atoms';
import { useQuery } from '@vue/apollo-composable';
import { myDebt } from '@/graphql/Login.graphql';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import greenDebthBanner from '@/assets/images/claims-green/debth-banner.png';
import domionDebthBanner from '@/assets/images/payments-banner.png';

const THEME = process.env.VUE_APP_THEME;
const banner = THEME === 'domion' ? domionDebthBanner : greenDebthBanner;
const store = useStore();

const emit = defineEmits(['open']);

const debt = ref(0);

const { result, loading } = useQuery(myDebt);

const openModal = () => {
    store.dispatch('client/saveDebt', {
        debt: debt.value,
    });

    emit('open', true);
}

watch(result, (value) => {
    debt.value = value.myDebt;
})
</script>

<style scoped lang="scss">
.payments-banner {
    padding: 38px 28px 23px 28px;
    width: 100%;
    border-radius: 17px;
    // background-image: url('../../../../assets/images/payments-banner.png');
    object-fit: cover;
    background-position: right;
    background-size:  100% auto;
    background-repeat: no-repeat;
    overflow: hidden;
    animation: fadeIn .7s ease-in-out;

    &__title {
        margin-bottom: 90px;
        font-size: 22px;
        font-weight: 500;

        &--price {
            font-weight: 600;
            animation: fadeIn 1s ease-in-out;

            &-red {
                color: var(--button-error-active-color);
            }

            &-blue {
                color: var(--primary-color);
            }
        }
    }
}

@keyframes fadeIn {
 from {
    opacity: 0;
 }
 to {
    opacity: 1;
 }
}

</style>