<template>
  <the-sidebar :data="links"></the-sidebar>
</template>

<script>
import { TheSidebar } from '@/components';

export default {
  name: 'ManagerSidebar',
  components: { TheSidebar },
  data() {
    return {
      links: [
        {
          url: '/app',
          name: 'Что нового?',
          icon: 'view-dashboard',
        },
        {
          url: '/app/manager/clients',
          name: 'Клиенты',
          icon: 'human-handsup',
        },
        {
          url: '/app/manager/clients-with-faceId',
          name: 'Клиенты c FaceId',
          icon: 'face-recognition',
        },
        {
          url: '/app/manager/claims',
          name: 'Заявки',
          icon: 'message-text',
        },
        {
          url: '/app/manager/news',
          name: 'Новости',
          icon: 'text-box-multiple',
        },
        {
          url: '/app/manager/notification',
          name: 'Центр уведомлений',
          icon: 'bell-badge',
        },
        {
          url: '/app/manager/cameras',
          name: 'Камеры видеонаблюдения',
          icon: 'cctv',
        },
        {
          url: '/app/manager/debtors',
          name: 'Должники',
          icon: 'account-alert',
        },
      ],
    };
  },
};
</script>
