<template>
<div class="dropdown">
    <base-button
        @click="toggleDropdown"
        :color="color"
        :text="text"
        :size="size"
    ></base-button>
    <div v-if="isOpen" class="dropdown-menu">
        <div v-for="(item, idx) in options" :key="idx" @click="onClick(item)" class="dropdown-item">
            {{ item.name }}
        </div>
        <div v-if="selectedItem?.dropdown" class="nested-dropdown-menu">
          <div v-for="(subItem, subIdx) in selectedItem?.dropdown" :key="subIdx" class="nested-dropdown-item">
            {{ subItem.name }}
          </div>
        </div>
    </div>
</div>
</template>
<script setup>
import { BaseButton } from '@/components/atoms/';
import { defineProps, defineEmits, onMounted, onUnmounted, ref } from 'vue';

const emit = defineEmits(['choose']);

defineProps({
    text: { type: String },
    color: { type: String },
    size: { type: String },
    options: { type: Array },
});

const isOpen = ref(false);
const selectedItem = ref(null);

const toggleDropdown = () => {
 isOpen.value = !isOpen.value;
};

const closeDropdown = (event) => {
 const dropdownElement = document.querySelector('.dropdown');
 if (!dropdownElement.contains(event.target)) {
   isOpen.value = false;
 }
};

const onClick = (value) => {
    if (value === selectedItem.value) {
        selectedItem.value = null;
    } else {
        selectedItem.value = value;
    }

    emit('choose', value);
    if (value.dropdown) return;
    toggleDropdown();
}

onMounted(() => {
    window.addEventListener('click', closeDropdown);
});

onUnmounted(() => {
    window.removeEventListener('click', closeDropdown);
});
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-menu {
    margin-top: 10px;
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
}

.dropdown-item {
    color: black;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
}

.dropdown-item:not(:last-child) {
    border-bottom: 1px solid #0000001A;
}

.dropdown-item:hover {
    color: #50AD2E;
    background-color: #E1FFD6;
}

.nested-dropdown-menu {
    margin-top: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    border: 1px solid #D1CFD7;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nested-dropdown-item {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
}

.nested-dropdown-item:hover {
    background-color: #f0f0f0;
}
</style>