<template>
  <base-popup
    size="extra-large"
    :is-visible="isStreetParkingModalVisible"
    @close="onModalClose"
    :title="`Квартира ${apartment?.number}`"
  >
    <div class="parking__body">
      <div class="parking__info">
        <div class="parking-row mb-3">
          <span class="text-thin">Собственник: &nbsp;</span>
          <span class="text">{{
            apartment.plateNums.meta.apartmentOwnerFIO
          }}</span>
        </div>
        <div class="parking-row mb-3">
          <span class="text-thin">Лицевой счет: &nbsp;</span>
          <span class="text">{{
            apartment?.plateNums.meta.apartmentAccount
          }}</span>
        </div>
        <div
          v-if="apartment.plateNums.meta.apartmentTenants.length"
          class="parking-row mb-3"
        >
          <span class="text-thin">Доступ: &nbsp;</span>
          <div v-if="!loading" class="text">
            <p
              v-for="client in apartment.plateNums.meta.apartmentTenants.split(
                ','
              )"
              :key="client.id"
              class="mb-2"
            >
              {{ client }}
            </p>
          </div>
        </div>
        <!-- <div class="parking-row mb-3">
          <span class="text-thin">Доступ: &nbsp;</span>
          <div v-if="!loading" class="text">
            <p v-for="client in clients" :key="client.id" class="mb-2">
              {{ client.user.lastName }} {{ client.user.firstName }}
            </p>
          </div>
        </div> -->
        <div
          v-if="apartment.parkingPlaces.length"
          class="parking-row flex-column mb-2"
        >
          <p class="text-thin mb-3">Места: &nbsp;</p>
          <div
            v-for="item in apartment.parkingPlaces"
            :key="item.id"
            class="d-flex align-items-center mb-1"
          >
            <p class="text-thin">Номер: &nbsp;</p>
            <p class="mr-4 text">{{ item.name }}</p>
            <p class="text-thin">Уровень: &nbsp;</p>
            <p class="text">{{ item.parkingGate.name }}</p>
          </div>
        </div>
      </div>
      <div v-if="numbersArr.length" class="parking__numbers">
        <p class="text-thin mb-3">Автомобильные номера: &nbsp;</p>
        <div
          v-for="(item, idx) in numbersArr"
          :key="idx"
          class="input__wrapper"
        >
          <input
            type="text"
            :placeholder="`Номер ${idx + 1}`"
            class="modal-input modal-item"
            ref="numbers"
            v-model.trim="item.number"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-20">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button text="Сохранить" color="green" @click="onSubmit" />
    </div>
  </base-popup>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits, ref, watch } from 'vue';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import { getAppartmentClients } from '@/graphql/Client.graphql';
import { updateApartmentStreetParkingNums } from '@/graphql/StreetParking.graphql';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  isStreetParkingModalVisible: {
    type: Boolean,
    default: false,
  },
  apartment: {},
});

watch(
  () => props.isStreetParkingModalVisible,
  (newValue) => {
    if (newValue) {
      console.log(props.apartment);
      apartmentId.value = props.apartment.id;
      numbersArr.value = props.apartment.plateNums.plateNums.map(
        (item) =>
          ({
            number: item.num,
          } || {
            number: '',
          })
      );
      while (numbersArr.value.length < props.apartment.slotsNum) {
        numbersArr.value.push({
          number: '',
        });
      }
      console.log(props.apartment);
      load();
    }
  }
);

const emit = defineEmits(['close', 'update']);

const apartmentId = ref(null);

const {
  result: AppartmentClientsResult,
  load,
  loading,
} = useLazyQuery(getAppartmentClients, () => ({
  apartmentId: apartmentId.value,
}));

const clients = ref(null);

watch(AppartmentClientsResult, (newValue) => {
  clients.value = newValue?.getAppartmentClients;
});

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    emit('close');
    numbersArr.value = [];
  }
};
const numbersArr = ref([]);

const { mutate: updateApartmentStreetParkingNumsMutation } = useMutation(
  updateApartmentStreetParkingNums
);

const onApartmentUpdate = async () => {
  console.log(
    'id',
    apartmentId.value,
    'numbers',
    numbersArr.value.map((item) => item.number)
  );
  try {
    const { data } = await updateApartmentStreetParkingNumsMutation({
      id: apartmentId.value,
      streetParkingNums: numbersArr.value.map((item) => item.number),
    });
    if (data.updateApartmentStreetParkingNums.status) {
      emit('update');
      store.dispatch('notification/showNotification', {
        text: 'Номера успешно обновлены',
        type: 'success',
      });
      onModalClose(true);
    } else {
      throw new Error(data.updateApartmentStreetParkingNums.error);
    }
  } catch (error) {
    console.error(error);
    store.dispatch('notification/showNotification', {
      text: error,
      type: 'error',
    });
  }
};
const onSubmit = async () => {
  await onApartmentUpdate();
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.parking-row {
  display: flex;
}
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  text-transform: uppercase;
}
.modal-input::placeholder {
  text-transform: none;
}

.modal-select {
  width: 100%;
}
.text-thin {
  color: var(--grey-text-color);
  font-weight: 500;
}
.text {
  color: var(--dark);
  font-weight: 600;
}
.parking-row__account {
  margin-right: 20px;
  width: 58%;
}
.parking-row.col {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.delete-item {
  margin-left: 5px;
  height: 26px;
  width: 26px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}
.parking__body {
  display: flex;
  justify-content: space-between;
}
.parking__info {
  margin-right: 40px;
}
.delete-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  height: calc(100% - 8px);
  width: 29px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
}
.modal-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: map-get($lightgray-text, 'normal');
}
.modal-item {
  width: 100%;
  position: relative;
}
.input__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.input__wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.new-item-btn {
  color: map-get($green, 'normal');
  cursor: pointer;
  width: fit-content;
  margin: 20px 0 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.new-item-btn.visible {
  opacity: 1;
  visibility: visible;
}
</style>
