<template>
<div class="sidebar-select__wrapper">
    <div class="sidebar-select" @click="toggleOpen()">
        <div class="sidebar-select__select">
            <div class="sidebar-select__select-text" :title="selectedItem.name">
                {{ selectedItem.name }}
            </div>

            <div>
                <mdicon name="chevron-down" size="30" class="sidebar-select__select-icon" />
            </div>
        </div>

        <div v-if="open" class="sidebar-select__content">
            <div 
                v-for="item in items"
                :key="item.name"
                @click="selectItem(item)"
                class="sidebar-select__content-item"
                >
                <div v-if="items.length !== 0" class="sidebar-select__content-item-text">{{ item.name }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'

const emit = defineEmits(['chooseItem'])

const props = defineProps({
    chosenItem: { type: Object, required: false },
    items: { type: Object, required: true },
})

const selectedItem = ref(props.chosenItem);
const open = ref(false);

const toggleOpen = () => {
const elements = document.querySelectorAll('.company-options');
elements.forEach(element => {
    element.style.display = 'none';
});

open.value = !open.value
}

const selectItem = (item) => {
selectedItem.value = item
open.value = false

emit('chooseItem', selectedItem.value)
}

const closeOnOutsideClick = (event) => {
const targetElement = event.target
if (!targetElement.closest('.sidebar-select')) {
    open.value = false
}
}

watch(
() => props.chosenItem,
async () => {
    console.log(props.chosenItem, "WATCH");
    if (props.chosenItem && 'name' in props.chosenItem) {
    selectedItem.value = props.chosenItem
    }
},
{ deep: true }
)

onMounted(() => {
    document.addEventListener('click', closeOnOutsideClick)
})

onUnmounted(() => {
    document.removeEventListener('click', closeOnOutsideClick)
})
</script>

<style scoped lang="scss">
.sidebar-select {
    padding: 0 23px;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;

    color: var(--white);
    background: var(--primary-color);
    border-radius: 0px 0px 15px 15px;

    user-select: none;
    cursor: pointer;

    &__wrapper {
        position: relative;
    }

    &__select {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-icon {
            color: var(--white);
        }
    }

    &__content {
        position: absolute;
        padding: 12px;
        width: 100%;
        max-height: 200px;
        top: 100%;
        left: 0;
        z-index: 100 !important;
        background: var(--white);
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        transition: 0.3s all;
        overflow-y: scroll;

        &-item {
            &:not(:last-child) {
                padding-bottom: 12px;
                margin-bottom: 12px;
                border-bottom: 1px solid var(--grey-border-color);
            }

            &-text {
                color: var(--black);
                cursor: pointer;
                transition: .3s all;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }
}
</style>