const state = {
  notifications: {
    isVisible: false,
    text: '',
    type: '',
  },
};

const mutations = {
  SHOW_NOTIFICATION(state, payload) {
    state.notifications = {
      isVisible: true,
      text: payload.text,
      type: payload.type,
    };
  },
  HIDE_NOTIFICATION(state) {
    state.notifications = {
      isVisible: false,
      text: '',
      type: '',
    };
  },
};

const actions = {
  showNotification({ commit }, payload) {
    commit('SHOW_NOTIFICATION', payload);
  },
  hideNotification({ commit }) {
    commit('HIDE_NOTIFICATION');
  },
};

const getters = {
  isNotificationVisible(state) {
    return state.notifications.isVisible;
  },
  notificationText(state) {
    return state.notifications.text;
  },
  notificationType(state) {
    return state.notifications.type;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
