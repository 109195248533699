export default {
  routerHandler: (router) => {
    router.beforeEach((to, from, next) => {
      const token = localStorage.getItem('token');

      if (token) {
        const role = localStorage.getItem('role');

        /* Show pages only for ROLES that are permitted */
        if (to.meta.roles && !to.meta.roles.includes(role.toLowerCase())) {
          next('/');
        }

        if (to.path === '/') {
          next('/app');
        }

        next();
      } else {
        if (to.matched.some((record) => record.meta.auth)) {
          next('/');
        }
        next();
      }
    });
  },
};
