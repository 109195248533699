<template>
  <div>
    <h1 class="header">Новости</h1>
  </div>
  <div class="tabs">
    <base-tabs-wrapper>
      <base-tab title="Добавить новость"
        ><create-news @create="onNewsCreate"></create-news
      ></base-tab>
      <base-tab title="История">
        <div class="tab-container">
          <div class="history__table">
            <div v-if="isLoading" class="mt-20 text-center">
              <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
            </div>
            <base-table class="history-table" v-else>
              <template v-slot:head>
                <base-table-row>
                  <base-table-head-cell width="10%">Дата</base-table-head-cell>
                  <base-table-head-cell width="20%">Тема</base-table-head-cell>
                  <base-table-head-cell width="45%"
                    >Содержимое</base-table-head-cell
                  >
                  <base-table-head-cell width="15%"
                    >Комплекс</base-table-head-cell
                  >
                  <base-table-head-cell width="10%"></base-table-head-cell>
                </base-table-row>
              </template>
              <template v-slot:body>
                <base-table-row v-for="news in newsArray" :key="news.id">
                  <base-table-cell width="10%">
                    {{ news.publishDate.substr(0, 10) }}
                  </base-table-cell>
                  <base-table-cell width="20%">
                    <p class="news__text">{{ news.title }}</p>
                  </base-table-cell>
                  <base-table-cell width="45%">
                    <p class="news__text">{{ news.text }}</p>
                  </base-table-cell>
                  <base-table-cell width="15%">
                    <div class="news__complexes">
                      <span
                        v-for="item in news.newsComplexes"
                        :key="item.complex.id"
                        >{{ item.complex.name }} &nbsp;
                      </span>
                    </div>
                  </base-table-cell>
                  <base-table-cell width="10%">
                    <div class="d-flex justify-content-end w-100 actions">
                      <mdicon
                        size="19"
                        name="eye"
                        class="actions__item mr-3"
                        @click="selectNews(news)"
                      ></mdicon>
                      <mdicon
                        size="17"
                        name="pencil"
                        class="actions__item mr-3"
                        @click="onNewsUpdate(news)"
                      ></mdicon>
                      <mdicon
                        size="17"
                        name="delete"
                        class="actions__item"
                        @click="onNewsDelete(news)"
                      ></mdicon>
                    </div>
                  </base-table-cell>
                </base-table-row>
              </template>
            </base-table>
          </div>
        </div>
        <the-pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @pageChanged="onChangePage"
          class="pagination"
        ></the-pagination>
      </base-tab>
    </base-tabs-wrapper>
  </div>
  <news-modal
    :news="selectedNews"
    :is-modal-visible="isNewsPopupVisible"
    @update="onNewsUpdated"
    @close="setNewsPopupVisibility(false)"
  />
  <news-info-modal
    :news="selectedNews"
    :is-modal-visible="isNewsInfoModalVisible"
    @close="setNewsInfoVisibility(false)"
  />
  <the-confirm
    :is-visible="isDeleteConfirmVisible"
    @close="onToggleDeleteConfirm"
    @confirm="isDeleteConfirmed"
  />
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import { BaseTabsWrapper, BaseTab } from '@/components/atoms/';
import CreateNews from './CreateNews.vue';
import NewsModal from './NewsModal.vue';
import ThePagination from '@/components/ThePagination.vue';
import TheConfirm from '@/components/TheConfirm.vue';
import { getNewsUI, deleteNews } from '@/graphql/News.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';
import NewsInfoModal from './NewsInfoModal.vue';
import { useRoute, useRouter } from 'vue-router';

import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';

// import { THEME } from '@/constant/main'
const THEME = process.env.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const isLoading = ref(true);
const newsArray = ref([]);
const isNewsPopupVisible = ref(false);
const selectedNews = ref(null);

const route = useRoute();
const router = useRouter();

const { mutate: deleteNewsMutation } = useMutation(deleteNews);

const totalPages = ref(0);
const perPage = ref(5);
const currentPage = ref(+route.query.page || 1);

let {
  result: newsResult,
  refetch,
  fetchMore,
} = useQuery(getNewsUI, {
  pagination: {
    limit: perPage.value,
    offset: 0,
  },
});

const loadMore = async (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  await fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * perPage.value,
        limit: perPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      console.log('fetchMoreResult', fetchMoreResult);
      (newsArray.value = fetchMoreResult.getNewsUI.news),
        getTotalPages(fetchMoreResult.getNewsUI.total);
    },
  });
};

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / perPage.value);
};

onMounted(async () => {
  if (route.query.page) {
    isNewsResultCanBeUpdated = false;
  }
  await loadMore(+route.query.page || 0);
});

let isNewsResultCanBeUpdated = true;

watch(newsResult, (value) => {
  if (isNewsResultCanBeUpdated) {
    newsArray.value = value.getNewsUI.news;
    console.log(newsArray.value);
    isNewsResultCanBeUpdated = false;
  }
  isLoading.value = false;
});

const onNewsCreate = async () => {
  const {
    data: { getNewsUI },
  } = await refetch();
  console.log(getNewsUI);
  newsArray.value = getNewsUI.news;
};

const setNewsPopupVisibility = (status) => {
  isNewsPopupVisible.value = status;

  if (!status) {
    selectedNews.value = null;
  }
};

const onNewsUpdate = (news) => {
  selectedNews.value = news;
  setNewsPopupVisibility(true);
};

const onNewsUpdated = (news) => {
  newsArray.value = newsArray.value.map((item) => {
    if (item.id === news.id) {
      return { ...news };
    }
    return item;
  });
};

const onChangePage = async (page) => {
  router.replace({
    route,
    query: {
      page,
    },
  });
  currentPage.value = page;
  await loadMore(page);
};

const isDeleteConfirmVisible = ref(false);
const isNewsShouldBeDeleted = ref(false);

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isNewsShouldBeDeleted.value = true;
};

const onNewsDelete = async (news) => {
  isDeleteConfirmVisible.value = true;
  watch(isNewsShouldBeDeleted, async (value) => {
    if (value) {
      await deleteNewsMutation({
        id: news.id,
      });

      newsArray.value = newsArray.value.filter((item) => item.id !== news.id);
      isNewsShouldBeDeleted.value = false;
    }
  });
};

const isNewsInfoModalVisible = ref(false);

const setNewsInfoVisibility = (status) => {
  isNewsInfoModalVisible.value = status;

  if (!status) {
    selectedNews.value = null;
  }
};
const selectNews = (news) => {
  selectedNews.value = news;
  setNewsInfoVisibility(true);
};
</script>
<style lang="scss" scoped>
.header {
  color: var(--black);
}
.tabs {
  margin-top: 21px;
}
.tab-container {
  margin-top: 37px;
  border-top: 1px solid var(--grey-opacity);
  padding-top: 30px;
}

.actions__item {
  cursor: pointer;
}

.pagination {
  margin-top: 25px;
}
.news__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news__complexes {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
